<template>
  <div>
    <b-card no-body border-variant="primary" :class="[parameter_design_full_screen ? 'parameter-design-full-screen' : 'not-parameter-design-full-screen']">
      <b-card-header header-bg-variant="primary" class="p-1">
        <b-dropdown variant="secondary" size="sm">
          <template slot="button-content">
            <i class="icon-settings"></i> {{ $t('wdm16.6276') }}
          </template>
          <b-dropdown-item @click="new_wisdom_model()">{{ $t('wdm16.11293') }}</b-dropdown-item>
          <b-dropdown-item v-if="data_type && option_data[data_type] && d_isThisModelEditable" @click="f_saveWisdomDataModel()"> "{{ option_data[data_type].name.label }}" {{ $t('wdm16.4061') }} </b-dropdown-item>
          <b-dropdown-item v-if="data_type && option_data[data_type] && d_isThisModelEditable" @click="reset_changes()">{{ $t('wdm16.7072') }} <span style="color: red;"> ({{ $t('wdm16.11294') }}) </span></b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" @click="f_saveWdmNewPhrases('all', '')">{{ $t('wdm16.11295') }}</b-dropdown-item>
          <b-dropdown-item v-if="data_type && option_data[data_type]" @click="f_saveWdmNewPhrases('this', '')">{{ $t('wdm16.11296') }}</b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" @click="wisdom_operation()">{{ $t('wdm16.11297') }}</b-dropdown-item>
        </b-dropdown>
        {{ $t('wdm16.11298') }}
        <strong v-if="d_selectedModel && d_selectedModel.name" style="color: black; padding-left: 20px;">
          {{ d_selectedModel.name.label + $t('wdm16.12629') + d_selectedModel.version + ' )' }} | {{ d_selectedModel.name.value }}
        </strong>
        <b-button style="padding-right: 5px;" size="sm" class="pull-right" variant="success" v-if="data_type && option_data[data_type] && d_isThisModelEditable" @click="f_saveWisdomDataModel()"><i class="fa fa-save"></i>{{ $t('wdm16.4061') }}</b-button>
        <b-button style="padding-right: 5px;" size="sm" class="pull-right" variant="secondary" @click="parameter_design_full_screen ? parameter_design_full_screen = false : parameter_design_full_screen = true;"> <i class="fa fa-arrows"></i>{{ $t('wdm16.6589') }}</b-button>
      </b-card-header>
      <b-row style="margin: 0px; height: 95%;">
        <b-col sm="12" :md="colList.models_general_col_left" :lg="colList.models_general_col_left" :style="data_type && option_data[data_type] && option_data[data_type].color ? 'padding-right: 0px; padding-left: 0px; border: solid 3px ' + option_data[data_type].color + ';' : 'padding-right: 0px; padding-left: 0px; border: solid 3px #dc7f7f;'">
          <b-navbar style="padding: 0px;">
            <b-nav pills>
              <b-nav-item v-if="d_showWdmModels" @click="selected_general_tab = 'wisdom_data_models'" :active="selected_general_tab === 'wisdom_data_models'">
                {{ $t('wdm16.11299') }}
              </b-nav-item>
              <b-nav-item v-if="data_type && option_data[data_type]" @click="selected_general_tab = 'data_general'" :active="selected_general_tab === 'data_general'"> {{ ShowPartOfString(option_data[data_type].name.label.toLocaleUpperCase('tr'), 10) }}
              </b-nav-item>
              <b-nav-item v-if="data_type && option_data[data_type]" @click="selected_general_tab = 'parameter_list'" :active="selected_general_tab === 'parameter_list'">
                {{ $t('wdm16.11300') }}
              </b-nav-item>
              <b-nav-item v-if="data_type && option_data[data_type]" @click="selected_general_tab = 'select_module'" :active="selected_general_tab === 'select_module'">
                {{ $t('wdm16.11301') }}
              </b-nav-item>
              <b-nav-item v-if="data_type && option_data[data_type] && ((option_data[data_type].owner && option_data[data_type].owner.value === user.username) || user.wisdom_data_model && user.wisdom_data_model[data_type] && user.wisdom_data_model[data_type].perm.indexOf('give_permission') !== -1)" @click="selected_general_tab = 'give_permission'" :active="selected_general_tab === 'give_permission'">
                {{ $t('wdm16.7131') }}
              </b-nav-item>
              <b-nav-item v-if="data_type && option_data[data_type]" @click="f_showTestWdmModal()">
                {{ $t('wdm16.11303') }}
              </b-nav-item>
            </b-nav>
          </b-navbar>
          <template v-if="selected_general_tab === 'give_permission'">
            <b-card header-tag="header" footer-tag="footer" no-body key="nav-give_permission">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                {{ $t('wdm16.11304') }}
                <span class="pull-right">
                  <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeRightBigger')"><i class="fa fa-angle-left"></i></b-button>
                  <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeLeftBigger')"><i class="fa fa-angle-right"></i></b-button>
                </span>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <b-button variant="primary" @click="checkUsers()"><i class="fa fa-check"></i>{{ $t('wdm16.11305') }}</b-button>
                  <b-button variant="success" @click="addThisPermissionsToSelectedUsers()"><i class="fa fa-plus"></i>{{ $t('wdm16.11306') }}</b-button>
                  <b-button variant="success" @click="savePermissionsToSelectedUsers()"><i class="fa fa-plus"></i>{{ $t('wdm16.4061') }}</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox-group v-model="selected_wisdom_data_model_permissions">
                    <b-form-checkbox name="add_edit" value="record_add_edit"> {{ $t('wdm16.7020') }}</b-form-checkbox>
                    <b-form-checkbox name="view" value="record_view"> {{ $t('wdm16.6581') }}</b-form-checkbox>
                    <b-form-checkbox name="edit_data_model" value="edit_data_model"> {{ $t('wdm16.6698') }}</b-form-checkbox>
                    <b-form-checkbox name="give_permission" value="give_permission"> {{ $t('wdm16.7131') }}</b-form-checkbox>
                    <b-form-checkbox name="create_new_version" value="create_new_version"> {{ $t('wdm16.7145') }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-input v-model="filterUsersTable" :placeholder="$t('wdm16.11307')"></b-form-input>
                </b-col>
              </b-row>
              <div style="overflow-y: auto; height: 500px; overflow-x: auto; padding-right: 10px; padding-left: 10px; padding-top: 5px;">
                <b-table thead-tr-class="/**/" head-variant="dark" striped :filter="filterUsersTable" responsive stacked="sm" ref="ref_user_list" bordered small hover :items="users_for_permission" :fields="user_table_columns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
                  <template v-slot:cell(check)="row">
                    <b-form-checkbox v-model="row.item.check" name="check"></b-form-checkbox>
                  </template>
                  <template v-slot:cell(username)="row">
                    <div style="white-space: nowrap;">
                      {{ row.item.label }}
                    </div>
                  </template>
                  <template v-slot:cell(wisdom_data_model)="row">
                    <template v-if="row.item.pdt.wisdom_data_model[data_type] && row.item.pdt.wisdom_data_model[data_type].permission">
                      <b-row>
                        <b-col cols="12">
                          <b-form-checkbox-group v-model="row.item.pdt.wisdom_data_model[data_type].permission">
                            <b-form-checkbox name="add_edit" value="record_add_edit"> {{ $t('wdm16.7020') }}</b-form-checkbox>
                            <b-form-checkbox name="view" value="record_view"> {{ $t('wdm16.6581') }}</b-form-checkbox>
                            <b-form-checkbox name="edit_data_model" value="edit_data_model"> {{ $t('wdm16.6698') }}</b-form-checkbox>
                            <b-form-checkbox name="give_permission" value="give_permission"> {{ $t('wdm16.7131') }}</b-form-checkbox>
                            <b-form-checkbox name="create_new_version" value="create_new_version"> {{ $t('wdm16.7145') }}</b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </b-table>
              </div>
            </b-card>
          </template>
          <template v-if="selected_general_tab === 'select_module'">
            <b-card header-tag="header" footer-tag="footer" no-body key="nav-position_department_title">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                {{ $t('wdm16.11308') }}
                <b-button v-if="tree_list.module.selected_items_data_list.length > 0 && d_isThisModelEditable" class="pull-right" variant="primary" size="sm" @click="fSelectThisModule()"><i class="fa fa-plus"></i></b-button>
              </b-card-header>
              <template v-if="option_data[data_type].module && option_data[data_type].module.length > 0">
                <b-row class="form-group" v-for="(mod, mod_ind) in option_data[data_type].module" :key="'option_data_data_type_.module_' + mod_ind">
                  <b-col sm="12" lg="12">
                    <b-button v-if="d_isThisModelEditable" variant="danger" size="sm" @click="option_data[data_type].module.splice(mod_ind, 1); $forceUpdate()"><i class="fa fa-trash"></i></b-button>
                    {{ mod.label }}
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <strong style="color: red;">{{ $t('wdm16.11309') }}</strong>
              </template>
            </b-card>
            <tree-list key="select_module" :show_mode="tree_list.module.show_mode" :tree_list_type="'module'" :select_type="tree_list.module.select_type" :mode="tree_list.module.mode" :selected_items_data_list="tree_list.module.selected_items_data_list" :max_height="tree_list.module.max_height"></tree-list>
          </template>
          <template v-if="selected_general_tab === 'wisdom_data_models'">
            <b-card header-tag="header" footer-tag="footer" no-body key="nav-give_permission" style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                {{ $t('wdm16.11310') }}
                <span class="pull-right">
                  <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeRightBigger')"><i class="fa fa-angle-left"></i></b-button>
                  <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeLeftBigger')"><i class="fa fa-angle-right"></i></b-button>
                </span>
              </b-card-header>
              <div :style="f_mainParameterSelectionStyle()">
                <template v-if="page_type === 'main'">
                  <b-card v-if="wdm_version.wisdom.all && Object.keys(wdm_version.wisdom.all).length > 0" header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      {{ $t('wdm16.11311') }}
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in wdm_version.wisdom.all" :key="'wisdom-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }} <small style="color: green;">[<i>{{ model_ind }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'wisdom-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'wisdom')">
                            <div :class="[CalculateSelectedModelClass('wisdom', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" :text="$t('wdm16.11317')" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='wisdom' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="model_ver.version !== 'draft' && model.permission.indexOf('create_new_version') !== -1 && !d_selectedModelHasDraft" @click="f_createDraftFromThisVersion('wisdom', model_ind, model_ver)"><i class="fa fa-plus"></i>{{ $t('wdm16.11312') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft' && d_userWdmPermissions.indexOf('create_new_version') !== -1" @click="f_deleteThisModel()"><i class="fa fa-trash"></i>{{ $t('wdm16.11313') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_publishWdm()"><i class="fa fa-trash"></i>{{ $t('wdm16.11314') }}</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card v-if="wdm_version.hospital.all && Object.keys(wdm_version.hospital.all).length > 0" header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      {{ $t('wdm16.11315') }}
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in wdm_version.hospital.all" :key="'hospital-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }} <small style="color: green;">[<i>{{ model_ind }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'hospital-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'hospital')">
                            <div :class="[CalculateSelectedModelClass('hospital', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <!-- 
                            <b-dropdown class="pull-right" variant="secondary" :text="$t('wdm16.11317')" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='hospital' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="model_ver.version !== 'draft' && !d_selectedModelHasDraft" @click="f_createDraftFromThisVersion('hospital', model_ind, model_ver)"><i class="fa fa-plus"></i>{{ $t('wdm16.11312') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_deleteThisModel()"><i class="fa fa-trash"></i>{{ $t('wdm16.11313') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_publishWdm()"><i class="fa fa-trash"></i>{{ $t('wdm16.11314') }}</b-dropdown-item>
                            </b-dropdown>
                           -->
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card v-if="wdm_version.user.all && Object.keys(wdm_version.user.all).length > 0" header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      {{ $t('wdm16.11316') }}
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in wdm_version.user.all" :key="'user-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }} <small style="color: green;">[<i>{{ model_ind }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'user-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'user')">
                            <div :class="[CalculateSelectedModelClass('user', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" :text="$t('wdm16.11317')" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='user' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="model_ver.version !== 'draft' && !d_selectedModelHasDraft" @click="f_createDraftFromThisVersion('user', model_ind, model_ver)"><i class="fa fa-plus"></i>{{ $t('wdm16.11312') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_deleteThisModel()"><i class="fa fa-trash"></i>{{ $t('wdm16.11313') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_publishWdm()"><i class="fa fa-trash"></i>{{ $t('wdm16.11314') }}</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
                <template v-else-if="page_type === 'hospital_settings'">
                  <b-card v-if="wdm_version_hospital.all && Object.keys(wdm_version_hospital.all).length > 0" header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
                    <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                      {{ $t('wdm16.11315') }}
                    </b-card-header>
                    <b-row v-for="(model, model_ind) in wdm_version_hospital.all" :key="'hospital-' + model_ind" style="border-bottom: solid 1px #bca3a3; padding: 3px;">
                      <b-col cols="12">
                        <strong>
                          <i class="fa fa-hand-paper-o" style="color: red;"></i>
                          {{ model.name.label }} <small style="color: green;">[<i>{{ model_ind }}</i>]</small>
                        </strong>
                        <b-row v-for="(model_ver, model_ver_ind) in model.list" :key="'hospital-' + model_ind + model_ver_ind">
                          <b-col cols="8" @click="SelectModel(model, model_ind, model_ver, model_ver_ind, 'hospital')">
                            <div :class="[CalculateSelectedModelClass('hospital', model_ind, model_ver_ind)]">
                              <i class="fa fa-hand-o-right" style="color: green;"></i> Version - {{ model_ver.version }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <b-dropdown class="pull-right" variant="secondary" :text="$t('wdm16.11317')" size="sm" v-if="d_selectedModelIndex === model_ind && d_selectedLocation ==='hospital' && d_selectedModelVersionIndex === model_ver_ind">
                              <b-dropdown-item v-if="model_ver.version !== 'draft' && !d_selectedModelHasDraft" @click="f_createDraftFromThisVersion('hospital', model_ind, model_ver)"><i class="fa fa-plus"></i>{{ $t('wdm16.11312') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_deleteThisModel()"><i class="fa fa-trash"></i>{{ $t('wdm16.11313') }}</b-dropdown-item>
                              <b-dropdown-item v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'" @click="f_publishWdm()"><i class="fa fa-trash"></i>{{ $t('wdm16.11314') }}</b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
                <div style="min-height: 300px;"></div>
              </div>
            </b-card>
          </template>
          <b-card no-body v-if="selected_general_tab === 'data_general'" style="margin-bottom: 0px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              {{ $t('wdm16.11318') }}
              <span class="pull-right">
                <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeRightBigger')"><i class="fa fa-angle-left"></i></b-button>
                <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeLeftBigger')"><i class="fa fa-angle-right"></i></b-button>
              </span>
            </b-card-header>
            <div :style="f_mainParameterSelectionStyle()">
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11319') }} </div>
                <div class="col-6">
                  <b-button @click="f_showWdmStyleModal()" variant="primary"><i class="fa fa-edit"></i>{{ $t('wdm16.11320') }}</b-button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11321') }} </div>
                <div class="col-6">
                  {{ option_data[data_type].parent }}
                </div>
              </div>
              <div class="form-group row" v-if="d_userWdmPermissions.indexOf('create_new_version') !== -1">
                <div class="col-6">{{ $t('wdm16.11322') }}</div>
                <div class="col-6">
                  <template v-if="d_selectedModel && d_selectedModel.version && d_selectedModel.version === 'draft'">
                    <b-button @click="f_deleteThisModel()" variant="danger"><i class="fa fa-trash"></i>{{ $t('wdm16.11313') }}</b-button>
                  </template>
                  <template v-else>
                    {{ $t('wdm16.11323') }}
                  </template>
                </div>
              </div>
              <div class="form-group row" v-if="d_userWdmPermissions.indexOf('create_new_version') !== -1">
                <div class="col-6">{{ $t('wdm16.11324') }}</div>
                <div class="col-6">
                  {{ option_data[data_type].name.wdm16_id ? option_data[data_type].name.wdm16_id : 'Yok' }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11325') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].rule_parameter" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="">Seçim yapılmadı</option>
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11326') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].send_to_hims_protocol" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11328') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].department_filter_mode" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="not_eligible_for_department_relation">{{ $t('wdm16.11329') }}</option>
                    <option value="filter_with_department">{{ $t('wdm16.11330') }}</option>
                    <option value="all_departments">{{ $t('wdm16.11331') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">
                  {{ $t('wdm16.11292') }}
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" :disabled="!d_isThisModelEditable" v-model="option_data[data_type].name.label" name="caption">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">
                  {{ $t('wdm16.11332') }}
                </div>
                <div class="col-6">
                  <input type="color" class="form-control" :disabled="!d_isThisModelEditable" v-model="option_data[data_type].color" @change="$forceUpdate()">
                  <input type="text" class="form-control" :disabled="!d_isThisModelEditable" v-model="option_data[data_type].color" @keyup="$forceUpdate()">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11333') }} </div>
                <div class="col-6">
                  <v-select v-model="option_data[data_type].owner" :disabled="d_userWdmPermissions.indexOf('edit_data_model') === -1 || d_selectedModel.version !== 'draft' || user.username !== option_data[data_type].owner.value" :options="all_users"></v-select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11334') }} </div>
                <div class="col-6">
                  <v-select v-model="option_data[data_type].language" :options="wisdomera_system_languages"></v-select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11327') }} </div>
                <div class="col-6"> {{ option_data[data_type].version }} </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.6299') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].record_count" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="not_recording">{{ $t('wdm16.11335') }}</option>
                    <option value="one_time">{{ $t('wdm16.11336') }}</option>
                    <option value="many_times">{{ $t('wdm16.11337') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11338') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].edit_in_hospital_settings" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11339') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].permission_by_superuser" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              {{ option_data[data_type].permission_by_superuser === 'no' ? option_data[data_type].permissions_list_for_superuser = [] : '' }}
              <div class="form-group row" v-if="option_data[data_type].permission_by_superuser === 'yes'">
                <div class="col-6"> {{ $t('wdm16.11340') }} </div>
                <div class="col-6">
                  <b-form-checkbox-group v-model="option_data[data_type].permissions_list_for_superuser" :disabled="!d_isThisModelEditable">
                    <b-form-checkbox name="add_edit" value="record_add_edit"> {{ $t('wdm16.7020') }}</b-form-checkbox>
                    <b-form-checkbox name="view" value="record_view"> {{ $t('wdm16.6581') }}</b-form-checkbox>
                    <b-form-checkbox name="edit_data_model" value="edit_data_model"> {{ $t('wdm16.6698') }}</b-form-checkbox>
                    <b-form-checkbox name="give_permission" value="give_permission"> {{ $t('wdm16.7131') }}</b-form-checkbox>
                    <b-form-checkbox name="create_new_version" value="create_new_version"> {{ $t('wdm16.7145') }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11325') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].rule_parameter" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11341') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].schema" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11342') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].use_old_data" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11343') }}</div>
                <div class="col-6">
                  {{ option_data[data_type].owner_type }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11344') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].favorite_show_mode" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11345') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].bmi_gfr" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11346') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].calculate_eval" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.11347') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].checked_on_open" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">{{ $t('wdm16.11348') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].show_last_recorded_value" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> Döküman Tarihleme </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].date_ability" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> Döküman Tarihleme Kullanıcı İnsiyatifi</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].date_ability_user_selection" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" v-if="option_data[data_type].date_ability === 'yes'">
                <div class="col-6"> {{ $t('wdm16.12748') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].date_ability_type" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="year">{{ $t('wdm16.12749') }}</option>
                    <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
                    <option value="date">{{ $t('wdm16.12751') }}</option>
                    <option value="datetime">{{ $t('wdm16.12752') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" v-if="option_data[data_type].date_ability === 'yes'">
                <div class="col-6"> {{ $t('wdm16.12753') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].date_ability_limit" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="none">{{ $t('wdm16.12754') }}</option>
                    <option value="before">{{ $t('wdm16.12755') }}</option>
                    <option value="after">{{ $t('wdm16.12756') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.12757') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].ending_date_ability" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.12758') }}</div>
                <div class="col-6">
                  <select v-model="option_data[data_type].ending_date_ability_user_selection" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" v-if="option_data[data_type].ending_date_ability === 'yes'">
                <div class="col-6"> {{ $t('wdm16.12748') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].ending_date_ability_type" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="year">{{ $t('wdm16.12749') }}</option>
                    <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
                    <option value="date">{{ $t('wdm16.12751') }}</option>
                    <option value="datetime">{{ $t('wdm16.12752') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" v-if="option_data[data_type].ending_date_ability === 'yes'">
                <div class="col-6"> {{ $t('wdm16.12753') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].ending_date_ability_limit" :disabled="!d_isThisModelEditable" class="form-control">
                    <option value="none">{{ $t('wdm16.12754') }}</option>
                    <option value="before">{{ $t('wdm16.12755') }}</option>
                    <!-- <option value="after">{{ $t('wdm16.12756') }}</option> -->
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.3702') }} </div>
                <div class="col-6">
                  <select v-model="option_data[data_type].document_upload" :disabled="!d_isThisModelEditable" class="form-control" @change="DocumentUploadFunc()">
                    <option value="yes">{{ $t('wdm16.7249') }}</option>
                    <option value="no">{{ $t('wdm16.76') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.12768') }} </div>
                <div class="col-6">
                  <b-form-checkbox-group v-model="option_data[data_type].document_type_list" :disabled="!d_isThisModelEditable">
                    <b-form-checkbox v-for="(doc_type, doc_type_ind) in document_type_list" :key="'document_type_list_'+ doc_type_ind" :plain="true" :value="doc_type"> {{ doc_type }} </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6"> {{ $t('wdm16.6775') }} </div>
                <div class="col-6">
                  <b-form-checkbox-group v-model="option_data[data_type].follow_up_location" :disabled="!d_isThisModelEditable">
                    <b-form-checkbox type="checkbox" name="oncology" value="patient_follow"> {{ $t('wdm16.12769') }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <strong>{{ $t('wdm16.12770') }}:</strong>
                  <br>
                  <span v-if="option_data[data_type].about" v-for="(ab, ab_ind) in option_data[data_type].about">
                    <button v-if="d_isThisModelEditable" type="button" class="btn-primary fa fa-trash" @click="option_data[data_type].about.splice(ab_ind, 1); $forceUpdate();"></button>
                    {{ ab }}
                    <br>
                  </span>
                </div>
              </div>
              <div class="form-group row" v-if="d_isThisModelEditable">
                <div class="col-2">
                  yeni bilgi
                </div>
                <div class="col-8">
                  <textarea class="form-control" v-model="about_model"></textarea>
                </div>
                <div class="col-1">
                  <button type="button" class="btn-primary" @click="add_to_model_about()"><strong>/\</strong> </button>
                </div>
              </div>
            </div>
          </b-card>
          <b-card no-body v-if="selected_general_tab === 'parameter_list'" border-variant="success" style="margin-bottom: 0px;">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              Ana parametre seçimi
              <span class="pull-right">
                <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeRightBigger')"><i class="fa fa-angle-left"></i></b-button>
                <b-button style="width: 50px; margin-right: 10px;" size="sm" variant="primary" @click="rowColReDesign('models_general_col_right', 'models_general_col_left', 'makeLeftBigger')"><i class="fa fa-angle-right"></i></b-button>
              </span>
            </b-card-header>
            <b-row style="height: 30px;">
              <b-col sm="12" md="12" lg="3"> </b-col>
              <b-col sm="12" md="12" lg="3"> </b-col>
              <b-col sm="12" md="12" lg="6">
                <b-form-select id="show_parameter_features" :plain="true" v-model="show_parameter_features">
                  <option value="all">{{ $t('wdm16.12772') }} </option>
                  <option value="naming">{{ $t('wdm16.12773') }} </option>
                  <option value="show_on_recording">{{ $t('wdm16.12774') }} </option>
                  <option value="show_after_recorded">{{ $t('wdm16.12775') }} </option>
                  <option value="required">{{ $t('wdm16.12776') }} </option>
                  <option value="rule">{{ $t('wdm16.12777') }} </option>
                  <option value="lab_unit">{{ $t('wdm16.12778') }} </option>
                  <option value="show_size_units">{{ $t('wdm16.12779') }}</option>
                  <option value="show_weight_units">{{ $t('wdm16.12780') }}</option>
                  <option value="show_dosage_units">{{ $t('wdm16.12781') }}</option>
                  <option value="anonym">{{ $t('wdm16.12782') }}</option>
                  <option value="not_schema">{{ $t('wdm16.12783') }}</option>
                  <option v-if="page_type === 'hospital_settings'" value="hims_match_operations">{{ $t('wdm16.12784') }}</option>
                  <option v-if="option_data[data_type].show_last_recorded_value === 'yes'" value="show_last_recorded_value">{{ $t('wdm16.11348') }} (SLRV) </option>
                  <option v-if="option_data[data_type].favorite_show_mode === 'yes'" value="fav_show">{{ $t('wdm16.11344') }} (FS) </option>
                  <option v-if="page_type === 'hospital_settings'" value="match">{{ $t('wdm16.12785') }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row :key="option_data[data_type].parent" style="border-bottom: solid 1px green; margin: 0px;">
              <b-col sm="12" lg="6">
                <strong style="color: green;">{{ option_data[data_type].name.label.toLocaleUpperCase('tr') }}</strong>
                <small> {{ $t('wdm16.12786') }}</small>
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-input v-model="search_in_first_parameter_list" :placeholder="$t('wdm16.12787')"></b-form-input>
              </b-col>
            </b-row>
            <div :style="f_mainParameterSelectionStyle()">
              <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="search_in_first_parameter_list" :option_data="option_data" :selected_index_data="selected_index_data" :show_parameter_features="show_parameter_features" :d_isThisModelEditable="d_isThisModelEditable" :param_change="param_change"></wdm-parameters>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" :md="colList.models_general_col_right" :lg="colList.models_general_col_right" :style="data_type && option_data[data_type] && option_data[data_type].color ? 'padding-right: 0px; padding-left: 0px; border: solid 3px ' + option_data[data_type].color + '; border-left: none!important;' : 'padding-right: 0px; padding-left: 0px; border: solid 3px #dc7f7f; border-left: none!important;'" v-if="data_type && option_data[data_type]">
          <b-navbar style="padding: 0px;" v-if="selected_general_tab === 'parameter_list'">
            <b-nav pills :key="'tab-' + selected_tab">
              <b-nav-item v-if="SelectedParamData.name" @click="selected_tab = 'param_details'" :active="selected_tab === 'param_details'">{{ $t('wdm16.3667') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.type && select_types.indexOf(SelectedParamData.type) !== -1 && parameter_option_method === 'manuel'" @click="selected_tab = 'parameterOptions'" :active="selected_tab === 'parameterOptions'">{{ $t('wdm16.3796') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.name" @click="selected_tab = 'param_friend'" :active="selected_tab === 'param_friend'">{{ $t('wdm16.12788') }}</b-nav-item>
              <b-nav-item v-if="option_data[data_type].calculate_eval && SelectedParamData.name" @click="selected_tab = 'param_friend_function'" :active="selected_tab === 'param_friend_function'">{{ $t('wdm16.12789') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.name && selected_index_data.index_list[0] === 'anatomy'" @click="selected_tab = 'param_anatomy'" :active="selected_tab === 'param_anatomy'">{{ $t('wdm16.12790') }}</b-nav-item>
              <b-nav-item @click="selected_tab = 'param_patient'" :active="selected_tab === 'param_patient'">{{ $t('wdm16.12791') }}</b-nav-item>
              <b-nav-item v-if="['object_list_options', 'list_parameter'].indexOf(SelectedParamData.type) !== -1 || selected_index_data.index_list.length === 1" @click="selected_tab = 'new_param'" :active="selected_tab === 'new_param'">{{ $t('wdm16.12792') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.type && select_types.indexOf(SelectedParamData.type) !== -1" @click="selected_tab = 'option_friend'" :active="selected_tab === 'option_friend'">{{ $t('wdm16.12793') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.type && select_types.indexOf(SelectedParamData.type) !== -1 && selected_index_data.index_list[0] === 'anatomy'" @click="selected_tab = 'option_anatomy'" :active="selected_tab === 'option_anatomy'">{{ $t('wdm16.12794') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.type && select_types.indexOf(SelectedParamData.type) !== -1" @click="selected_tab = 'option_patient'" :active="selected_tab === 'option_patient'">{{ $t('wdm16.12795') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.rule && false" @click="selected_tab = 'param_rule'" :active="selected_tab === 'param_rule'">{{ $t('wdm16.11468') }}</b-nav-item>
              <b-nav-item v-if="d_isThisModelEditable" @click="selected_tab = 'new_param_group'" :active="selected_tab === 'new_param_group'">{{ $t('wdm16.12796') }}</b-nav-item>
              <!-- selected_index_data.index_list.length === 1 means we selected the group index, so index_list has only one parameter as group value -->
              <b-nav-item v-if="selected_index_data.index_list.length === 1" @click="selected_tab = 'param_group_details'" :active="selected_tab === 'param_group_details'">{{ $t('wdm16.12797') }}</b-nav-item>
              <b-nav-item v-if="SelectedParamData.type && page_type === 'hospital_settings'" @click="selected_tab = 'match_parameter_from_webservice'" :active="selected_tab === 'match_parameter_from_webservice'">{{ $t('wdm16.12798') }} ({{ $t('wdm16.12801') }} = {{ option_data[data_type].hospital_id }})</b-nav-item>
            </b-nav>
          </b-navbar>
          <div>
            <template v-if="selected_tab === 'match_parameter_from_webservice'">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="12" lg="12" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> "{{ SelectedParamData.name.label.toLocaleUpperCase('tr') }}" {{ $t('wdm16.12799') }} </b-card-header>
                    <b-row v-if="d_isThisModelEditable" style="border-bottom: solid 1px black; margin: 0px;">
                      <b-col cols="6">
                        <template v-if="!selectedHimsTest || !SelectedParamData.type">
                          <span style="color: red">{{ $t('wdm16.12800') }}</span>
                        </template>
                        <template v-else>
                          <b-button variant="primary" @click="matchWithHimsTestID()">
                            {{ SelectedParamData.name.label }} [Wisdom]
                            <=>
                              [{{ $t('wdm16.12801') }}] {{ selectedHimsTest.mname }}
                          </b-button>
                        </template>
                      </b-col>
                      <b-col cols="4">
                        <template v-if="SelectedParamData.himsTestData && Object.keys(SelectedParamData.himsTestData).length > 0">
                          <b-row v-for="(himstest, himstest_id) in SelectedParamData.himsTestData" :key="'SelectedParamData.himsTestData_' + himstest_id">
                            <b-col cols="12">
                              <b-button size="sm" variant="danger" @click="f_deleteHimsTest(himstest_id)"><i class="fa fa-minus"></i></b-button>
                              {{ himstest.id + ' - ' + himstest.name }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="3">
                        <b-form-input v-model="d_himsParameterSearchText" :placeholder="$t('wdm16.12802')"></b-form-input>
                      </b-col>
                      <b-col cols="3">
                        <b-dropdown variant="warning">
                          <template slot="button-content">{{ $t('wdm16.6276') }}</template>
                          <b-dropdown-item @click="TestIDUniqFunc()">{{ testIDListedAsOne ? this.$t('wdm16.12630') : $t('wdm16.12631') }}</b-dropdown-item>
                          <b-dropdown-item v-for="(field, field_ind) in DontShowLaboratoryListColumns" :key="'DontShowLaboratoryListColumns_' + field_ind" @click="ShowThisField(field_ind, DontShowLaboratoryListColumns)"><i class="fa fa-plus"></i> {{ field.label }} </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                      <b-col cols="3">
                        {{ d_showHimsLabList.length + $t('wdm16.12632') }}
                      </b-col>
                    </b-row>
                    <div :style="f_himsTestListStyle()">
                      <table id="report_here" style="border: solid 1px; border-collapse: collapse; width: 100%;">
                        <thead>
                          <tr>
                            <th v-for="(field, field_ind) in showHimsLaboratoryListColumns" style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
                              <b-button style="float: left;" size="sm" variant="danger" @click="DontShowThisField(field_ind, showHimsLaboratoryListColumns)"><i class="fa fa-minus"></i></b-button>
                              <b-form-select v-if="field.grouping" v-model="FieldGrouping[field.key]" @change="SelectFieldGroupingChanged()" style="float: left;">
                                <option value="">Tümü</option>
                                <option v-for="(val, val_ind) in CalculateFieldValues(field.key, d_showHimsLabList)">{{ val }}</option>
                              </b-form-select>
                              {{ field.label }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(row, row_ind) in d_showHimsLabList">
                            <tr @click="f_selectTestFromTable(row, row_ind)" :style="selectedHimsTestIndex === row_ind ? 'border: solid 1px #c3d8d8; padding: 5px; background-color: #55ec12; cursor: pointer;' : 'border: solid 1px #c3d8d8; padding: 5px; cursor: pointer;'">
                              <td v-for="(field, field_ind) in showHimsLaboratoryListColumns" style="border: solid 1px #c3d8d8; padding: 5px;">
                                {{ row[field.key] }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <!-- <b-table ref="hims_test_table" thead-tr-class="/*search-patient-table-thead-class*/" head-variant="dark" striped :filter="filterTable" responsive stacked="sm" bordered small hover :items="ShowhimsLaboratoryList" :fields="showHimsLaboratoryListColumns" selectable select-mode="single" selectedVariant="success" @row-selected="selectTest" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
                        <template v-slot:cell(thead-top)="row">
                          <tr>
                            <th v-for="(field, field_ind) in showHimsLaboratoryListColumns" style="vertical-align: top; white-space: nowrap;">
                              <b-button style="float: left;" size="sm" variant="danger" @click="DontShowThisField(field_ind, showHimsLaboratoryListColumns)"><i class="fa fa-minus"></i></b-button>
                              <b-form-select v-if="field.grouping" v-model="FieldGrouping[field.key]" @change="SelectFieldGroupingChanged()" style="float: left;">
                                <option value="">Tümü</option>
                                <option v-for="(val, val_ind) in CalculateFieldValues(field.key, ShowhimsLaboratoryList)">{{ val }}</option>
                              </b-form-select>
                            </th>
                          </tr>
                        </template>
                        <template v-slot:cell(mname)="row">
                          <div style="white-space: nowrap;">{{ row.item.mname }}</div>
                        </template>
                        <template v-slot:cell(info)="row">
                          <div style="white-space: nowrap;">{{ row.item.info }}</div>
                        </template>
                        <template v-slot:cell(test_code)="row">
                          <div style="white-space: nowrap;">{{ row.item.test_code }}</div>
                        </template>
                        <template v-slot:cell(mgroup_name)="row">
                          <div style="white-space: nowrap;">{{ row.item.mgroup_name }}</div>
                        </template>
                      </b-table> -->
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <template v-if="selected_tab === 'param_rule' && SelectedParamData.rule">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="12" lg="12" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> "{{ SelectedParamData.name.label.toLocaleUpperCase('tr') }}" {{ $t('wdm16.12804') }} </b-card-header>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong>{{ $t('wdm16.12805') }}</strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.buck">
                          <option value="wisdom">wisdom</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong>{{ $t('wdm16.12806') }}</strong>
                      </div>
                      <div class="col-9">
                        {{ SelectedParamData.rule.type.label }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong>{{ $t('wdm16.12807') }}</strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.record_type">
                          <option value="many">{{ $t('wdm16.12808') }}</option>
                          <option value="only_one">{{ $t('wdm16.12809') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12810') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.op">
                          <option v-for="(op, op_ind) in SelectedParamData.rule.operators" :value="op"> {{ op }} </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong>{{ $t('wdm16.12811') }}</strong>
                      </div>
                      <div class="col-8">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="selected_data_type">
                          <option value="requesting_data">{{ $t('wdm16.12812') }}</option>
                          <option value="old_recorded_data">{{ $t('wdm16.12813') }}</option>
                        </select>
                      </div>
                      <div class="col-1" v-if="d_isThisModelEditable">
                        <button type="button" class="btn btn-primary fa fa-plus" @click="SelectedParamData.rule.data_types.indexOf(selected_data_type) === -1 ? SelectedParamData.rule.data_types.push(selected_data_type) : ''"></button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong>{{ $t('wdm16.12814') }}</strong>
                      </div>
                      <div class="col-9">
                        <div v-for="(op, op_ind) in SelectedParamData.rule.data_types">
                          <button v-if="d_isThisModelEditable" style="margin: 3px;" type="button" class="btn btn-primary fa fa-trash" @click="SelectedParamData.rule.data_types.splice(op_ind, 1)"></button>
                          {{ op }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12815') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.data_type">
                          <option value="requesting_data">{{ $t('wdm16.12812') }}</option>
                          <option value="old_recorded_data">{{ $t('wdm16.12813') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12818') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.time_of_value">
                          <option value="last_value">{{ $t('wdm16.12819') }}</option>
                          <option value="last_days">{{ $t('wdm16.12820') }}</option>
                          <option value="any_value">{{ $t('wdm16.12821') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12822') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.date">
                          <option value="1">{{ $t('wdm16.12823') }}</option>
                          <option value="0">{{ $t('wdm16.12824') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12825') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.requesting_operation">
                          <option value="wisdom_data_save">{{ $t('wdm16.12826') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-3">
                        <strong> {{ $t('wdm16.12827') }} </strong>
                      </div>
                      <div class="col-9">
                        <select class="form-control" :disabled="!d_isThisModelEditable" v-model="SelectedParamData.rule.significant">
                          <option value="true">{{ $t('wdm16.12828') }}</option>
                          <option value="false">{{ $t('wdm16.12829') }}</option>
                        </select>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <!-- ******************** param_friend_function ******************** START -->
            <template v-if="selected_tab === 'param_friend_function'">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="6" lg="6" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.6803') }} </b-card-header>
                    <div style="overflow-y: auto; height: 250px; overflow-x: hidden;">
                      <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="search_in_first_parameter_list" :option_data="option_data" :selected_index_data="selected_index_data_2" :show_parameter_features="show_parameter_features" :d_isThisModelEditable="d_isThisModelEditable" :param_change="param_change"></wdm-parameters>
                    </div>
                  </b-card>
                </b-col>
                <b-col sm="12" md="6" lg="6" style="padding-left: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.12830') }} </b-card-header>
                    <template v-if="SelectedParamData_second.name">
                      <b-row>
                        <b-col cols="12">
                          Fonksiyonda kullanmaya olmaya uygunluk
                          <b-badge :variant="is_eligible_for_being_friend_compare_two_param() ? 'success' : 'danger'"> <i class="fa fa-check"></i> </b-badge>
                        </b-col>
                      </b-row>
                      <b-row v-if="is_eligible_for_being_friend_compare_two_param() && d_isThisModelEditable">
                        <b-col cols="12">
                          <b-button style="width: 100%;" @click="f_addForEvalFunction('wdm_param');">
                            <span style="color: blue;">'{{ SelectedParamData_second.name.label }}'</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-form-group v-if="d_isThisModelEditable" label="Matematiksel İşlem" label-for="label" :label-cols="6">
                        <b-input-group>
                          <b-form-select v-model="d_selectedMathOperator">
                            <option v-for="(op, op_ind) in d_evalMathOpertors" :value="op">{{ op.label }}</option>
                          </b-form-select>
                          <b-input-group-append>
                            <b-button @click="f_addForEvalFunction('math_operator')"><i class="fa fa-plus"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group v-if="d_isThisModelEditable" :label="$t('wdm16.12832')" label-for="label" :label-cols="6">
                        <b-input-group>
                          <b-form-select v-model="d_selectedPreparedParameter">
                            <option v-for="(op, op_ind) in d_preparedParameters" :value="op">{{ op.label }}</option>
                          </b-form-select>
                          <b-input-group-append>
                            <b-button @click="f_addForEvalFunction('prepared_parameter')"><i class="fa fa-plus"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group v-if="d_isThisModelEditable" :label="$t('wdm16.12833')" label-for="label" :label-cols="6">
                        <b-input-group>
                          <b-form-input v-model="d_manualParameter"> </b-form-input>
                          <b-input-group-append>
                            <b-button @click="f_addForEvalFunction('manuel_parameter')"><i class="fa fa-plus"></i></b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </template>
                    <template v-else>
                      <strong style="color: red;">{{ $t('wdm16.7159') }}</strong>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;" v-if="SelectedParamData.name">
                <b-col sm="12" md="12" lg="12" style="padding-left: 0px;">
                  <b-card no-body class="mb-1">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      " <span>{{ SelectedParamData.name.label }}</span> " değerinin hesaplama fonksiyonu
                      <b-button class="pull-right" @click="f_cleanEvalFunction()">Clean</b-button>
                    </b-card-header>
                    <template>
                      <div v-if="SelectedParamData.eval" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <template v-for="(pr, pr_ind) in SelectedParamData.eval.parameters">
                          <span> {{ pr.label }} </span>
                        </template>
                      </div>
                      <div v-else>
                        Parametre Fonksiyon Tanımı Yok
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <!-- ******************** param_friend_function ******************** END -->
            <template v-if="selected_tab === 'param_friend' || selected_tab === 'option_friend' || selected_tab === 'option_anatomy'">
              <b-row style="margin: 0px;" v-if="selected_tab === 'option_friend' || selected_tab === 'option_anatomy' && parameter_option_method === 'manuel'">
                <b-col sm="12" md="6" lg="6" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> "{{ SelectedParamData.name.label.toLocaleUpperCase('tr') }}" {{ $t('wdm16.12835') }} </b-card-header>
                    <div style="overflow-y: auto; height: 170px; overflow-x: hidden;">
                      <b-row style="margin: 0px;" v-if="!SelectedParamData.options || (SelectedParamData.options && SelectedParamData.options.length === 0)">
                        <b-col sm="12" md="12" lg="12" style="padding: 0px; color: red;"> {{ $t('wdm16.12836') }} </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <template v-for="(op, op_ind) in SelectedParamData.options">
                            <b-row @click="selected_first_param_option = op_ind; $forceUpdate();" :class="[CalculateFirstParamOptionStyle(op_ind)]" :key="'first_param_option-' + op_ind">
                              <b-col cols="12"> {{ op.label }} </b-col>
                            </b-row>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
                <b-col sm="12" md="6" lg="6">
                  <b-card no-body class="mb-1">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <template v-if="selected_first_param_option !== '' && selected_tab === 'option_friend'">
                        "<span> {{ SelectedParamData.options[selected_first_param_option].label }}</span> " {{ $t('wdm16.12837') }}
                        <select v-if="SelectedParamData.options[selected_first_param_option].if && SelectedParamData.options[selected_first_param_option].if.friend" v-model="SelectedParamData.options[selected_first_param_option].if.friend_if_type">
                          <option value="all_friend_match">{{ $t('wdm16.12838') }}</option>
                          <option value="any_one_of_friend_match">{{ $t('wdm16.12839') }}</option>
                        </select>
                      </template>
                    </b-card-header>
                    <template v-if="selected_tab === 'option_friend' && selected_first_param_option !== ''">
                      <div v-if="SelectedParamData.options[selected_first_param_option].if" style="overflow-y: auto; height: 170px; overflow-x: hidden;">
                        <template v-for="(pr, pr_ind) in SelectedParamData.options[selected_first_param_option].if.friend">
                          <b-row v-if="pr.parent_param === 'yes'">
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col v-if="d_isThisModelEditable" sm="12" md="12" lg="2">
                                  <b-dropdown variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.options[selected_first_param_option].if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                                    <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <span v-if="upper_parameters[pr_ind]"> <strong>{{ upper_parameters[pr_ind].name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12842') }})</small> </span>
                                  <span v-else style="color: red;"> {{ $t('wdm16.12843') }} </span>
                                </b-col>
                              </b-row>
                            </b-col>
                            <template v-if="!pr.math_operator">
                              <b-col sm="12" md="12" lg="8">
                                <template v-for="(x, x_ind) in pr.operation">
                                  <div style="color: blue; padding-bottom: 3px;">
                                    <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                                      <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                                    </b-dropdown>
                                    {{ x_ind }}
                                  </div>
                                  <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                                    <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                    {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                                  </div>
                                </template>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col cols="4">
                                <b-form-group label="operator" label-for="type" :label-cols="3">
                                  <b-form-select id="math_operator.operator" v-model="pr.math_operator.operator">
                                    <option value=">"> {{ 'bigger than' }} </option>
                                    <option value="<"> {{ 'smaller than' }} </option>
                                    <option value="==="> {{ 'equals' }} </option>
                                    <option value="!=="> {{ 'not equal' }} </option>
                                    <option value="between"> {{ 'between' }} </option>
                                    <option value="not_between"> {{ 'not_between' }} </option>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col cols="4">
                                <b-form-group label="first" label-for="pr.math_operator.number_1" :label-cols="6">
                                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_1" name="pr.math_operator.number_1"></b-form-input>
                                </b-form-group>
                                <b-form-group v-if="pr.math_operator.operator === 'between' || pr.math_operator.operator === 'not_between'" label="second" label-for="pr.math_operator.number_2" :label-cols="6">
                                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_2" name="pr.math_operator.number_2"></b-form-input>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                          <b-row v-else>
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col sm="12" md="12" lg="2">
                                  <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.options[selected_first_param_option].if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                                    <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <strong>
                                    {{ pr.name.label }}
                                  </strong>
                                  <small style="color: red;"> ({{ $t('wdm16.12846') }})</small>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <template v-for="(x, x_ind) in pr.operation">
                                <div style="color: blue; padding-bottom: 3px;">
                                  <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                                  </b-dropdown>
                                  {{ x_ind }}
                                </div>
                                <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                                  <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                  {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                          <hr>
                        </template>
                        <template v-if="SelectedParamData.options[selected_first_param_option].if.mother && Object.keys(SelectedParamData.options[selected_first_param_option].if.mother).length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col v-if="d_isThisModelEditable" sm="12" md="12" lg="2">
                                  <b-dropdown variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.options[selected_first_param_option].if, 'mother'); $forceUpdate()"><i class="fa fa-trash"></i>{{ $t('wdm16.12847') }} </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <strong>{{ MotherObjectListParam.name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12848') }})</small>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <template v-for="(pr, pr_ind) in SelectedParamData.options[selected_first_param_option].if.mother">
                                <div :key="pr_ind">
                                  <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="$delete(SelectedParamData.options[selected_first_param_option].if.mother, pr_ind); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                  {{ pr.label }}
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                          <hr>
                        </template>
                      </div>
                      <div v-else>
                        Parametre arkadaş ilişkisi yok
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;" v-if="selected_tab === 'param_friend' || (selected_tab === 'option_friend' && selected_first_param_option !== '')">
                <b-col sm="12" md="6" lg="6" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.6803') }} </b-card-header>
                    <div style="overflow-y: auto; height: 250px; overflow-x: hidden;">
                      <wdm-parameters :data_type="data_type" :search_in_first_parameter_list="search_in_first_parameter_list" :option_data="option_data" :selected_index_data="selected_index_data_2" :show_parameter_features="show_parameter_features" :d_isThisModelEditable="d_isThisModelEditable" :param_change="param_change"></wdm-parameters>
                    </div>
                  </b-card>
                </b-col>
                <b-col sm="12" md="6" lg="6" style="padding-left: 0px;">
                  <template v-if="SelectedParamData_second.name">
                    <b-card class="mb-1" no-body>
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.12830') }} </b-card-header>
                      <b-row>
                        <b-col cols="12">
                          Arkadaş olmaya uygunluk
                          <b-badge :variant="is_eligible_for_being_friend_compare_two_param() ? 'success' : 'danger'"> <i class="fa fa-check"></i> </b-badge>
                        </b-col>
                      </b-row>
                      <b-row v-if="is_eligible_for_being_friend_compare_two_param() && d_isThisModelEditable">
                        <template v-if="['select', 'checkbox', 'radiogroup', 'float', 'integer'].indexOf(SelectedParamData_second.type) !== -1">
                          <b-col cols="6">
                            <b-button size="sm" style="width: 100%;" @click="add_friend('or_options');">
                              <span style="color: blue;">'{{ SelectedParamData_second.name.label }}'</span>
                              <br> {{ $t('wdm16.12852') }}
                              <br> ({{ $t('wdm16.12853') }})
                            </b-button>
                          </b-col>
                          <b-col cols="6">
                            <b-button size="sm" style="width: 100%;" @click="add_friend('or_options', true);">
                              <span style="color: blue;">'{{ SelectedParamData_second.name.label }}'</span>
                              <br> parametresine ait sayısal ifade işlemi
                            </b-button>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col cols="12">
                            <b-button size="sm" style="width: 100%;" @click="add_friend('or_options');">
                              <span style="color: blue;">'{{ SelectedParamData_second.name.label }}'</span>
                              <br> {{ $t('wdm16.12852') }}
                              <br> ({{ $t('wdm16.12853') }})
                            </b-button>
                          </b-col>
                        </template>
                      </b-row>
                    </b-card>
                    <b-card class="mb-1" no-body v-if="SelectedParamData_second.options && is_eligible_for_being_friend_compare_two_param()">
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.3796') }} </b-card-header>
                      <div style="overflow-y: auto; height: 170px; overflow-x: hidden;">
                        <template v-for="(op, op_ind) in SelectedParamData_second.options">
                          <b-row v-if="d_isThisModelEditable" @click="SelectSecondParamOption(op.value)" :class="[CalculateSecondParamOptionStyle(op.value)]" :key="'second_param_option-' + op_ind">
                            <b-col cols="12"> {{ op.label }} </b-col>
                          </b-row>
                          <b-row v-else :class="[CalculateSecondParamOptionStyle(op.value)]" :key="'second_param_option-' + op_ind">
                            <b-col cols="12"> {{ op.label }} </b-col>
                          </b-row>
                        </template>
                      </div>
                    </b-card>
                    <b-card class="mb-1" no-body v-if="MotherObjectListParam.name && SelectedParamData_second.name.value === MotherObjectListParam.name.value">
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> {{ $t('wdm16.12854') }} </b-card-header>
                      <div style="overflow-y: auto; height: 170px; overflow-x: hidden;">
                        <template v-for="(op, op_ind) in SelectedParamData_second.options">
                          <b-row v-if="d_isThisModelEditable" @click="SelectMotherParamOption(op)" :class="[CalculateMotherParamOptionStyle(op.value)]" :key="'second_param_option-' + op_ind">
                            <b-col cols="12"> {{ op.label }} </b-col>
                          </b-row>
                          <b-row v-else :class="[CalculateMotherParamOptionStyle(op.value)]" :key="'second_param_option-' + op_ind">
                            <b-col cols="12"> {{ op.label }} </b-col>
                          </b-row>
                        </template>
                      </div>
                    </b-card>
                  </template>
                  <template v-else>
                    <strong style="color: red;">{{ $t('wdm16.7159') }}</strong>
                  </template>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;" v-if="SelectedParamData.name && selected_tab === 'param_friend'">
                <b-col sm="12" md="12" lg="12" style="padding-left: 0px;">
                  <b-card no-body class="mb-1">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <b-row>
                        <b-col sm="12" md="6">
                          " <span style="color: red;">{{ SelectedParamData.name.label }}</span> " {{ $t('wdm16.6597') }}
                        </b-col>
                        <b-col sm="12" md="6">
                          <select v-if="SelectedParamData.if" v-model="SelectedParamData.if.friend_type">
                            <option value="all_friend_match">{{ $t('wdm16.12838') }}</option>
                            <option value="any_one_of_friend_match">{{ $t('wdm16.12839') }}</option>
                          </select>
                        </b-col>
                      </b-row>
                    </b-card-header>
                    <template v-if="selected_tab === 'param_friend'">
                      <div v-if="SelectedParamData.if" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <template v-for="(pr, pr_ind) in SelectedParamData.if.friend">
                          <b-row v-if="pr.parent_param === 'yes'">
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col sm="12" md="12" lg="2" v-if="d_isThisModelEditable">
                                  <b-dropdown variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                                    <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <strong>{{ pr.name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12842') }})</small>
                                </b-col>
                              </b-row>
                            </b-col>
                            <template v-if="!pr.math_operator">
                              <b-col sm="12" md="12" lg="8">
                                <template v-for="(x, x_ind) in pr.operation">
                                  <div style="color: blue; padding-bottom: 3px;">
                                    <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                                      <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                                    </b-dropdown>
                                    {{ x_ind }}
                                  </div>
                                  <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                                    <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                    {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                                  </div>
                                </template>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col cols="4">
                                <b-form-group label="operator" label-for="type" :label-cols="3">
                                  <b-form-select id="math_operator.operator" v-model="pr.math_operator.operator">
                                    <option value=">"> {{ 'bigger than' }} </option>
                                    <option value="<"> {{ 'smaller than' }} </option>
                                    <option value="==="> {{ 'equals' }} </option>
                                    <option value="!=="> {{ 'not equal' }} </option>
                                    <option value="between"> {{ 'between' }} </option>
                                    <option value="not_between"> {{ 'not_between' }} </option>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col cols="4">
                                <b-form-group label="first" label-for="pr.math_operator.number_1" :label-cols="6">
                                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_1" name="pr.math_operator.number_1"></b-form-input>
                                </b-form-group>
                                <b-form-group v-if="pr.math_operator.operator === 'between' || pr.math_operator.operator === 'not_between'" label="second" label-for="pr.math_operator.number_2" :label-cols="6">
                                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_2" name="pr.math_operator.number_2"></b-form-input>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                          <b-row v-else>
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col sm="12" md="12" lg="2" v-if="d_isThisModelEditable">
                                  <b-dropdown variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                                    <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <strong>
                                    {{ pr.name.label }}
                                  </strong>
                                  <small style="color: red;"> ({{ $t('wdm16.12846') }})</small>
                                </b-col>
                              </b-row>
                            </b-col>
                            <template v-if="!pr.math_operator">
                              <b-col sm="12" md="12" lg="8">
                                <template v-for="(x, x_ind) in pr.operation">
                                  <div style="color: blue; padding-bottom: 3px;">
                                    <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                                      <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                                    </b-dropdown>
                                    {{ x_ind }}
                                  </div>
                                  <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                                    <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                    {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                                  </div>
                                </template>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col sm="12" md="12" lg="8">deneme</b-col>
                            </template>
                          </b-row>
                          <hr>
                        </template>
                        <template v-if="SelectedParamData.if.mother && Object.keys(SelectedParamData.if.mother).length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4">
                              <b-row>
                                <b-col v-if="d_isThisModelEditable" sm="12" md="12" lg="2">
                                  <b-dropdown variant="warning" size="sm">
                                    <b-dropdown-item @click="$delete(SelectedParamData.if, 'mother'); $forceUpdate()"><i class="fa fa-trash"></i>{{ $t('wdm16.12847') }} </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <strong>{{ MotherObjectListParam.name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12848') }})</small>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <template v-for="(pr, pr_ind) in SelectedParamData.if.mother">
                                <div :key="pr_ind">
                                  <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="$delete(SelectedParamData.if.mother, pr_ind); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                                  {{ pr.label }}
                                </div>
                              </template>
                            </b-col>
                          </b-row>
                          <hr>
                        </template>
                      </div>
                      <div v-else>
                        Parametre arkadaş ilişkisi yok
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <template v-if="selected_tab === 'param_anatomy' || selected_tab === 'option_anatomy'">
              <b-row style="margin: 0px;">
                <b-col v-if="d_isThisModelEditable" sm="12" md="12" lg="12" style="padding: 0px;">
                  <anatomy :selected_anatomy_region="selected_anatomy_region" :selected_anatomy_parent="selected_anatomy_parent"></anatomy>
                </b-col>
              </b-row>
              <b-row style="margin: 0px;">
                <b-col sm="12" md="12" lg="12" style="padding-left: 0px;">
                  <b-card no-body class="mb-1">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <template v-if="SelectedParamData.name && selected_tab === 'param_anatomy'">
                        " <span>{{ SelectedParamData.name.label }}</span> " {{ $t('wdm16.12866') }}
                      </template>
                      <template v-if="selected_first_param_option !== '' && selected_tab === 'option_anatomy'">
                        "<span> {{ SelectedParamData.options[selected_first_param_option].label }}</span> " {{ $t('wdm16.12867') }}
                      </template>
                    </b-card-header>
                    <template v-if="d_isThisModelEditable">
                      <b-button size="sm" style="width: 100%; margin: 2px;" @click="add_anatomy()" v-if="selected_anatomy_region.value" :key="'anatomy-region-' + selected_anatomy_region.value">{{ $t('wdm16.12868') }}: {{ selected_anatomy_region.label }}</b-button>
                      <b-button size="sm" style="width: 100%; margin: 2px;" @click="add_parent_anatomy()" v-if="selected_anatomy_parent.value" :key="'anatomy-parent-' + selected_anatomy_parent.value">{{ $t('wdm16.11246') }}: {{ selected_anatomy_parent.label }}</b-button>
                    </template>
                    <template v-if="selected_tab === 'param_anatomy'">
                      <div v-if="SelectedParamData.if && (SelectedParamData.if.mother_anatomy || SelectedParamData.if.ana_parent)" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <template v-if="SelectedParamData.if.mother_anatomy && SelectedParamData.if.mother_anatomy.length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12869') }}</span> </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <span v-for="(ana, ana_ind) in SelectedParamData.if.mother_anatomy">
                                <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                  <b-dropdown-item @click="SelectedParamData.if.mother_anatomy.splice(ana_ind, 1); $forceUpdate()">{{ $t('wdm16.12870') }}</b-dropdown-item>
                                  <b-dropdown-item @click="copy_if_data(ana, 'mother_anatomy')">{{ $t('wdm16.12871') }}</b-dropdown-item>
                                </b-dropdown>
                                {{ ana.label }},
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                        <template v-if="SelectedParamData.if.ana_parent && SelectedParamData.if.ana_parent.length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12872') }}</span> </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <span v-for="(par, par_ind) in SelectedParamData.if.ana_parent">
                                <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                  <b-dropdown-item @click="SelectedParamData.if.ana_parent.splice(par_ind, 1); $forceUpdate()">{{ $t('wdm16.12870') }}</b-dropdown-item>
                                  <b-dropdown-item @click="copy_if_data(par, 'ana_parent')">{{ $t('wdm16.12871') }}</b-dropdown-item>
                                </b-dropdown>
                                {{ par.label }},
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                      <div v-else>
                        Parametre anatomi ilişkisi yok
                      </div>
                    </template>
                    <template v-if="selected_tab === 'option_anatomy' && selected_first_param_option !== ''">
                      <div v-if="SelectedParamData.options[selected_first_param_option].if && (SelectedParamData.options[selected_first_param_option].if.mother_anatomy || SelectedParamData.options[selected_first_param_option].if.ana_parent)" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <template v-if="SelectedParamData.options[selected_first_param_option].if.mother_anatomy && SelectedParamData.options[selected_first_param_option].if.mother_anatomy.length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12869') }}</span> </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <span v-for="(ana, ana_ind) in SelectedParamData.options[selected_first_param_option].if.mother_anatomy">
                                <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                  <b-dropdown-item @click="SelectedParamData.options[selected_first_param_option].if.mother_anatomy.splice(ana_ind, 1); $forceUpdate()">anatomi sil</b-dropdown-item>
                                  <b-dropdown-item @click="copy_if_data(ana, 'mother_anatomy')">{{ $t('wdm16.12871') }}</b-dropdown-item>
                                </b-dropdown>
                                {{ ana.label }},
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                        <template v-if="SelectedParamData.options[selected_first_param_option].if.ana_parent && SelectedParamData.options[selected_first_param_option].if.ana_parent.length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12872') }}</span> </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <span v-for="(par, par_ind) in SelectedParamData.options[selected_first_param_option].if.ana_parent">
                                <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                  <b-dropdown-item @click="SelectedParamData.options[selected_first_param_option].if.ana_parent.splice(par_ind, 1); $forceUpdate()">anatomi sil</b-dropdown-item>
                                  <b-dropdown-item @click="copy_if_data(par, 'ana_parent')">{{ $t('wdm16.12871') }}</b-dropdown-item>
                                </b-dropdown>
                                {{ par.label }},
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                      <div v-else>
                        Seçenek anatomi ilişkisi yok
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <!-- asasasas -->
            <template v-if="selected_tab === 'param_patient' || selected_tab === 'option_patient'">
              <b-row style="margin: 0px;">
                <b-col v-if="selected_tab === 'option_patient'" sm="12" md="6" lg="6" style="padding: 0px;">
                  <b-card class="mb-1" no-body>
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1"> "{{ SelectedParamData.name.label.toLocaleUpperCase('tr') }}" {{ $t('wdm16.12835') }} </b-card-header>
                    <div style="overflow-y: auto; height: 170px; overflow-x: hidden;">
                      <b-row style="margin: 0px;" v-if="!SelectedParamData.options || (SelectedParamData.options && SelectedParamData.options.length === 0)">
                        <b-col sm="12" md="12" lg="12" style="padding: 0px; color: red;"> {{ $t('wdm16.12836') }} </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <template v-for="(op, op_ind) in SelectedParamData.options">
                            <b-row @click="selected_first_param_option = op_ind; $forceUpdate();" :class="[CalculateFirstParamOptionStyle(op_ind)]" :key="'first_param_option-' + op_ind">
                              <b-col cols="12"> {{ op.label }} </b-col>
                            </b-row>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
                <b-col sm="12" :md="selected_tab === 'option_patient' ? '6' : '12'" :lg="selected_tab === 'option_patient' ? '6' : '12'">
                  <b-card no-body class="mb-1">
                    <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                      <template v-if="SelectedParamData.name && selected_tab === 'param_patient'">
                        " <span>{{ SelectedParamData.name.label }}</span> " hasta özellikleri ilişkileri
                      </template>
                      <template v-if="selected_first_param_option !== '' && selected_tab === 'option_patient'">
                        "<span> {{ SelectedParamData.options[selected_first_param_option].label }}</span> " seçeneğinin hasta özellikleri ilişkileri
                      </template>
                    </b-card-header>
                    <template v-if="d_isThisModelEditable">
                      <select class="form-control" v-model="d_selectedPatientSex" :disabled="!d_isThisModelEditable">
                        <option v-for="sex in [{'type': 'sex', 'value': 'f', 'label': 'kadın'}, {'type': 'sex', 'value': 'm', 'label': 'erkek'}]" :value="sex">
                          {{ sex.label }}
                        </option>
                      </select>
                      <b-button size="sm" style="width: 100%; margin: 2px;" @click="add_patient()" :key="'param_patient_' + 'sami'">{{ $t('wdm16.12884') }}</b-button>
                    </template>
                    <template v-if="selected_tab === 'param_patient'">
                      <div v-if="SelectedParamData.if && SelectedParamData.if.patient" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <b-row>
                          <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12885') }}</span> </b-col>
                          <b-col sm="12" md="12" lg="8">
                            <span v-for="(par, par_ind) in SelectedParamData.if.patient">
                              <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                <b-dropdown-item @click="SelectedParamData.if.patient.splice(par_ind, 1); $forceUpdate()">{{ $t('wdm16.7218') }}</b-dropdown-item>
                                <b-dropdown-item @click="copy_if_data(par, 'patient')">{{ $t('wdm16.12886') }}</b-dropdown-item>
                              </b-dropdown>
                              {{ par.label }},
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else>
                        Parametre hasta özellikleri ilişkisi yok
                      </div>
                    </template>
                    <template v-if="selected_tab === 'option_patient' && selected_first_param_option !== ''">
                      <div v-if="SelectedParamData.options[selected_first_param_option].if && SelectedParamData.options[selected_first_param_option].if.patient" style="overflow-y: auto; height: 300px; overflow-x: hidden;">
                        <template v-if="SelectedParamData.options[selected_first_param_option].if.patient && SelectedParamData.options[selected_first_param_option].if.patient.length > 0">
                          <b-row>
                            <b-col sm="12" md="12" lg="4"> <span style="color: blue;">{{ $t('wdm16.12888') }}</span> </b-col>
                            <b-col sm="12" md="12" lg="8">
                              <span v-for="(ana, ana_ind) in SelectedParamData.options[selected_first_param_option].if.patient">
                                <b-dropdown v-if="d_isThisModelEditable" size="sm">
                                  <b-dropdown-item @click="SelectedParamData.options[selected_first_param_option].if.patient.splice(ana_ind, 1); $forceUpdate()">ilişkiyi sil</b-dropdown-item>
                                </b-dropdown>
                                {{ ana.label }},
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                      </div>
                      <div v-else>
                        Seçenek hasta özellikleri ilişkisi yok
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
            </template>
            <!-- asasasas -->
            <template v-if="selected_tab === 'new_param'">
              <b-form-group :label="$t('wdm16.12890')" label-for="type" :label-cols="3">
                <b-form-select id="param_type" :plain="true" v-model="new_param.type">
                  <option v-for="(ty, ty_ind) in param_type" :value="ty"> {{ ty }} </option>
                </b-form-select>
              </b-form-group>
              <b-form-group :label="$t('wdm16.12891')" label-for="label" :state="f_checkLabelRegex(new_param.name.label)" :label-cols="3">
                <b-form-input type="text" class="form-control-warning" id="label" v-model="new_param.name.label" :state="f_checkLabelRegex(new_param.name.label)" :formatter="f_formatLabelRegex" required></b-form-input>
              </b-form-group>
              <template v-if="user.permissions_result && user.permissions_result.indexOf('wisdom-data_define-parameter-value') !== -1">
                <b-form-group :label="$t('wdm16.12892')" label-for="label" :label-cols="3">
                  <b-form-checkbox v-model="ShowDefineParameterValue"></b-form-checkbox>
                </b-form-group>
                <template v-if="ShowDefineParameterValue">
                  <b-form-group :label="$t('wdm16.12893')" label-for="value" :label-cols="3" :state="f_checkValueRegex(new_param.name.value) && !d_duplicatedValue">
                    <b-form-input type="text" class="form-control-warning" id="value" v-model="new_param.name.value" :state="f_checkValueRegex(new_param.name.value) && !d_duplicatedValue" :formatter="f_formatValueRegex" required></b-form-input>
                    <span v-if="d_duplicatedValue" style="color: red;"> {{ $t('wdm16.12894') }} </span>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>{{ $t('wdm16.12895') }}
                      <br>{{ $t('wdm16.12896') }}
                      <br>{{ $t('wdm16.12897') }}</b-form-invalid-feedback>
                  </b-form-group>
                </template>
              </template>
              <b-form-group v-if="new_param.type === 'object_list_options'" :label="$t('wdm16.12898')" label-for="object_list_mother" :label-cols="3">
                <b-form-select id="object_list_mother" :plain="true" v-model="new_param.mother_param">
                  <option v-for="(pr, pr_ind) in SelectedParamData.param_order" :value="pr" v-if="SelectedParamData.parameters[pr].type === 'object_list'">
                    {{ SelectedParamData.parameters[pr].name.label }}
                  </option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="" label-for="save" :label-cols="3" v-if="(!ShowDefineParameterValue && new_param.name.label) || (ShowDefineParameterValue && new_param.name.label && new_param.name.value && !d_duplicatedValue && f_checkValueRegex(new_param.name.value))">
                <b-button variant="success" @click="f_addNewParameter()">{{ $t('wdm16.12899') }}</b-button>
              </b-form-group>
            </template>
            <template v-if="selected_tab === 'new_param_group'">
              <b-form-group :label="$t('wdm16.12900')" label-for="label" :label-cols="3">
                <v-select v-model="SelectedParameterGroup" :options="ParameterGroupList"></v-select>
                <b-input-group-append v-if="SelectedParameterGroup">
                  <b-button variant="success" @click="AddSelectedNewParamGroup()"><i class="fa fa-plus"></i></b-button>
                </b-input-group-append>
              </b-form-group>
              <b-form-group :label="$t('wdm16.12901')" label-for="label" :label-cols="3">
                <b-form-input type="text" class="form-control-warning" id="label" v-model="new_param_group.label" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('wdm16.12902')" label-for="label" :label-cols="3">
                <b-form-checkbox v-model="iWillDefineTheGroupValue"></b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="iWillDefineTheGroupValue" :label="$t('wdm16.13271')" label-for="label" :label-cols="3">
                <b-form-input type="text" class="form-control-warning" id="label" v-model="new_param_group.value" required></b-form-input>
              </b-form-group>
              <template v-if="(iWillDefineTheGroupValue && new_param_group.label && new_param_group.value && ['wdm_settings', 'general', 'anatomy'].indexOf(new_param_group.value) === -1 && !d_duplicatedGroupName) || (!iWillDefineTheGroupValue && new_param_group.label)">
                <b-form-group label="" label-for="save" :label-cols="3">
                  <b-button variant="success" @click="f_addNewParamGroup()">{{ $t('wdm16.12903') }}</b-button>
                </b-form-group>
              </template>
              <template v-else-if="d_duplicatedGroupName">
                <span style="color: red;"> {{ $t('wdm16.12894') }} </span>
              </template>
              <template v-else-if="['wdm_settings', 'general', 'anatomy'].indexOf(new_param_group.value) !== -1">
                <span style="color: red;"> {{ new_param_group.value }} {{ $t('wdm16.12905') }} </span>
              </template>
            </template>
            <template v-if="selected_tab === 'param_group_details'">
              <b-form-group v-if="d_isThisModelEditable" :label="$t('wdm16.12906')" label-for="save" :label-cols="3">
                <b-button variant="danger" @click="delete_param_group()">{{ $t('wdm16.12907') }}</b-button>
              </b-form-group>
              <b-form-group :label="$t('wdm16.12901')" label-for="save" :label-cols="3" v-if="selected_index_data.index_list.length > 0">
                <template v-if="showEditParamGroup">
                  <b-form-input type="text" class="form-control-warning" id="label" v-model="new_param_group_label"></b-form-input>
                  <b-button variant="success" size="sm" @click="SaveEditParamGroupLabel()"><i class="fa fa-save"></i></b-button>
                </template>
                <template v-else>
                  {{ option_data[data_type].param_group['parameters'][selected_index_data.index_list[0]].label }}
                  <b-button v-if="d_isThisModelEditable" variant="primary" size="sm" @click="showEditParamGroup = true"><i class="fa fa-edit"></i></b-button>
                </template>
              </b-form-group>
              <b-form-group :label="$t('wdm16.12909')" label-for="group_value" :label-cols="3">
                {{ option_data[data_type].param_group['parameters'][selected_index_data.index_list[0]].value }}
              </b-form-group>
              <b-form-group :label="$t('wdm16.12910')" label-for="group_value" :label-cols="3">
                {{ option_data[data_type].param_group['parameters'][selected_index_data.index_list[0]].wdm16_id ? option_data[data_type].param_group['parameters'][selected_index_data.index_list[0]].wdm16_id : 'Yok' }}
              </b-form-group>
            </template>
            <template v-if="SelectedParamData.name && selected_tab === 'param_details'">
              <b-card no-body class="mb-1">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">{{ $t('wdm16.3317') }} ( {{ SelectedParamData.name.label.toLocaleUpperCase('tr') }} ) </b-card-header>
                <div :style="f_mainParameterSelectionStyle()">
                  <b-form-group v-if="d_isThisModelEditable" label="Parametre silme işlemi" label-for="parameter_deleting" :label-cols="6">
                    <b-button variant="danger" @click="delete_parameter()">{{ $t('wdm16.12911') }}</b-button>
                  </b-form-group>
                  <b-form-group :label="$t('wdm16.12912')" label-for="parameter_name" :label-cols="6">
                    <template v-if="d_isThisModelEditable">
                      <b-button v-if="!show_edit_parameter_label" size="sm" @click="EditParameterLabel()"><i class="fa fa-edit"></i></b-button>
                      <b-button v-if="show_edit_parameter_label" size="sm" @click="SaveParameterLabel()"><i class="fa fa-save"></i></b-button>
                    </template>
                    <template v-if="show_edit_parameter_label">
                      <b-form-input type="text" id="show_edit_parameter_label" v-model="new_param_label"></b-form-input>
                    </template>
                    <template v-else>
                      {{ SelectedParamData.name.label.toLocaleUpperCase('tr') }}
                    </template>
                  </b-form-group>
                  <b-form-group :label="$t('wdm16.12893')" label-for="parameter_value" :label-cols="6">
                    {{ SelectedParamData.name.value }}
                  </b-form-group>
                  <b-form-group :label="$t('wdm16.12914')" label-for="parameter_lang_id" :label-cols="6">
                    {{ SelectedParamData.name.wdm16_id ? SelectedParamData.name.wdm16_id : $t('wdm16.12633') }}
                  </b-form-group>
                  <b-form-group v-if="SelectedParamData.type === 'textarea'" :label="$t('wdm16.12915')" label-for="row_count" :label-cols="6">
                    <b-form-select v-model="SelectedParamData.rows" :disabled="!d_isThisModelEditable">
                      <option v-for="(row) in 30">{{ row }}</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group v-if="SelectedParamData.type === 'select' || SelectedParamData.type === 'radiogroup'" :label="$t('wdm16.12915')" label-for="row_count" :label-cols="6">
                    <b-form-select v-model="SelectedParamData.type" :disabled="!d_isThisModelEditable">
                      <option value="select">{{ $t('wdm16.12916') }}</option>
                      <option value="radiogroup">{{ $t('wdm16.12917') }}</option>
                    </b-form-select>
                  </b-form-group>
                  <template v-if="d_isThisModelEditable">
                    <b-form-group v-if="['integer', 'float', 'date', 'select', 'object_list'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12918')" label-for="rule_creating" :label-cols="6">
                      <b-badge :variant="SelectedParamData.rule ? 'success' : 'danger'" style="cursor: pointer;" @click="param_rule_func(SelectedParamData)"> {{ $t('wdm16.12777') }} </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12956')" label-for="recording_requirement" :label-cols="6">
                      <b-badge :variant="SelectedParamData.required === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_require_func(SelectedParamData)"> {{ $t('wdm16.12776') }} </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12957')" label-for="show_param_while_recording" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_func(SelectedParamData)"> {{ $t('wdm16.12921') }} </b-badge>
                    </b-form-group>
                    <b-form-group label="Kaydederken Etkin" label-for="show_param_while_recording" :label-cols="6">
                      <b-badge :variant="!SelectedParamData.disabled ? 'success' : 'danger'" style="cursor: pointer;" @click="param_ability(SelectedParamData)"> {{ $t('wdm16.12922') }} </b-badge>
                    </b-form-group>
                    <b-form-group v-if="option_data[data_type].favorite_show_mode === 'yes'" :label="$t('wdm16.11344')" label-for="favorite_show_mode" :label-cols="6">
                      <b-badge :variant="SelectedParamData.fav_show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_fav_show_func(SelectedParamData)"> {{ $t('wdm16.11344') }} (FS) </b-badge>
                    </b-form-group>
                    <b-form-group v-if="option_data[data_type].show_last_recorded_value === 'yes'" :label="$t('wdm16.11348')" label-for="show_last_recorded_value" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_last_recorded_value === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_last_recorded_value(SelectedParamData)"> {{ $t('wdm16.11348') }} (SLRV) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12923')" label-for="lab_unit" :label-cols="6">
                      <b-badge :variant="SelectedParamData.lab_unit ? 'success' : 'danger'" style="cursor: pointer;" @click="param_lab_unit_func(SelectedParamData)"> {{ $t('wdm16.12924') }} (LU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12925')" label-for="show_size_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_size_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_size_units_func(SelectedParamData)"> {{ $t('wdm16.12926') }} (SU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12927')" label-for="show_weight_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_weight_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_weight_units_func(SelectedParamData)"> {{ $t('wdm16.12928') }} (WU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12929')" label-for="show_dosage_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_dosage_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_dosage_units_func(SelectedParamData)"> {{ $t('wdm16.12930') }} (DU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12931')" label-for="not_schema" :label-cols="6">
                      <b-badge :variant="SelectedParamData.not_schema ? 'success' : 'danger'" style="cursor: pointer;" @click="param_not_schema_func(SelectedParamData)"> {{ $t('wdm16.12931') }} (Ş) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12933')" label-for="anonym" :label-cols="6">
                      <b-badge :variant="SelectedParamData.anonym ? 'success' : 'danger'" style="cursor: pointer;" @click="param_anonym_func(SelectedParamData)"> {{ $t('wdm16.12933') }} (A) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12935')" label-for="auto_naming" :label-cols="6">
                      <b-badge :variant="SelectedParamData.naming ? 'success' : 'danger'" style="cursor: pointer;" @click="param_name_func(SelectedParamData)"> {{ $t('wdm16.12935') }} (N) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.13272')" label-for="dont_use_label" :label-cols="6">
                      <b-badge :variant="SelectedParamData.dont_use_label ? 'success' : 'danger'" style="cursor: pointer;" @click="param_dont_use_label_func(SelectedParamData)"> {{ $t('wdm16.13272') }} (DL) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12939')" label-for="show_after_recorded" :label-cols="6">
                      <b-badge :variant="SelectedParamData.page_not_show ? 'danger' : 'success'" style="cursor: pointer;" @click="param_page_not_show_func(SelectedParamData)"> {{ $t('wdm16.12939') }} (SAR) </b-badge>
                    </b-form-group>
                    <b-form-group v-if="page_type === 'hospital_settings'" :label="$t('wdm16.12941')" label-for="hims_match_operations" :label-cols="6">
                      <b-badge :variant="SelectedParamData.hims_match_operations ? 'success' : 'danger'" style="cursor: pointer;" @click="param_hims_match_operations(SelectedParamData)"> {{ $t('wdm16.12941') }} (HMO) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.1675')" label-for="SelectedParamData_info" :label-cols="6">
                      <b-textarea id="SelectedParamData_info" v-model="SelectedParamData.info"></b-textarea>
                    </b-form-group>
                    <b-form-group v-if="['integer', 'float'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12943')" label-for="SelectedParamData_max" :label-cols="6">
                      <b-form-input type="number" min="0" step="1" class="form-control" v-model="SelectedParamData.min" :name="$t('wdm16.12943')"></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="['text', 'textarea', 'integer', 'float'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12945')" label-for="SelectedParamData_max" :label-cols="6">
                      <b-form-input type="number" min="0" step="1" class="form-control" v-model="SelectedParamData.max" :name="$t('wdm16.12945')"></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="['select', 'object_list'].indexOf(SelectedParamData.type) !== -1" label="Selectbox Türü" label-for="SelectedParamData_selectbox_type" :label-cols="6">
                      <b-form-select id="show_parameter_features" :plain="true" v-model="SelectedParamData.selectbox_type">
                        <option value="vselect">{{ $t('wdm16.12947') }} </option>
                        <option value="listbox">{{ $t('wdm16.12948') }}</option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group v-if="['object_list', 'object_list_options', 'list_parameter'].indexOf(SelectedParamData.type) === -1" :label="$t('wdm16.12953')" label-for="SelectedParamData_wdm_specific" :label-cols="6">
                      <b-form-select id="wdm_specific" :plain="true" v-model="SelectedParamData.wdm_specific">
                        <option value="table">{{ $t('wdm16.12949') }}</option>
                        <option value="sentence">{{ $t('wdm16.12950') }}</option>
                        <option value="polestar">{{ $t('wdm16.12951') }}</option>
                        <option value="not_show">{{ $t('wdm16.12952') }}</option>
                      </b-form-select>
                    </b-form-group>
                  </template>
                  <template v-else>
                    <b-form-group v-if="['integer', 'float', 'date', 'select', 'object_list'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12918')" label-for="rule_creating" :label-cols="6">
                      <b-badge :variant="SelectedParamData.rule ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.11468') }} (RU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12956')" label-for="recording_requirement" :label-cols="6">
                      <b-badge :variant="SelectedParamData.required === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12955') }} (RE) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12957')" label-for="show_param_while_recording" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12958') }} (SOR) </b-badge>
                    </b-form-group>
                    <b-form-group v-if="option_data[data_type].favorite_show_mode === 'yes'" :label="$t('wdm16.11344')" label-for="favorite_show_mode" :label-cols="6">
                      <b-badge :variant="SelectedParamData.fav_show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.11344') }} (FS) </b-badge>
                    </b-form-group>
                    <b-form-group v-if="option_data[data_type].show_last_recorded_value === 'yes'" :label="$t('wdm16.11348')" label-for="show_last_recorded_value" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_last_recorded_value === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.11348') }} (SLRV) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12923')" label-for="lab_unit" :label-cols="6">
                      <b-badge :variant="SelectedParamData.lab_unit ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12924') }} (LU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12925')" label-for="show_size_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_size_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12926') }} (SU) </b-badge>
                    </b-form-group>
                    <b-form-group label="$t('wdm16.12927')" label-for="show_weight_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_weight_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12928') }} (WU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12929')" label-for="show_dosage_units" :label-cols="6">
                      <b-badge :variant="SelectedParamData.show_dosage_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12930') }} (DU) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12931')" label-for="not_schema" :label-cols="6">
                      <b-badge :variant="SelectedParamData.not_schema ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12931') }} (Ş) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12933')" label-for="anonym" :label-cols="6">
                      <b-badge :variant="SelectedParamData.anonym ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12933') }} (A) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12935')" label-for="auto_naming" :label-cols="6">
                      <b-badge :variant="SelectedParamData.naming ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12935') }} (N) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12935')" label-for="dont_use_label" :label-cols="6">
                      <b-badge :variant="SelectedParamData.dont_use_label ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12938') }} (DL) </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12939')" label-for="show_after_recorded" :label-cols="6">
                      <b-badge :variant="SelectedParamData.page_not_show ? 'danger' : 'success'" style="cursor: pointer;"> {{ $t('wdm16.12939') }} (SAR) </b-badge>
                    </b-form-group>
                    <b-form-group v-if="page_type === 'hospital_settings'" :label="$t('wdm16.12941')" label-for="hims_match_operations" :label-cols="6">
                      <b-badge :variant="SelectedParamData.hims_match_operations ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12784') }} </b-badge>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.1675')" label-for="SelectedParamData_info" :label-cols="6">
                      {{ SelectedParamData.info }}
                    </b-form-group>
                    <b-form-group v-if="['integer', 'float'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12943')" label-for="SelectedParamData_max" :label-cols="6">
                      {{ SelectedParamData.min }}
                    </b-form-group>
                    <b-form-group v-if="['text', 'textarea', 'integer', 'float'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12945')" label-for="SelectedParamData_max" :label-cols="6">
                      {{ SelectedParamData.max }}
                    </b-form-group>
                    <b-form-group v-if="['select', 'object_list'].indexOf(SelectedParamData.type) !== -1" label="Selectbox Türü" label-for="SelectedParamData_selectbox_type" :label-cols="6">
                      {{ SelectedParamData.selectbox_type }}
                    </b-form-group>
                    <b-form-group v-if="['object_list', 'object_list_options', 'list_parameter'].indexOf(SelectedParamData.type) === -1" :label="$t('wdm16.12953')" label-for="SelectedParamData_wdm_specific" :label-cols="6">
                      {{ SelectedParamData.wdm_specific }}
                    </b-form-group>
                  </template>
                  <b-form-group v-if="SelectedParamData.show_last_recorded_value && SelectedParamData.show_last_recorded_value === 'yes'" :label="$t('wdm16.12974')" label-for="last_how_much_records_will_be_shown" :label-cols="6">
                    <b-form-select v-model="SelectedParamData.last_show_count" :disabled="!d_isThisModelEditable">
                      <option v-for="(x, x_ind) in 10" :value="x">{{ x }}</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group v-if="['select', 'object_list', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(SelectedParamData.type) !== -1" :label="$t('wdm16.12975')" label-for="parameter_option_method" :label-cols="6">
                    <b-form-select id="parameter_option_method" :plain="true" v-model="parameter_option_method" :disabled="!d_isThisModelEditable">
                      <option value="manuel">{{ $t('wdm16.12976') }}</option>
                      <option value="option_search">{{ $t('wdm16.12977') }}</option>
                      <option value="another_function_fills">{{ $t('wdm16.12978') }}</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group v-if="parameter_option_method === 'option_search'" :label="$t('wdm16.12975')" label-for="parameter_option_method_2" :label-cols="6">
                    <b-form-select id="parameter_option_search_with_function" :plain="true" v-model="SelectedParamData.option_search" :disabled="!d_isThisModelEditable">
                      <option v-for="parameter_function_option in d_parameterFunctionOptions" :value="parameter_function_option.value">{{ parameter_function_option.label }}</option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group v-if="parameter_option_method === 'option_search' && SelectedParamData.option_search === 'get_hospital_wtl_list'" :label="$t('wdm16.12980')" label-for="option_search_hospital_wtl" :label-cols="6">
                    <b-form-select id="parameter_option_search_hospital_wtl_with_function" :plain="true" v-model="SelectedParamData.option_search_hospital_wtl" :disabled="!d_isThisModelEditable">
                      <option value="department">{{ $t('wdm16.11428') }}</option>
                      <option value="hospital_location">{{ $t('wdm16.12981') }}</option>
                    </b-form-select>
                  </b-form-group>
                  <!-- SAMI SAMİ sami -->
                  <b-form-group v-if="SelectedParamData.option_search === 'get_patient_wdmr_list'" label="WDMR Türünü Seçin" label-for="option_search_patient_wdmr" :label-cols="6">
                    <b-form-select id="parameter_option_search_patient_wdmr_with_function" :plain="true" v-model="SelectedParamData.option_search_patient_wdmr" :disabled="!d_isThisModelEditable">
                      <option value="treatment">{{ $t('wdm16.12982') }}</option>
                    </b-form-select>
                  </b-form-group>
                  <template v-if="SelectedParamData.type === 'object_list'">
                    <template v-if="SelectedParamData.object_type && SelectedParamData.object_param && upper_parameters[SelectedParamData.object_param]">
                      {{ upper_parameters[SelectedParamData.object_param].object_type = SelectedParamData.object_type }}
                    </template>
                    <b-form-group label="Obje parametresi" label-for="object_param" :label-cols="6">
                      <select class="form-control" v-model="SelectedParamData.object_param" :disabled="!d_isThisModelEditable">
                        <option v-for="(other_param, other_param_ind) in upper_param_order" :value="upper_parameters[other_param].name" v-if="upper_parameters[other_param].type === 'object_list_options'">
                          {{ upper_parameters[other_param].name.label }}
                        </option>
                      </select>
                    </b-form-group>
                    <b-form-group :label="$t('wdm16.12983')" label-for="object_type" :label-cols="6">
                      <select class="form-control" v-model="SelectedParamData.object_type" :disabled="!d_isThisModelEditable">
                        <option v-for="(object_type, object_type_ind) in ['object']" :value="object_type">
                          {{ object_type }}
                        </option>
                      </select>
                    </b-form-group>
                  </template>
                  <b-form-group v-if="SelectedParamData.type === 'object_list_options'" :label="$t('wdm16.12984')" label-for="object_record_type" :label-cols="6">
                    {{ SelectedParamData.object_type }}
                  </b-form-group>
                  <b-form-group v-if="select_types.indexOf(SelectedParamData.type) !== -1 && parameter_option_method === 'manuel'" :label="$t('wdm16.12985')" label-for="parameter_options" :label-cols="6">
                    <v-select v-model="SelectedParamData.default" :options="SelectedParamData.options"></v-select>
                  </b-form-group>
                  <b-row>
                    <b-col sm="12" md="3">
                      Parametre resmi
                      <b-button v-if="SelectedParamData.image_info && SelectedParamData.image_info.data !== ''" @click="f_deleteParamImage()"> <i class="fa fa-trash"></i> {{ $t('wdm16.12987') }} </b-button>
                    </b-col>
                    <b-col sm="12" md="3">
                      <b-form-file @input="f_addImageToSelectedParameter()" v-model="d_selectedImage" accept="jpeg,jpg,png" :placeholder="$t('wdm16.12988')" :drop-placeholder="$t('wdm16.12989')"></b-form-file>
                    </b-col>
                    <b-col sm="12" md="3">
                      <template v-if="SelectedParamData.image_info && SelectedParamData.image_info.data !== ''">
                        <b-form-select v-model="SelectedParamData.image_info.side">
                          <option value="icon">{{ $t('wdm16.12990') }}</option>
                          <option value="bottom">{{ $t('wdm16.12991') }}</option>
                        </b-form-select>
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <img :src="SelectedParamData.image_info.data" style="width: 25%;">
                            <!-- <a :href="data[param].val" download>Dökümanı İndir</a> -->
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </template>
            <template v-if="SelectedParamData.name && selected_tab === 'parameterOptions'">
              <b-card no-body class="mb-1">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">{{ $t('wdm16.3796') }}</b-card-header>
                <b-row v-if="d_isThisModelEditable">
                  <b-col sm="12" md="12" lg="2"> <strong>{{ $t('wdm16.12992') }}</strong> </b-col>
                  <b-col sm="12" md="12" lg="8">
                    <b-row>
                      <b-col sm="12" lg="6">{{ $t('wdm16.11292') }}</b-col>
                      <b-col sm="12" lg="6">
                        <b-form-input type="text" id="new_parameter_option_label" v-model="new_parameter_option.label"></b-form-input>
                      </b-col>
                    </b-row>
                    <template v-if="user.permissions_result && user.permissions_result.indexOf('wisdom-data_define-option-value') !== -1">
                      <template v-if="ShowDefineOptionValue">
                        <b-row>
                          <b-col sm="12" lg="6">{{ $t('wdm16.12993') }}</b-col>
                          <b-col sm="12" lg="6">
                            <b-form-input type="text" id="new_parameter_option_value" v-model="new_parameter_option.value"></b-form-input>
                          </b-col>
                        </b-row>
                      </template>
                      <b-row>
                        <b-col sm="12" lg="6">{{ $t('wdm16.12994') }}</b-col>
                        <b-col sm="12" lg="6">
                          <b-form-checkbox v-model="ShowDefineOptionValue"></b-form-checkbox>
                        </b-col>
                      </b-row>
                    </template>
                  </b-col>
                  <b-col sm="12" md="12" lg="2">
                    <b-button v-if="(!ShowDefineOptionValue && new_parameter_option.label) || (ShowDefineOptionValue && new_parameter_option.label && new_parameter_option.value)" size="sm" @click="AddNewParameterOption()"><i class="fa fa-plus"></i></b-button>
                  </b-col>
                </b-row>
                <b-row v-if="d_isThisModelEditable">
                  <b-col sm="12" md="12" lg="2"> <strong>{{ $t('wdm16.12995') }}</strong> </b-col>
                  <b-col sm="12" md="12" lg="8">
                    <b-dropdown variant="primary">
                      <template slot="button-content">{{ $t('wdm16.12995') }}</template>
                      <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('wisdom-data_add-options-from-json') !== -1" @click="f_showAddBulkOptionsModal()"> <i class="fa fa-plus fa-lg mt-4"></i> {{ $t('wdm16.13648') }} </b-dropdown-item>
                      <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('wisdom-data_add-options-from-json') !== -1" @click="ShowAddOptionsFromJsonText()"> <i class="fa fa-plus fa-lg mt-4"></i> {{ $t('wdm16.12997') }} </b-dropdown-item>
                      <b-dropdown-item @click="order_list('label')"><i class="fa fa-arrows fa-lg mt-4"></i>{{ $t('wdm16.12998') }} </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <b-card-header header-bg-variant="primary" class="p-1">
                  <b-row>
                    <b-col sm="12" md="3">
                      Seçenek Başlığı
                    </b-col>
                    <b-col sm="12" md="2">
                      Kodlar
                    </b-col>
                    <b-col sm="12" md="2">
                      Sayısal Değer
                    </b-col>
                    <b-col sm="12" md="3">
                      Resim
                    </b-col>
                    <b-col sm="12" md="2">
                      Eklenen Resim
                    </b-col>
                  </b-row>
                </b-card-header>
                <div style="overflow-y: auto; height: 500px; overflow-x: hidden; width: 100%">
                  <b-row v-for="(op, op_ind) in SelectedParamData.options" :key="op.value + '-' + op_ind">
                    <b-col sm="12" md="3">
                      <b-dropdown variant="primary" v-if="d_isThisModelEditable">
                        <template slot="button-content"></template>
                        <b-dropdown-item @click="EditParameterOption(op_ind)"> <i class="fa fa-edit"></i> {{ $t('wdm16.13001') }} </b-dropdown-item>
                        <b-dropdown-item @click="DeleteParameterOption(op_ind)"> <i class="fa fa-trash"></i> {{ $t('wdm16.13002') }} </b-dropdown-item>
                        <b-dropdown-item v-if="op.image_info && op.image_info.data !== ''" @click="f_deleteOptionImage(op_ind)"> <i class="fa fa-trash"></i> {{ $t('wdm16.12987') }} </b-dropdown-item>
                        <b-dropdown-item @click="list_param_order(SelectedParamData.options, op_ind, 'up')"><i class="fa fa-arrow-circle-o-up fa-lg mt-4"></i>{{ $t('wdm16.13004') }}</b-dropdown-item>
                        <b-dropdown-item @click="list_param_order(SelectedParamData.options, op_ind, 'down')"><i class="fa fa-arrow-circle-down fa-lg mt-4"></i>{{ $t('wdm16.905') }}</b-dropdown-item>
                        <b-dropdown-item @click="list_param_order(SelectedParamData.options, op_ind, 'top')"><i class="fa fa-arrow-up fa-lg mt-4"></i>{{ $t('wdm16.13005') }}</b-dropdown-item>
                        <b-dropdown-item @click="list_param_order(SelectedParamData.options, op_ind, 'bottom')"><i class="fa fa-arrow-down fa-lg mt-4"></i>{{ $t('wdm16.13006') }}</b-dropdown-item>
                      </b-dropdown>
                      <template v-if="EditingParameterOptionIndex === op_ind && d_isThisModelEditable">
                        <b-button size="sm" @click="CancelEditingParameterOptionLabel()"><i class="fa fa-ban"></i></b-button>
                        <b-button size="sm" v-if="editing_parameter_option" @click="SaveParameterOption()"><i class="fa fa-save"></i></b-button>
                        <b-form-input type="text" id="EditingParameterOptionIndex" v-model="editing_parameter_option"></b-form-input>
                      </template>
                      {{ op.label }}
                      <small :style="op.wdm16_id ? 'color: green;' : 'color: red;'">
                           ( {{ op.wdm16_id ? 'dil no: ' + op.wdm16_id : 'dil no yok' }} )
                         </small>
                    </b-col>
                    <b-col sm="12" md="2">
                      {{ op.value }}
                    </b-col>
                    <b-col sm="12" md="2">
                      <b-form-input type="number" step="0.01" class="form-control" v-model="op.number_value" name="op.number"></b-form-input>
                    </b-col>
                    <b-col sm="12" md="3">
                      <b-form-file @input="f_addImageToOption(op_ind)" v-model="d_optionImage" accept="jpeg,jpg,png" :placeholder="$t('wdm16.11462')" :drop-placeholder="$t('wdm16.11463')"></b-form-file>
                    </b-col>
                    <b-col sm="12" md="2">
                      <template v-if="op.image_info && op.image_info.data !== ''">
                        <b-form-select v-model="op.image_info.side">
                          <option value="icon">{{ $t('wdm16.12990') }}</option>
                          <option value="bottom">{{ $t('wdm16.12991') }}</option>
                        </b-form-select>
                        <b-row style="margin: 0px;">
                          <b-col cols="12">
                            <img :src="op.image_info.data" style="width: 25%;">
                            <!-- <a :href="data[param].val" download>Dökümanı İndir</a> -->
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_copy_parameter" @close="show_copy_parameter = false" :width="'1500'">
      <h3 slot="header">{{ $t('wdm16.13009') }}</h3>
      <div slot="body">
        <option-copy :new_copy_parameter="new_copy_parameter" :param_data="new_param_data"></option-copy>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="add_new_copy_parameter()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_copy_parameter = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_parameter_json" @close="show_parameter_json = false" :width="'1000'">
      <h3 slot="header">{{ $t('wdm16.13010') }}</h3>
      <div slot="body">
        <textarea style="width: 80%;" v-model="param_json_data"></textarea>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="show_parameter_json = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_new_parameter_from_json" @close="show_new_parameter_from_json = false" :width="'1000'">
      <h3 slot="header">{{ $t('wdm16.13010') }}</h3>
      <div slot="body">
        Eklemek istediğiniz parametrenin json yapısını ekleyiniz:
        <textarea style="width: 80%;" v-model="param_json_data"></textarea>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="add_new_param_from_json_data()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_new_parameter_from_json = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="ShowJsonOptions" @close="ShowJsonOptions = false" :width="'1000'">
      <h3 slot="header">{{ $t('wdm16.13013') }}</h3>
      <div slot="body">
        <b-row>
          <b-col sm="12" lg="4">{{ $t('wdm16.13014') }}</b-col>
          <b-col sm="12" lg="8">
            <b-form-select v-model="JsonOptions.is_deleted">
              <option value="0">{{ $t('wdm16.76') }}</option>
              <option value="1">{{ $t('wdm16.7249') }}</option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="4">{{ $t('wdm16.13015') }}</b-col>
          <b-col sm="12" lg="8">
            <b-form-select v-model="JsonOptions.create_new_value">
              <option value="0">{{ $t('wdm16.76') }}</option>
              <option value="1">{{ $t('wdm16.7249') }}</option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="4">{{ $t('wdm16.13016') }}</b-col>
          <b-col sm="12" lg="8">
            <textarea style="width: 80%;" v-model="JsonOptions.json_text" rows="20"></textarea>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button v-if="JsonOptions.json_text && JsonOptions.create_new_value && JsonOptions.is_deleted" type="button" class="btn btn-success" v-on:click="AddJsonOptionsToThisParameter()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="ShowJsonOptions = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_carry_param" @close="show_carry_param = false" :width="'1000'">
      <h3 slot="header">{{ $t('wdm16.13017') }}</h3>
      <div slot="body">
        <div class="form-group row">
          <div class="col-2">
            taşınacak sırayı giriniz
          </div>
          <div class="col-10">
            <input type="number" v-model="carry_param.order" class="form-control">
          </div>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" v-on:click="carry_param_func()">{{ $t('wdm16.13019') }}</button>
        <button type="button" class="btn btn-danger" @click="show_carry_param = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_new_wisdom_data_model" @close="show_new_wisdom_data_model = false" :width="'750'">
      <h3 slot="header"> {{ $t('wdm16.13020') }} </h3>
      <div slot="body">
        <b-form-group :label="$t('wdm16.11292')" label-for="caption" :label-cols="4">
          <b-form-input type="text" class="form-control" v-model="new_wisdom_data_model.name.label" name="caption"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11333')" label-for="owner" :label-cols="4">
          {{ new_wisdom_data_model.owner.label }}
        </b-form-group>
        <b-form-group :label="$t('wdm16.11347')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.checked_on_open" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11327')" label-for="type" :label-cols="4">
          {{ new_wisdom_data_model.version }}
        </b-form-group>
        <b-form-group :label="$t('wdm16.11325')" label-for="usage_in_rule_engine" :label-cols="4">
          <select v-model="new_wisdom_data_model.rule_parameter" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11326')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.send_to_hims_protocol" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11328')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.department_filter_mode" class="form-control">
            <option value="not_eligible_for_department_relation">{{ $t('wdm16.11329') }}</option>
            <option value="filter_with_department">{{ $t('wdm16.11330') }}</option>
            <option value="all_departments">{{ $t('wdm16.11331') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11338')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.edit_in_hospital_settings" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11334')" label-for="type" :label-cols="4">
          <v-select v-model="new_wisdom_data_model.language" :options="wisdomera_system_languages"></v-select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11339')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.permission_by_superuser" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11340')" label-for="type" :label-cols="4">
          <b-form-checkbox-group v-model="new_wisdom_data_model.permissions_list_for_superuser">
            <b-form-checkbox name="add_edit" value="record_add_edit"> {{ $t('wdm16.7020') }}</b-form-checkbox>
            <b-form-checkbox name="view" value="record_view"> {{ $t('wdm16.6581') }}</b-form-checkbox>
            <b-form-checkbox name="edit_data_model" value="edit_data_model"> {{ $t('wdm16.6698') }}</b-form-checkbox>
            <b-form-checkbox name="give_permission" value="give_permission"> {{ $t('wdm16.7131') }}</b-form-checkbox>
            <b-form-checkbox name="create_new_version" value="create_new_version"> {{ $t('wdm16.7145') }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11349')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.model" class="form-control">
            <option value="wisdom_data">wisdom verisi</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11344')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.favorite_show_mode" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11345')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.bmi_gfr" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11346')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.calculate_eval" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13026')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.date_ability" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13027')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.date_ability_user_selection" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group v-if="new_wisdom_data_model.date_ability === 'yes'" :label="$t('wdm16.12748')" label-for="date_ability_type" :label-cols="4">
          <select v-model="new_wisdom_data_model.date_ability_type" class="form-control">
            <option value="year">{{ $t('wdm16.12749') }}</option>
            <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
            <option value="date">{{ $t('wdm16.12751') }}</option>
            <option value="datetime">{{ $t('wdm16.12752') }}</option>
          </select>
        </b-form-group>
        <b-form-group v-if="new_wisdom_data_model.date_ability === 'yes'" :label="$t('wdm16.12753')" label-for="date_ability_limit" :label-cols="4">
          <select v-model="new_wisdom_data_model.date_ability_limit" class="form-control">
            <option value="none">{{ $t('wdm16.12754') }}</option>
            <option value="before">{{ $t('wdm16.12755') }}</option>
            <option value="after">{{ $t('wdm16.12756') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13033')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.ending_date_ability" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.12758')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.ending_date_ability_user_selection" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group v-if="new_wisdom_data_model.ending_date_ability === 'yes'" :label="$t('wdm16.12748')" label-for="ending_date_ability_type" :label-cols="4">
          <select v-model="new_wisdom_data_model.ending_date_ability_type" class="form-control">
            <option value="year">{{ $t('wdm16.12749') }}</option>
            <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
            <option value="date">{{ $t('wdm16.12751') }}</option>
            <option value="datetime">{{ $t('wdm16.12752') }}</option>
          </select>
        </b-form-group>
        <b-form-group v-if="new_wisdom_data_model.ending_date_ability === 'yes'" :label="$t('wdm16.12753')" label-for="ending_date_ability_limit" :label-cols="4">
          <select v-model="new_wisdom_data_model.ending_date_ability_limit" class="form-control">
            <option value="none">{{ $t('wdm16.12754') }}</option>
            <option value="before">{{ $t('wdm16.12755') }}</option>
            <!-- <option value="after">{{ $t('wdm16.12756') }}</option> -->
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.6299')" label-for="type" :label-cols="4">
          <select v-model="new_wisdom_data_model.record_count" class="form-control">
            <option value="not_recording">{{ $t('wdm16.11335') }}</option>
            <option value="one_time">{{ $t('wdm16.11336') }}</option>
            <option value="many_times">{{ $t('wdm16.11337') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.11341')" label-for="wdm_schema" :label-cols="4">
          <select v-model="new_wisdom_data_model.schema" class="form-control">
            <option value="yes">{{ $t('wdm16.7249') }}</option>
            <option value="no">{{ $t('wdm16.76') }}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13038')" label-for="wdm_owner_type" :label-cols="4">
          <template v-if="user.permissions_result.indexOf('wisdom') !== -1">
            <select v-model="new_wisdom_data_model.owner_type" class="form-control">
              <option value="wisdom">wisdom</option>
              <option value="user">{{ $t('wdm16.13039') }}</option>
            </select>
          </template>
          <template v-else>
            {{ new_wisdom_data_model.owner_type }}
          </template>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13040')" label-for="wdm_follow_up_location" :label-cols="4">
          <b-form-checkbox-group v-model="new_wisdom_data_model.follow_up_location">
            <b-form-checkbox type="checkbox" name="oncology" value="patient_follow"> {{ $t('wdm16.12769') }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group :label="$t('wdm16.13042')" label-for="about_wdm" :label-cols="4">
          <textarea style="width: 80%;" class="form-control" v-model="about_text"></textarea>
          <button type="button" class="btn btn-primary fa fa-plus" @click="add_wisdom_data_about()"></button>
          <br>
          <b-row>
            <b-col cols="12">
              <span v-for="(ab, ab_ind) in new_wisdom_data_model.about">
                <button style="margin: 3px;" type="button" class="btn btn-primary fa fa-trash" @click="new_wisdom_data_model.about.splice(ab_ind, 1)"></button>
                {{ ab_ind + 1 }} - {{ ab }}
                <br>
              </span>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="save_wisdom_data_model()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-secondary" @click="show_new_wisdom_data_model = false">{{ $t('wdm16.3975') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTestWdmModal" @close="d_showTestWdmModal = false" :width="'1000'">
      <h3 slot="header">{{ option_data[data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal :test_mode="true" :external_patient_data="test_wdm_patient" ref="wisdom_data_modal" :data="test_wdm_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        <!--               <wisdom-data-modal-template-study :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal-template-study>
        -->
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showTestWdmModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showWdmStyleModal" @close="d_showWdmStyleModal = false" :width="'1000'">
      <h3 slot="header">{{ option_data[data_type].name.label }} {{ $t('wdm16.13043') }}</h3>
      <div slot="body">
        <wdm-and-parameter-style :p_styleData="option_data[data_type].style"></wdm-and-parameter-style>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showWdmStyleModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showAddBulkOptionsModal" @close="d_showAddBulkOptionsModal = false" :width="'1000'">
      <h3 slot="header">{{ $t('wdm16.13648') }}</h3>
      <div slot="body">
        <b-card style="margin-bottom: 0px;" no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col cols="11" class="text-center">
                {{ $t('wdm16.13649') }}
              </b-col>
              <b-col cols="1">
                <b-button class="pull-right" size="sm" variant="primary" @click="d_addBulkOptionsData.show_input ? d_addBulkOptionsData.show_input = false : d_addBulkOptionsData.show_input = true"><i class="fa fa-edit"></i></b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_addBulkOptionsData.show_input">
            <b-row style="padding: 3px;">
              <b-col cols="12">
                <b-form-group :label="$t('wdm16.13650')" label-for="d_addBulkOptionsData.seperator" :label-cols="3">
                  <b-form-input type="text" class="form-control-warning" id="d_addBulkOptionsData.seperator" v-model="d_addBulkOptionsData.seperator"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group :label="$t('wdm16.13651')" label-for="d_addBulkOptionsData.input" :label-cols="3">
                  <b-form-textarea rows="4" class="form-control-warning" id="d_addBulkOptionsData.input" v-model="d_addBulkOptionsData.input"></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button block variant="primary" @click="f_prepareOptionsFromBulkText()">{{ $t('wdm16.13652') }}</b-button>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <b-card style="margin-bottom: 0px;" no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col cols="12" class="text-center">
                {{ $t('wdm16.13653') }}
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_addBulkOptionsData.output.length > 0">
            <b-row>
              <b-col cols="12">
                <table class="bulkOptionTableTable">
                  <tr class="bulkOptionTableTr">
                    <th class="bulkOptionTableTh">{{ $t('wdm16.3913') }}</th>
                    <th class="bulkOptionTableTh">{{ $t('wdm16.11292') }}</th>
                  </tr>
                  <template v-for="(new_option, new_option_ind) in d_addBulkOptionsData.output">
                    <tr class="bulkOptionTableTr" :style="f_calculateRowStyle(new_option_ind)">
                      <td class="bulkOptionTableTd">
                        <b-button v-if="!d_addBulkOptionsData.edit || (d_addBulkOptionsData.edit && d_addBulkOptionsData.edit_index === new_option_ind)" size="sm" variant="primary" @click="f_editBulkOption(new_option_ind)"><i class="fa fa-edit fa-1x"></i> {{ $t('wdm16.3897') }}</b-button>
                      </td>
                      <td class="bulkOptionTableTd">
                        <template v-if="(d_addBulkOptionsData.edit && d_addBulkOptionsData.edit_index === new_option_ind)">
                          <b-form-input type="text" class="form-control-warning" id="new_option" v-model="d_addBulkOptionsData.output[new_option_ind].label"></b-form-input>
                        </template>
                        <template v-else>
                          {{ new_option.label }}
                        </template>
                      </td>
                    </tr>
                  </template>
                </table>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="f_addBulkOptionsToThisParameter()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="danger" @click="d_showAddBulkOptionsModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsSearch } from '@/services/public/search';
import WdmService from "@/services/wdm";
import { WisdomRegex } from '@/services/public/functions';
import { ClsWdm } from '@/services/public/wdm';
import {
  default as WdmAndParameterStyle
} from "@/components/widgets/WdmAndParameterStyle";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
// import {default as router} from '@/router';
import {
  default as Modal
} from './Modal';
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import OptionService from '@/services/option';
import WebService from '@/services/webservice';
import UserService from '@/services/users';
import {
  default as Anatomy
} from './Anatomy';

export default {
  name: 'ParameterDesign',
  computed: {
    ...mapGetters({
      lang: 'lang',
      StoreModal: 'modal'
    })
  },
  props: {
    page_type: {
      type: String,
      required: false,
      default: 'main'
    },
    hospital_settings_data: {
      type: Object,
      required: false
    },
    p_wdmData: {
      type: Object,
      required: false
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  data () {
    return {
      d_optionImage: '',
      d_selectedImage: '',
      d_selectedAnatomySchemaList: [],
      d_showWdmStyleModal: false,
      d_showWdmModels: true,
      d_showHimsLabList: [],
      d_manualParameter: '',
      d_selectedMathOperator: { 'label': '+', 'value': 'plus', 'type': 'math_operator' },
      d_evalMathOpertors: [{
        'label': '+',
        'value': 'plus',
        'type': 'math_operator'
      }, {
        'label': '-',
        'value': 'minus',
        'type': 'math_operator'
      }, {
        'label': '*',
        'value': 'multiply',
        'type': 'math_operator'
      }, {
        'label': '/',
        'value': 'division',
        'type': 'math_operator'
      }, {
        'label': '(',
        'value': 'parenthesis_open',
        'type': 'math_operator'
      }, {
        'label': ')',
        'value': 'parenthesis_close',
        'type': 'math_operator'
      }],
      d_selectedPreparedParameter: { 'label': 'Gerçek Kilo (kg)', 'value': 'weight_kg', 'type': 'prepared_parameter' },
      d_preparedParameters: [{
        'label': 'Vücut Yüzey Alanı',
        'value': 'bsa',
        'type': 'prepared_parameter'
      }, {
        'label': 'Gerçek Kilo (kg)',
        'value': 'weight_kg',
        'type': 'prepared_parameter'
      }, {
        'label': 'Boy (cm)',
        'value': 'height_cm',
        'type': 'prepared_parameter'
      }, {
        'label': 'Yaş (yıl)',
        'value': 'age',
        'type': 'prepared_parameter'
      }, {
        'label': 'GFR (cockcroft gault) (ml/dk)',
        'value': 'gfr_cockcroft_gault',
        'type': 'prepared_parameter'
      }],
      selected_index_data: { 'index': '', 'index_list': [] },
      selected_index_data_2: { 'index': '', 'index_list': [] },
      d_showTestWdmModal: false,
      wdm_version_hospital: {},
      owner_type: '',
      wdm_version: '',
      d_himsParameterSearchText: '',
      all_users: [],
      tree_list: {
        'module': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'many']
        }
      },
      parameter_design_full_screen: false,
      filterTable: '',
      himsLaboratoryList: [],
      ShowhimsLaboratoryList: [],
      DontShowLaboratoryListColumns: [],
      himsLaboratoryListColumns: [{
        key: "wisdom_test",
        label: "Wisdom Test",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "id",
        label: "HBYS Test ID",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "mname",
        label: "Adı",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "test_code",
        label: "Kod",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "mgroup_name",
        label: "Grup",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' },
        grouping: true
      }, {
        key: "lower_bound",
        label: "Alt Sınır",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "upper_bound",
        label: "Üst Sınır",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "critical_lower_bound",
        label: "Kritik Alt Sınır",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "critical_upper_bound",
        label: "Kritik Üst Sınır",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "start_age",
        label: "Başlangıç Yaş",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "end_age",
        label: "Bitiş Yaş",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "information",
        label: "Bilgi",
        thStyle: { 'white-space': 'nowrap' }
      }],
      showHimsLaboratoryListColumns: [],
      hospital_data: {},
      selected_data_type: '',
      SelectedParameterGroup: '',
      ParameterGroupList: [
        { 'label': 'Anatomi', 'value': 'anatomy', 'translation': { 'tr': 'Anatomi', 'en': 'Anatomy' } },
        { 'label': 'Genel', 'value': 'general', 'translation': { 'tr': 'Genel', 'en': 'General' } }
      ],
      JsonOptions: { 'is_deleted': '0', 'create_new_value': '0', 'json_text': '' },
      ShowJsonOptions: false,
      ShowDefineOptionValue: false,
      ShowDefineParameterValue: false,
      document_type_list: ['jpeg', 'jpg', 'png', 'docx', 'doc', 'pdf'],
      parameter_option_method: 'manuel',
      new_param_label: '',
      show_edit_parameter_label: false,
      selected_first_param_option: '',
      d_selectedPatientSex: {},
      selected_anatomy_parent: { 'value': '' },
      selected_anatomy_region: { 'value': '' },
      MotherObjectListParam: {},
      show_parameter_features: '',
      upper_parameters: {},
      upper_param_order: [],
      selected_tab: 'param_details',
      is_eligible_for_new_parameter: false,
      SelectedParamData: { 'name': '' },
      SelectedParamData_second: { 'name': '' },
      selected_index: '',
      selected_index_second: '',
      show_parameter_general_options: false,
      filter_text: '',
      param_json_data: '',
      show_new_parameter_from_json: false,
      show_parameter_json: false,
      show_new_param_group: false,
      new_param_group: { label: '', value: '', translation: { tr: '', en: '' } },
      new_copy_parameter: {},
      param_type: ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'object_list', 'object_list_options', 'plain_text', 'list_parameter', 'image', 'pdf', 'html_editor'],
      select_types: ['select', 'object_list', 'checkbox', 'radiogroup'],
      new_param: { name: { label: '', translation: { tr: '', en: '' }, value: '' }, type: 'select', options: [] },
      show_new_parameter: false,
      param_data: {},
      new_param_data: {},
      show_copy_parameter: false,
      test_data: {},
      user: {},
      selected_group: '',
      selected_param: '',
      selected_sub_param: '',
      anatomy_if: {},
      ana_parent_if: {},
      friend_if: {},
      copy_option: {},
      carry_param: { group: '', param: '', sub_param: '', order: '' },
      show_carry_param: false,
      about_model: '',
      selected_option_list_second: [],
      EditingParameterOptionIndex: '',
      editing_parameter_option: '',
      new_parameter_option_label: '',
      new_parameter_option: { label: '', value: '', translation: { tr: '', en: '' } },
      selected_general_tab: 'wisdom_data_models',
      new_param_group_label: '',
      showEditParamGroup: false,
      wisdom_data_models: [],
      d_selectedModelIndex: '',
      d_selectedModelVersionIndex: '',
      d_selectedLocation: '',
      d_selectedModelHasDraft: false,
      d_userWdmPermissions: [],
      d_selectedModel: '',
      option_data: {},
      data_type: '',
      new_wisdom_data_model: {},
      show_new_wisdom_data_model: false,
      about_text: '',
      option_type_list: [],
      iWillDefineTheGroupValue: false,
      colList: {
        'models_general_col_left': 5,
        'models_general_col_right': 7
      },
      testIDListedAsOne: false,
      FieldGrouping: {},
      selectedHimsTest: {},
      selectedHimsTestIndex: '',
      search_in_first_parameter_list: '',
      users_for_permission: [],
      filterUsersTable: '',
      user_table_columns: [{
        key: "check",
        label: "seç",
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "username",
        label: "Kullanıcı Adı",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "hospitalCode",
        label: "Hastane Kodu",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "hospitalGroup",
        label: "Hastane Grubu",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }, {
        key: "wisdom_data_model",
        label: "Yetkiler",
        sortable: true,
        thStyle: { 'white-space': 'nowrap' }
      }],
      selected_wisdom_data_model_permissions: [],
      d_showHimsTestTable: true,
      d_isThisModelEditable: false,
      d_hospitalGroup: '',
      d_hospitalId: '',
      d_duplicatedValue: false,
      d_duplicatedGroupName: false,
      test_wdm_patient: { "version": 1, "color": 1, "personel_number": "11223344550", "sex": 1, "hospitals": { "MLPCARE": { "ULS": { "data": {}, "webservice": 0 } } }, "id": "example-id", "passport_number": "", "hospital_group": "MLPCARE", "last_lab": { "crea": "1" }, "created_by": "wisdom", "date_of_birth": "1986-01-01", "record_id": "123456", "color_label": "beyaz", "chemo_list": [{ "status": "active", "name": "Example Chemo", "created_at": "2019-09-29T01:39:29.382000", "created_by": "wisdom", "last_status": "no_operation_yet", "started_at": "2019-09-29", "type": "treatment", "id": "example-treatment-id", "treatment_diagnosis": { "translation": { "tr": "C50 - Meme malign neoplazmı" }, "id": "example-diagnosis-id", "value": "C50", "label": "C50 - Meme malign neoplazmı - 2017-02-01" } }], "type": "patient", "mobile_phone": "", "firstname": "Example", "lastname": "Patient", "phone": "", "citizen_type": "citizen", "last_bmi_data": { "weight": "75", "created_at": "2019-09-28T12:05:05.266000", "created_by": "wisdom", "id": "example-bmi-id", "length": "175", "date": "2019-09-28", "adjusted_weight": "73", "type": "patient_follow_physicalexam_bmi", "bodymassindex": "24.49", "ideal_weight": "71" }, "hospital_id": "ULS", "age": 75, "diagnosis": [{ "translation": { "tr": "C50 - Meme malign neoplazmı" }, "id": "example-diagnosis-id", "value": "C50", "label": "C50 - Meme malign neoplazmı - 2017-02-01" }], "last_laboratory": { "2": { "param_group": 3000, "name": { "translation:": { "en": "Crea", "tr": "Crea" }, "value": "Crea", "label": "Crea" }, "val": "0.67", "key": "laboratory_example-lab-id", "param_group_name": { "new_value": 3000, "translation": { "en": "Biochemistry", "tr": "Biyokimya" }, "value": "biochemistry", "label": "Biyokimya" }, "date": "2018-10-05T12:00:00.000000" } }, "created_at": "2018-10-22T15:21:23.183793", "selected": true },
      test_wdm_data: {},
      wisdomera_system_languages: [],
      param_change: { 'update': 0, 'new_parameter_added': 0 },
      d_parameterFunctionOptions: [],
      d_showAddBulkOptionsModal: false,
      d_addBulkOptionsData: { 'input': '', 'seperator': '', 'output': [], 'show_input': true, 'edit': false, 'edit_index': 0, 'delete_old_options': false, 'create_new_value': true }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let wisdomera_system = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (wisdomera_system) {
      this.wisdomera_system_languages = wisdomera_system['languages'];
      this.d_parameterFunctionOptions = JSON.parse(JSON.stringify(wisdomera_system['parameter_functions']));
    }
    if (this.page_type === 'hospital_settings') {
      if (this.hospital_settings_data.hospital_group && this.hospital_settings_data.hospital_id) {
        this.d_hospitalGroup = this.hospital_settings_data.hospital_group;
        this.d_hospitalId = this.hospital_settings_data.hospital_id;
        let query = '';
        query += 'hospital_group=' + this.hospital_settings_data.hospital_group;
        query += '&hospital_id=' + this.hospital_settings_data.hospital_id;
        OptionService.get_wdm_version_hospital(query)
          .then(resp => {
            if (resp.data.status === 'success') {
              // console.log(resp.data.result);
              this.wdm_version_hospital = resp.data.result;
            } else {
              alert('error:', resp.data.message);
            }
          });
      }
    } else {
      this.d_hospitalGroup = this.user.hospital_group;
      this.d_hospitalId = this.user.hospital_id;
    }
    this.wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
    if (this.p_wdmData !== undefined) {
      this.d_showWdmModels = false;
      let type_list = ['wisdom', 'hospital', 'user'];
      let det = false;
      for (let i in type_list) {
        if (this.wdm_version[type_list[i]]) {
          if (this.wdm_version[type_list[i]].all && this.wdm_version[type_list[i]].all[this.p_wdmData.wdm.value]) {
            for (let model_ind in this.wdm_version[type_list[i]].all[this.p_wdmData.wdm.value].list) {
              let model = this.wdm_version[type_list[i]].all[this.p_wdmData.wdm.value].list[model_ind];
              if (model.version === this.p_wdmData.wdm.version) {
                det = true;
                this.SelectModel(this.wdm_version[type_list[i]].all[this.p_wdmData.wdm.value], this.p_wdmData.wdm.value, model, model_ind, type_list[i])
                break;
              }
            }
          }
        }
        if (det) {
          break;
        }
      }
    }
    // this.get_option_type_list();
  },
  mounted: function () {
    // öncelik hastanenin olduğundan önce hastane sorgusu yapılıyor. Ardından wisdom ve user kontrolü
    if (this.page_type === 'hospital_settings') {
      this.owner_type = 'hospital';
    } else if (this.user.permissions_result.indexOf('wisdom') !== -1) {
      this.owner_type = 'wisdom';
    } else {
      this.owner_type = 'user';
    }
    let user_list = JSON.parse(localStorage.getItem('all_users'));
    for (let i in user_list) {
      this.all_users.push({ 'label': user_list[i].first_name + ' ' + user_list[i].last_name, 'value': user_list[i].username });
    }
    this.hospital_data = JSON.parse(localStorage.getItem('hospital_data'));
    if (this.page_type === 'hospital_settings') {
      this.show_parameter_features = 'match';
    }
  },
  methods: {
    f_findMinimumNumberAsStringValue: function (current_list) {
      let i = 1;
      for (i = 1; i < 100000; i++) {
        console.log(i);
        if (current_list.indexOf(i.toString()) === -1) {
          return i.toString();
        }
      }
    },
    f_addBulkOptionsToThisParameter: function () {
      try {
        let added_option_labels = [];
        let added_option_values = [];
        if (!this.SelectedParamData.options) {
          this.SelectedParamData.options = [];
        }
        for (let i in this.SelectedParamData.options) {
          added_option_labels.push(this.SelectedParamData.options[i].label);
          added_option_values.push(this.SelectedParamData.options[i].value);
        }
        if (this.d_addBulkOptionsData.delete_old_options) {
          this.SelectedParamData.options = [];
        }
        for (let i in this.d_addBulkOptionsData.output) {
          if (added_option_labels.indexOf(this.d_addBulkOptionsData.output[i].label) === -1) {
            added_option_labels.push(this.d_addBulkOptionsData.output[i].label);
            if (this.d_addBulkOptionsData.create_new_value) {
              console.log(this.d_addBulkOptionsData.output[i].value);
              this.d_addBulkOptionsData.output[i].value = this.f_findMinimumNumberAsStringValue(added_option_values);
              console.log(this.d_addBulkOptionsData.output[i].value);
            }
            if (added_option_values.indexOf(this.d_addBulkOptionsData.output[i].value) === -1) {
              added_option_values.push(this.d_addBulkOptionsData.output[i].value);
              this.SelectedParamData.options.push(this.d_addBulkOptionsData.output[i]);
            }
          }
        }
        this.$forceUpdate();
        this.d_showAddBulkOptionsModal = false;
      } catch (err) {
        alert(this.$t('wdm16.12676'));
      }
    },
    f_editBulkOption: function (new_option_ind) {
      if (!this.d_addBulkOptionsData.edit) {
        this.d_addBulkOptionsData.edit_index = new_option_ind;
        this.d_addBulkOptionsData.edit = true;
      } else {
        this.d_addBulkOptionsData.edit = false;
      }
    },
    f_calculateRowStyle: function (row_ind) {
      if (row_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    f_prepareOptionsFromBulkText: function () {
      this.d_addBulkOptionsData.output = [];
      let tmp_output = this.d_addBulkOptionsData.input.split(this.d_addBulkOptionsData.seperator);
      for (let i in tmp_output) {
        if (tmp_output[i] !== '') {
          this.d_addBulkOptionsData.output.push({ 'label': tmp_output[i], 'value': '' });
        }
      }
    },
    f_showAddBulkOptionsModal: function () {
      this.d_addBulkOptionsData = { 'input': '', 'seperator': '', 'output': [], 'show_input': true, 'edit': false, 'edit_index': 0, 'delete_old_options': false, 'create_new_value': true };
      this.d_showAddBulkOptionsModal = true;
    },
    f_getSelectedParamOptionLabel: function (pr, pr_ind, op_value) {
      let x = op_value;
      if (pr.parent_param === 'yes' && this.upper_parameters[pr_ind].options) {
        for (let i in this.upper_parameters[pr_ind].options) {
          if (this.upper_parameters[pr_ind].options[i].value === op_value) {
            x = this.upper_parameters[pr_ind].options[i].label;
            break;
          }
        }
      } else if (pr.index_list && pr.index_list.length > 0) {
        let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], pr.index_list);
        if (param_data.options) {
          for (let i in param_data.options) {
            if (param_data.options[i].value === op_value) {
              x = param_data.options[i].label;
              break;
            }
          }
        }
      }
      return x;
    },
    f_addImageToSelectedParameter: function () {
      if (this.d_selectedImage) {
        let file_name = this.d_selectedImage.name;
        let arr = this.d_selectedImage.name.split('.');
        let fileExtension = arr[arr.length - 1];
        // console.log(fileExtension);
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_selectedImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12634'));
          return;
        }
        if (this.d_selectedImage.size > 2097152) {
          this.d_selectedImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12635'));
          return;
        }

        var reader = new FileReader();
        var vm = this;
        let doc_data = {
          'user': this.user.username,
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name,
          'side': 'icon',
          'data': ''
        };
        this.SelectedParamData.image_info = doc_data;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.SelectedParamData.image_info.data = reader.result;
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_selectedImage);
        this.d_selectedImage = '';
      }
    },
    f_addImageToOption: function (op_ind) {
      if (this.d_optionImage) {
        let file_name = this.d_optionImage.name;
        let arr = this.d_optionImage.name.split('.');
        let fileExtension = arr[arr.length - 1];
        // console.log(fileExtension);
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_optionImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12634'));
          return;
        }
        if (this.d_optionImage.size > 2097152) {
          this.d_optionImage = '';
          this.$forceUpdate();
          alert(this.$t('wdm16.12635'));
          return;
        }

        var reader = new FileReader();
        var vm = this;
        let doc_data = {
          'user': this.user.username,
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name,
          'side': 'icon',
          'data': ''
        };
        this.SelectedParamData.options[op_ind].image_info = doc_data;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.SelectedParamData.options[op_ind].image_info.data = reader.result;
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_optionImage);
        this.d_optionImage = '';
      }
    },
    f_showWdmStyleModal: function () {
      if (!this.option_data[this.data_type].style) {
        this.option_data[this.data_type].style = {};
      }
      this.d_showWdmStyleModal = true;
    },
    f_saveWdmNewPhrases: function (type, wdm_key) {
      let bucket = this.d_selectedLocation === 'wisdom' ? 'option' : 'hospital';
      let data = {
        'wdm_new_phrases_type': type,
        'wdm_key': this.d_selectedModel.key,
        'bucket': bucket
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12638') } });
      OptionService.save_wdm_new_phrases(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === 'success') {
            this.option_data[this.data_type] = resp.data.result.wdm;
            if (type === 'all') {
              msg = this.$t('wdm16.12639');
            } else {
              msg = JSON.stringify(resp.data.result.new_phrases_list);
            }
          } else {
            msg = resp.data.message;
          }
          let modal_data = {
            'type': 'alert',
            'header_bg_variant': 'info',
            'header_text_variant': 'dark',
            'text': msg,
            'centered': true,
            'title': this.$t('wdm16.12640')
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_showTestWdmModal () {
      this.test_wdm_data['date'] = '';
      this.test_wdm_data['data'] = {};
      this.test_wdm_data['type'] = this.data_type;
      // this.test_wdm_data['reference_wdm'] = { 'version': reference_wdm.version, 'owner_type': reference_wdm.owner_type };
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        this.test_wdm_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.test_wdm_data.reference_wdm = {
        'data_type': this.data_type,
        'owner_type': this.owner_type,
        'key': 'wdm_' + this.owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version,
        'version': this.option_data[this.data_type].version
      };
      this.d_showTestWdmModal = true;
    },
    f_formatValueRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_checkValueRegex (value) {
      return WisdomRegex.regex1(value).status;
    },
    f_formatLabelRegex (value, event) {
      return WisdomRegex.regex2(value).value;
    },
    f_checkLabelRegex (value) {
      return WisdomRegex.regex2(value).status;
    },
    f_publishWdm: function () {
      if (confirm(this.$t('wdm16.12641'))) {
        let version_list = [];
        if (this.owner_type === 'hospital') {
          version_list = this.wdm_version_hospital.all[this.data_type]['list'];
        } else {
          version_list = this.wdm_version[this.d_selectedLocation].all[this.data_type]['list'];
        }
        let last_version = '';
        for (let i = version_list.length - 1; i > -1; i--) {
          if (version_list[i].version !== 'draft') {
            last_version = version_list[i].version;
            break;
          }
        }
        // console.log('last_version', last_version);
        let data = { 'last_version': last_version, 'key': this.d_selectedModel.key, 'data_type': this.data_type, 'location': this.d_selectedLocation, 'wdm': this.option_data[this.data_type] };
        OptionService.publish_wdm(data)
          .then(resp => {
            let msg = '';
            if (resp.data.status === 'success') {
              // console.log('publish result: ', resp.data.result);
              if (this.owner_type === 'hospital') {
                let list_ind = '';
                for (let i in this.wdm_version_hospital.all[this.data_type]['list']) {
                  if (this.d_selectedModel.key === this.wdm_version_hospital.all[this.data_type]['list'][i].key) {
                    list_ind = i;
                    this.wdm_version_hospital.all[this.data_type]['list'][i] = resp.data.result.version_data;
                    break;
                  }
                }
                this.wdm_version_hospital.last[this.data_type] = resp.data.result.version_data;
                this.wdm_version_hospital.last[this.data_type]['status'] = 'published';
                msg = this.$t('wdm16.12643');
                this.SelectModel(this.wdm_version_hospital.all[this.data_type], this.data_type, resp.data.result.version_data, list_ind, this.d_selectedLocation);
              } else {
                let list_ind = '';
                for (let i in this.wdm_version[this.d_selectedLocation].all[this.data_type]['list']) {
                  if (this.d_selectedModel.key === this.wdm_version[this.d_selectedLocation].all[this.data_type]['list'][i].key) {
                    list_ind = i;
                    this.wdm_version[this.d_selectedLocation].all[this.data_type]['list'][i] = resp.data.result.version_data;
                    break;
                  }
                }
                this.wdm_version[this.d_selectedLocation].last[this.data_type] = resp.data.result.version_data;
                this.wdm_version[this.d_selectedLocation].last[this.data_type]['status'] = 'published';
                localStorage.wdm_version = JSON.stringify(this.wdm_version);
                msg = this.$t('wdm16.12643');
                this.SelectModel(this.wdm_version[this.d_selectedLocation].all[this.data_type], this.data_type, resp.data.result.version_data, list_ind, this.d_selectedLocation);
              }
            } else {
              msg = resp.data.message;
            }
            let modal_data = {
              'type': 'alert',
              'header_bg_variant': 'info',
              'header_text_variant': 'dark',
              'text': msg,
              'centered': true,
              'title': this.$t('wdm16.12644')
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          });
      }
    },
    f_createDraftFromThisVersion: function (owner_type, data_type, model_ver) {
      let data = {
        'owner_type': owner_type,
        'data_type': data_type,
        'model_ver': model_ver.version,
        'model_key': model_ver.key,
        'hospital_group': this.d_hospitalGroup,
        'hospital_id': this.d_hospitalId
      };
      OptionService.create_new_wdm_version(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            msg = this.$t('wdm16.12661');
            if (owner_type === 'hospital') {
              this.wdm_version_hospital.all[data_type].list.push(resp.data.result.version_data)
            } else {
              this.wdm_version[owner_type].all[data_type].list.push(resp.data.result.version_data);
              localStorage.wdm_version = JSON.stringify(this.wdm_version);
              let last_index = this.wdm_version[owner_type].all[data_type].list.length - 1;
              this.SelectModel(this.wdm_version[owner_type].all[data_type], data_type, resp.data.result.version_data, last_index, owner_type);
            }
          } else {
            msg = resp.data.message;
          }
          let modal_data = {
            'type': 'alert',
            'header_bg_variant': 'info',
            'header_text_variant': 'dark',
            'text': msg,
            'centered': true,
            'title': this.$t('wdm16.12660')
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_showTrueFalse: function () {
      this.d_showHimsTestTable = false;
      setTimeout(function () {
        this.d_showHimsTestTable = true;
        this.$refs.hims_test_table.refresh();
      }.bind(this), 1000)
    },
    f_himsTestListStyle: function () {
      if (this.parameter_design_full_screen) {
        let height = window.innerHeight - 180;
        return 'overflow-y: auto; height: ' + height.toString() + 'px;';
      } else {
        if (this.page_type === 'hospital_settings') {
          return 'overflow-y: auto; height: 500px;';
        } else {
          let height = window.innerHeight - 250;
          return 'overflow-y: auto; height: ' + height.toString() + 'px;';
        }
      }
    },
    f_mainParameterSelectionStyle: function () {
      let x = 0;
      if (this.parameter_design_full_screen) {
        if (['wisdom_data_models', 'data_general'].indexOf(this.selected_general_tab) !== -1) {
          x = 90;
        } else { // parameter_list,
          x = 160;
        }
        let height = window.innerHeight - x;
        return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; padding: 3px;';
      } else {
        if (['wisdom_data_models', 'data_general'].indexOf(this.selected_general_tab) !== -1) {
          x = 190;
        } else { // parameter_list,
          x = 250;
        }
        if (this.page_type === 'hospital_settings') {
          return 'overflow-y: auto; height: 500px; overflow-x: hidden; padding: 3px;';
        } else {
          let height = window.innerHeight - x;
          return 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; padding: 3px;';
        }
      }
    },
    f_deleteThisModel: function () {
      if (confirm(this.$t('wdm16.12646'))) {
        OptionService.deleteThisWisdomDataModel('key=' + this.d_selectedModel.key + '&data_type=' + this.data_type + '&location=' + this.d_selectedLocation)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result === 'successfully_deleted') {
                alert(this.$t('wdm16.12647'));
                // Bir wdm modelinin ilgili lokasyonda () bir adet draftı olabilir.
                if (this.wdm_version[this.d_selectedLocation].all[this.data_type].list.length === 1) {
                  this.$delete(this.wdm_version[this.d_selectedLocation].all, this.data_type);
                } else {
                  let ind = '';
                  for (let i in this.wdm_version[this.d_selectedLocation].all[this.data_type].list) {
                    if (this.wdm_version[this.d_selectedLocation].all[this.data_type].list[i].key === this.d_selectedModel.key) {
                      ind = i;
                      break;
                    }
                  }
                  this.wdm_version[this.d_selectedLocation].all[this.data_type].list.splice(ind, 1);
                }
                this.selected_general_tab = 'wisdom_data_models';
                this.d_selectedLocation = '';
                this.d_selectedModelIndex = '';
                this.d_selectedModelVersionIndex = '';
                this.d_selectedModel = {};
                this.option_data = {};
                localStorage.wdm_version = JSON.stringify(this.wdm_version);
                this.data_type = '';
                this.$forceUpdate();
              }
            } else {
              alert('error: ' + resp.data.message);
            }
          });
      }
    },
    savePermissionsToSelectedUsers: function () {
      let data = { 'users': this.users_for_permission, 'data_type': this.data_type };
      UserService.saveAllUsersForPermission(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12648'));
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    addThisPermissionsToSelectedUsers: function () {
      for (let i in this.users_for_permission) {
        if (this.users_for_permission[i].check) {
          if (!this.users_for_permission[i].pdt.wisdom_data_model[this.data_type]) {
            this.users_for_permission[i].pdt.wisdom_data_model[this.data_type] = {
              'data_type': this.data_type,
              'name': this.option_data[this.data_type].name,
              'permission': []
            };
            this.users_for_permission[i].pdt.wisdom_data_model[this.data_type].permission = this.selected_wisdom_data_model_permissions;
          }
        }
      }
      this.$forceUpdate();
    },
    checkUsers: function () {
      let det = false;
      for (let i in this.users_for_permission) {
        if (this.users_for_permission[i].check) {
          det = true;
        }
      }
      if (!det) {
        for (let i in this.users_for_permission) {
          this.users_for_permission[i].check = true;
        }
      } else {
        for (let i in this.users_for_permission) {
          this.users_for_permission[i].check = false;
        }
      }
      this.$forceUpdate();
    },
    fSelectThisModule: function () {
      if (!this.option_data[this.data_type].module) {
        this.option_data[this.data_type].module = [];
      }
      let str = '';
      for (let m in this.tree_list.module.selected_items_data_list) {
        if (this.tree_list.module.selected_items_data_list[m].isTitle === 'yes') {
          str += '-' + this.tree_list.module.selected_items_data_list[m].label + '\n';
          continue;
        }
        let det = false;
        for (let i in this.option_data[this.data_type].module) {
          if (this.option_data[this.data_type].module[i].value === this.tree_list.module.selected_items_data_list[m].value) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.option_data[this.data_type].module.push(this.tree_list.module.selected_items_data_list[m]);
        }
      }
      if (str) {
        str += '\nBu gruplandırma başlığıdır. Lütfen uygun modül seçimini yapınız.';
        alert(str);
      }
      this.$forceUpdate();
    },
    matchWithHimsTestID: function () {
      if (!this.SelectedParamData['himsTestData']) {
        this.SelectedParamData['himsTestData'] = {};
      }
      this.SelectedParamData['himsTestData'][this.selectedHimsTest.id] = { 'id': this.selectedHimsTest.id, 'name': this.selectedHimsTest.mname };
      this.f_matchControlWithWisdomLabTests();
      this.$forceUpdate();
    },
    f_deleteHimsTest: function (hims_test_id) {
      this.$delete(this.SelectedParamData['himsTestData'], hims_test_id);
      this.f_matchControlWithWisdomLabTests();
      this.$forceUpdate();
    },
    selectTest: function (row) {
      this.selectedHimsTest = row[0];
    },
    f_selectTestFromTable: function (row, row_ind) {
      this.selectedHimsTest = row;
      this.selectedHimsTestIndex = row_ind;
    },
    SelectFieldGroupingChanged: function () {
      let list = JSON.parse(JSON.stringify(this.TestIDUniqFunc()));
      let del_list = [];
      for (let field_key in this.FieldGrouping) {
        if (this.FieldGrouping[field_key]) {
          for (let k in list) {
            if (list[k][field_key] !== this.FieldGrouping[field_key]) {
              del_list.push(k);
            }
          }
        }
      }
      del_list.sort((a, b) => b - a);
      for (let i in del_list) {
        list.splice(del_list[i], 1);
      }
      this.ShowhimsLaboratoryList = list;
      this.f_matchControlWithWisdomLabTests();
    },
    CalculateFieldValues: function (field_key, list) {
      let val_list = [];
      for (let i in list) {
        if (val_list.indexOf(list[i][field_key]) === -1) {
          val_list.push(list[i][field_key]);
        }
      }
      return val_list;
    },
    ShowThisField: function (field_ind, DontShowList) {
      DontShowList.splice(field_ind, 1);
      this.showHimsLaboratoryListColumns = JSON.parse(JSON.stringify(this.himsLaboratoryListColumns));
      for (let i in DontShowList) {
        for (let k in this.showHimsLaboratoryListColumns) {
          if (DontShowList[i].key === this.showHimsLaboratoryListColumns[k].key) {
            this.showHimsLaboratoryListColumns.splice(k, 1);
          }
        }
      }
    },
    DontShowThisField: function (field_ind, showingColumns) {
      this.DontShowLaboratoryListColumns.push(showingColumns[field_ind]);
      showingColumns.splice(field_ind, 1);
    },
    TestIDUniqFunc: function () {
      if (this.testIDListedAsOne) {
        this.ShowhimsLaboratoryList = JSON.parse(JSON.stringify(this.himsLaboratoryList));
        this.testIDListedAsOne = false;
      } else {
        this.testIDListedAsOne = true;
        this.ShowhimsLaboratoryList = [];
        let tempList = [];
        for (let i in this.himsLaboratoryList) {
          if (tempList.indexOf(this.himsLaboratoryList[i].id) === -1) {
            tempList.push(this.himsLaboratoryList[i].id);
            this.ShowhimsLaboratoryList.push(this.himsLaboratoryList[i]);
          }
        }
      }
      return this.ShowhimsLaboratoryList;
    },
    rowColReDesign: function (rightParam, leftParam, type) {
      if (type === 'makeLeftBigger') {
        if (this.colList[rightParam] !== 0) {
          this.colList[rightParam] -= 1;
          this.colList[leftParam] += 1;
        }
      } else if (type === 'makeRightBigger') {
        if (this.colList[leftParam] !== 0) {
          this.colList[leftParam] -= 1;
          this.colList[rightParam] += 1;
        }
      }
    },
    ChangeSizeByPercentWithID: function (type, domID, percent) {
      let elem = document.getElementById(domID);
      if (type === 'width') {
        elem.style.width = (elem.clientWidth * percent / 100).toString() + 'px';
      } else if (type === 'height') {
        elem.style.height = (elem.clientHeight * percent / 100).toString() + 'px';
      }
    },
    order_list: function (x) {
      let list = [];
      for (let i in this.SelectedParamData.options) {
        if (list.indexOf(this.SelectedParamData.options[i][x]) === -1) {
          list.push(this.SelectedParamData.options[i][x]);
        }
      }
      list.sort();
      let new_list = [];
      for (let i in list) {
        for (let op in this.SelectedParamData.options) {
          if (list[i] === this.SelectedParamData.options[op][x]) {
            new_list.push(this.SelectedParamData.options[op]);
          }
        }
      }
      this.SelectedParamData.options = new_list;
      this.$forceUpdate();
    },
    AddSelectedNewParamGroup: function () {
      for (let i in this.option_data[this.data_type].param_group.parameters) {
        if (this.option_data[this.data_type].param_group.parameters[i].value === this.SelectedParameterGroup.value) {
          /*
          :::::::::::::::modal_data variables::::::::::::::
          header_bg_variant: not required | default info
          header_text_variant: not required | default dark
          text: not required | if no text, modal will show free screen
          centered: not required | default top-center
          title: not required | default 'Uyarı'
          function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
          */
          let msg = this.$t('wdm16.12678');
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12679') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      this.option_data[this.data_type].param_group.param_order.push(this.SelectedParameterGroup.value);
      this.option_data[this.data_type].param_group.parameters[this.SelectedParameterGroup.value] = JSON.parse(JSON.stringify(this.SelectedParameterGroup));
      this.show_new_param_group = false;
      this.option_data[this.data_type][this.SelectedParameterGroup.value] = { parameters: {}, param_order: [] };
      this.f_motherChildren('update');
    },
    DocumentUploadFunc: function () {
      if (this.option_data[this.data_type].document_upload === 'yes') {
        this.option_data[this.data_type].document_type_list = [];
      } else {
        this.$delete(this.option_data[this.data_type], 'document_type_list');
      }
    },
    wisdom_operation: function () {},
    reset_changes: function () {
      let data = { option_list: [], 'page_type': this.page_type };
      data.option_list.push(this.data_type);
      OptionService.get_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data = resp.data.result;
            this.$forceUpdate();
            let msg = this.$t('wdm16.12652');
            let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12653') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            console.log('FUNC, reset_changes, ERROR::: ', resp.data.message);
          }
        });
      this.$forceUpdate();
    },
    f_saveWisdomDataModel: function () {
      let data = {
        'data': JSON.parse(JSON.stringify(this.option_data[this.data_type])),
        'page_type': this.page_type,
        'hospital_group': this.d_hospitalGroup,
        'hospital_id': this.d_hospitalId,
        'key': this.d_selectedModel.key,
        'location': this.d_selectedLocation
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12654') } });
      OptionService.saveWisdomDataModel(data)
        .then(resp => {
          let msg = '';
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.option_data = Object.assign({}, this.option_data, resp.data.result);
            msg = this.$t('wdm16.12661');
          } else {
            msg = 'Error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12656') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    get_option_type_list: function () {
      OptionService.type_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_type_list = resp.data.results;
            this.$forceUpdate();
          } else if (resp.data.status === 'error') {
            /*
            :::::::::::::::modal_data variables::::::::::::::
            header_bg_variant: not required | default info
            header_text_variant: not required | default dark
            text: not required | if no text, modal will show free screen
            centered: not required | default top-center
            title: not required | default 'Uyarı'
            function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
            */
            let msg = resp.data.message;
            let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12668') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    save_wisdom_data_model: function () {
      let msg = '';
      if (!this.new_wisdom_data_model.name.label) {
        msg += 'Lütfen model başlığını doldurunuz\n';
      }
      if (!this.new_wisdom_data_model.language) {
        msg += 'Lütfen model dilini doldurunuz\n';
      }
      if (msg) {
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12660') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        if (this.owner_type === 'hospital') {
          this.new_wisdom_data_model.hospital_id = this.d_hospitalId;
          this.new_wisdom_data_model.hospital_group = this.d_hospitalGroup;
        }
        OptionService.new_wisdom_model(this.new_wisdom_data_model)
          .then(resp => {
            let msg = '';
            if (resp.data.status === 'success') {
              // console.log(resp.data.result);
              this.show_new_wisdom_data_model = false;
              let parent = resp.data.result.wdm_data.parent;
              let name = resp.data.result.wdm_data.name;
              // yeni eklenen bir model draft olduğundan last içerisine koymuyoruz.
              if (this.owner_type === 'hospital') {
                if (!this.wdm_version_hospital['all']) {
                  this.wdm_version_hospital = { 'all': {}, 'last': {} };
                }
                this.wdm_version_hospital['all'][parent] = {
                  'list': [],
                  'name': name,
                  'permission': []
                };
                this.wdm_version_hospital.all[parent].list.push(resp.data.result.version_data);
              } else {
                this.wdm_version[this.new_wisdom_data_model.owner_type].all[parent] = {
                  'list': [],
                  'name': name,
                  'permission': [
                    'record_add_edit',
                    'record_view',
                    'edit_data_model',
                    'create_new_version',
                    'give_permission'
                  ]
                };
                this.wdm_version[this.new_wisdom_data_model.owner_type].all[parent].list.push(resp.data.result.version_data);
                localStorage.wdm_version = JSON.stringify(this.wdm_version);
                msg = this.$t('wdm16.12661');
                this.SelectModel(this.wdm_version[this.new_wisdom_data_model.owner_type].all[parent], parent, resp.data.result.version_data, 0, this.new_wisdom_data_model.owner_type);
              }
            } else {
              msg = resp.data.message;
            }
            let modal_data = {
              'type': 'alert',
              'header_bg_variant': 'info',
              'header_text_variant': 'dark',
              'text': msg,
              'centered': true,
              'title': this.$t('wdm16.12660')
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          });
      }
    },
    add_wisdom_data_about: function () {
      if (this.new_wisdom_data_model.about.indexOf(this.about_text) === -1) {
        this.new_wisdom_data_model.about.push(this.about_text);
        this.$forceUpdate();
      }
    },
    new_wisdom_model: function () {
      this.new_wisdom_data_model = {
        'language': { 'label': 'Türkçe', 'value': 'tr' },
        'show_last_recorded_value': 'yes',
        'record_count': 'many_times',
        'document_upload': 'no',
        'owner': '',
        'name': { 'label': '', 'value': '', 'translation': { 'tr': '', 'en': '' } },
        'value': '',
        'parent': '',
        'param_group': { 'param_order': [], 'parameters': {} },
        'rule_parameter': 'no',
        'version': 'draft',
        'about': [],
        'schema': 'yes',
        'owner_type': this.owner_type,
        'edit_in_hospital_settings': 'no',
        'favorite_show_mode': 'yes',
        'bmi_gfr': 'no',
        'calculate_eval': 'no',
        'checked_on_open': 'no',
        'date_ability': 'yes',
        'date_ability_user_selection': 'no',
        'date_ability_type': 'date',
        'date_ability_limit': 'none',
        'ending_date_ability': 'yes',
        'ending_date_ability_user_selection': 'no',
        'ending_date_ability_type': 'date',
        'ending_date_ability_limit': 'none',
        'edit_in_hospital_settings': 'no',
        'model': 'wisdom_data',
        'follow_up_location': [],
        'module': [],
        'permission_by_superuser': 'yes',
        'permissions_list_for_superuser': [],
        'department_filter_mode': 'filter_with_department', // all_departments, not_eligible_for_department_relation
        'send_to_hims_protocol': 'no',
        'style': {
          'caption': {
            'fontSize': 12,
            'color': 'black',
            'after': '',
            'backgroundColor': 'white',
            'border': {
              'type': 'solid',
              'size': 0,
              'color': 'black'
            },
            'borderRadius': 0
          },
          'value': {
            'fontSize': 10,
            'color': 'black',
            'after': '',
            'backgroundColor': 'white',
            'border': {
              'type': 'solid',
              'size': 0,
              'color': 'black'
            },
            'borderRadius': 0
          }
        }
      };
      this.new_wisdom_data_model.owner = { 'label': this.user.first_name + ' ' + this.user.last_name, 'value': this.user.username };
      this.show_new_wisdom_data_model = true;
    },
    ShowPartOfString: function (txt, count) {
      let new_txt = '';
      for (let i = 0; i < count; i++) {
        if (txt[i]) {
          new_txt += txt[i];
        }
      }
      if (txt.length > count) {
        new_txt += '...';
      }
      return new_txt.toLocaleUpperCase('tr');
    },
    SelectModel: function (model, model_ind, model_ver, model_ver_ind, location) {
      // console.log('model, model_ind, model_ver, model_ver_ind, location', model, model_ind, model_ver, model_ver_ind, location);
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': '"' + model.name.label + this.$t('wdm16.12663') } });
      this.selected_tab = 'param_details';
      this.SelectedParamData = {};
      let query = 'location=' + location + '&' + 'key=' + model_ver.key;
      // console.log('select model query: ', query);
      this.option_data = {};
      this.d_selectedModelHasDraft = false;
      let data = {
        'wdm_list': [{
          'value': model_ver.key,
          'bucket': location === 'wisdom' ? 'option' : 'hospital'
        }],
        'target_lang': ''
      };
      WdmService.get_wdm_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data[model_ind] = resp.data.result.wdm_list[0];
            this.d_selectedLocation = location;
            this.d_selectedModelIndex = model_ind;
            this.d_selectedModelVersionIndex = model_ver_ind;
            this.d_selectedModel = model;
            this.d_selectedModel.version = model_ver.version;
            this.d_selectedModel.key = model_ver.key;
            for (let i in model.list) {
              if (model.list[i].version === 'draft') {
                this.d_selectedModelHasDraft = true;
                break;
              }
            }
            this.d_userWdmPermissions = model.permission;
            this.data_type = model_ind;
            this.d_isThisModelEditable = (this.d_userWdmPermissions.indexOf('edit_data_model') !== -1 || this.page_type === 'hospital_settings') && this.d_selectedModel.version === 'draft' ? true : false;
            if (this.user.permissions_result && this.user.permissions_result.indexOf('wisdom') !== -1) {
              this.d_isThisModelEditable = true;
            }
            if (this.page_type === 'hospital_settings' && this.user.permissions_result && this.user.permissions_result.indexOf('hospital-settings_all-hospitals') !== -1) {
              this.d_isThisModelEditable = true;
            }
            if (this.p_wdmData !== undefined) {
              this.p_wdmData.wdm = this.option_data[model_ind];
              this.selected_general_tab = 'parameter_list';
            }
          } else {
            console.log('FUNC, SelectModel, ERROR::: ', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.$forceUpdate();
        });
    },
    CalculateSelectedModelClass: function (location, model_ind, model_ver_ind) {
      if (this.d_selectedModelIndex === model_ind && this.d_selectedModelVersionIndex === model_ver_ind && this.d_selectedLocation === location) {
        return 'model-selected';
      } else {
        return 'model-not-selected';
      }
    },
    get_model_wisdom_data: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12664') } });
      let query = this.page_type === 'hospital_settings' ? 'which=edit_in_hospital_settings' : 'which=all'
      OptionService.get_model_wisdom_data(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.wisdom_data_models = resp.data.result;
          } else if (resp.data.status === 'error') {
            alert(resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    SaveEditParamGroupLabel: function () {
      this.showEditParamGroup = false;
      this.option_data[this.data_type].param_group.parameters[this.selected_index_data.index_list[0]].label = this.new_param_group_label;
      this.option_data[this.data_type].param_group.parameters[this.selected_index_data.index_list[0]].translation.tr = this.new_param_group_label;
      this.option_data[this.data_type].param_group.parameters[this.selected_index_data.index_list[0]].translation.en = this.new_param_group_label;
      this.$forceUpdate();
    },
    AddNewParameterOption: function () {
      if (!this.SelectedParamData.options) {
        this.SelectedParamData.options = [];
      }
      for (let op_ind in this.SelectedParamData.options) {
        if (this.SelectedParamData.options[op_ind].label === this.new_parameter_option.label) {
          alert(this.$t('wdm16.12665'));
          return;
        }
      }
      if (this.ShowDefineOptionValue) {
        //
      } else {
        let option_value = '';
        for (let i = this.SelectedParamData.options.length; i < 10000; i++) {
          let det = false;
          for (let op_ind in this.SelectedParamData.options) {
            if (this.SelectedParamData.options[op_ind].value === i.toString()) {
              det = true;
              break;
            }
          }
          if (!det) {
            option_value = i.toString();
            break;
          }
        }
        this.new_parameter_option.value = option_value;
      }
      this.new_parameter_option.translation.tr = this.new_parameter_option.label;
      this.new_parameter_option.translation.en = this.new_parameter_option.label;
      if (!this.SelectedParamData.options) {
        this.SelectedParamData.options = [];
      }
      this.SelectedParamData.options.push(JSON.parse(JSON.stringify(this.new_parameter_option)));
      this.$forceUpdate();
    },
    CancelEditingParameterOptionLabel: function () {
      this.EditingParameterOptionIndex = '';
      this.$forceUpdate();
    },
    EditParameterOption: function (op_ind) {
      this.EditingParameterOptionIndex = op_ind;
      this.editing_parameter_option = this.SelectedParamData.options[op_ind].label;
      this.$forceUpdate();
    },
    SaveParameterOption: function () {
      this.SelectedParamData.options[this.EditingParameterOptionIndex].label = this.editing_parameter_option;
      this.EditingParameterOptionIndex = '';
      this.$forceUpdate();
    },
    DeleteParameterOption: function (op_ind) {
      this.SelectedParamData.options.splice(op_ind, 1);
      this.$forceUpdate();
    },
    f_deleteOptionImage: function (op_ind) {
      this.$delete(this.SelectedParamData.options[op_ind], 'image_info');
      this.$forceUpdate();
    },
    f_deleteParamImage: function () {
      this.$delete(this.SelectedParamData, 'image_info');
      this.$forceUpdate();
    },
    EditParameterLabel: function () {
      this.new_param_label = this.SelectedParamData.name.label;
      this.show_edit_parameter_label = true;
      this.$forceUpdate();
    },
    SaveParameterLabel: function () {
      this.SelectedParamData.name.label = this.new_param_label;
      this.new_param_label = '';
      this.show_edit_parameter_label = false;
      this.$forceUpdate();
    },
    add_patient: function () {
      let data = {};
      if (this.selected_tab === 'param_patient') {
        data = this.SelectedParamData;
      } else if (this.selected_tab === 'option_patient') {
        if (!this.SelectedParamData.op_if) {
          this.SelectedParamData.op_if = '1';
        }
        data = this.SelectedParamData.options[this.selected_first_param_option];
      }
      if (!data.if) { data.if = {}; }
      if (!data.if.patient) { data.if.patient = []; }
      data.if.patient.push(JSON.parse(JSON.stringify(this.d_selectedPatientSex)));
      this.$forceUpdate();
    },
    add_anatomy: function () {
      let data = {};
      if (this.selected_tab === 'param_anatomy') {
        data = this.SelectedParamData;
      } else if (this.selected_tab === 'option_anatomy') {
        if (!this.SelectedParamData.op_if) {
          this.SelectedParamData.op_if = '1';
        }
        data = this.SelectedParamData.options[this.selected_first_param_option];
      }
      if (!data.if) { data.if = {}; }
      if (!data.if.mother_anatomy) { data.if.mother_anatomy = []; }
      data.if.mother_anatomy.push(JSON.parse(JSON.stringify(this.selected_anatomy_region)));
      this.$forceUpdate();
    },
    add_parent_anatomy: function () {
      let data = {};
      if (this.selected_tab === 'param_anatomy') {
        data = this.SelectedParamData;
      } else if (this.selected_tab === 'option_anatomy') {
        if (!this.SelectedParamData.op_if) {
          this.SelectedParamData.op_if = '1';
        }
        data = this.SelectedParamData.options[this.selected_first_param_option];
      }
      if (!data.if) { data.if = {}; }
      if (!data.if.ana_parent) { data.if.ana_parent = []; }
      data.if.ana_parent.push(JSON.parse(JSON.stringify(this.selected_anatomy_parent)));
      this.$forceUpdate();
    },
    SelectSecondParamOption: function (option_value) {
      let data = {};
      if (this.selected_tab === 'param_friend') {
        data = this.SelectedParamData;
      } else if (this.selected_tab === 'option_friend') {
        data = this.SelectedParamData.options[this.selected_first_param_option];
      }
      if (data.if && data.if.friend && data.if.friend[this.SelectedParamData_second.name.value] && data.if.friend[this.SelectedParamData_second.name.value].operation && data.if.friend[this.SelectedParamData_second.name.value].operation['or_options']) {
        if (data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].indexOf(option_value) !== -1) {
          data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].splice(data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].indexOf(option_value), 1);
          if (data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].length === 0) {
            this.$delete(data.if.friend[this.SelectedParamData_second.name.value].operation, 'or_options');
          }
        } else {
          data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].push(option_value);
        }
      } else {
        if (!data.if || !data.if.friend || !data.if.friend[this.SelectedParamData_second.name.value]) {
          this.add_friend('or_options');
        }
        if (!data.if.friend[this.SelectedParamData_second.name.value].math_operator) {
          if (!data.if.friend[this.SelectedParamData_second.name.value].operation['or_options']) {
            data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'] = [];
          }
          data.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].push(option_value);
        }
      }
      this.$forceUpdate();
    },
    SelectMotherParamOption: function (option) {
      if (this.SelectedParamData.if) {
        if (!this.SelectedParamData.if.mother) {
          this.SelectedParamData.if.mother = {};
        }
        if (!this.SelectedParamData.if.mother[option.value]) {
          this.SelectedParamData.if.mother[option.value] = option;
        } else {
          this.$delete(this.SelectedParamData.if.mother, option.value);
        }
      } else {
        this.SelectedParamData.if = { 'mother': {} };
        this.SelectedParamData.if.mother[option.value] = option;
      }
      this.$forceUpdate();
    },
    CalculateMotherParamOptionStyle (option_value) {
      if (this.SelectedParamData.if && this.SelectedParamData.if.mother && this.SelectedParamData.if.mother[option_value]) {
        return 'second-param-option-selected';
      } else {
        return 'second-param-option-not-selected';
      }
    },
    CalculateSecondParamOptionStyle (option_value) {
      if (this.SelectedParamData.if && this.SelectedParamData.if.friend && this.SelectedParamData.if.friend[this.SelectedParamData_second.name.value] && this.SelectedParamData.if.friend[this.SelectedParamData_second.name.value].operation && this.SelectedParamData.if.friend[this.SelectedParamData_second.name.value].operation['or_options']) {
        if (this.SelectedParamData.if.friend[this.SelectedParamData_second.name.value].operation['or_options'].indexOf(option_value) !== -1) {
          return 'second-param-option-selected';
        } else {
          return 'second-param-option-not-selected';
        }
      } else {
        return 'second-param-option-not-selected';
      }
    },
    CalculateFirstParamOptionStyle (option_index) {
      if (this.selected_first_param_option === option_index) {
        return 'first-param-option-selected';
      } else {
        return 'first-param-option-not-selected';
      }
    },
    is_eligible_for_being_friend_compare_two_param: function () {
      if (this.selected_index_data.index_list.length > 0 && this.selected_index_data_2.index_list.length > 0) {
        // Both these index_list have parameters from group to deeper parameters ordered by deeply
        // algoritms:
        // 1 - different groups but level 1 parameter and type not in ['list_parameter', 'object_list', 'object_list_options', 'textarea']
        if (this.selected_index_data_2.index_list.length === 2 && ['list_parameter', 'object_list', 'object_list_options', 'textarea'].indexOf(this.SelectedParamData_second.type) === -1) {
          return true;
        }
        // 2 - They are in the same upper_parameters and type not in ['list_parameter', 'object_list', 'object_list_options', 'textarea']
        if (JSON.stringify(this.selected_index_data.index_list.slice(0, this.selected_index_data.index_list.length - 1)) === JSON.stringify(this.selected_index_data_2.index_list.slice(0, this.selected_index_data_2.index_list.length - 1)) && ['list_parameter', 'object_list', 'object_list_options', 'textarea'].indexOf(this.SelectedParamData_second.type) === -1) {
          return true;
        }
      }
      return false;
    },
    add_friend: function (state, math_operator = false) {
      let data = {};
      if (this.selected_tab === 'param_friend') {
        data = this.SelectedParamData;
      } else if (this.selected_tab === 'option_friend') {
        if (!this.SelectedParamData.op_if) {
          this.SelectedParamData.op_if = '1';
        }
        data = this.SelectedParamData.options[this.selected_first_param_option];
      }
      if (!data.if) {
        data.if = { friend: {} };
      }
      if (!data.if.friend) {
        data.if.friend = {};
      }
      data.if.friend[this.SelectedParamData_second.name.value] = {
        'operation': {},
        'index_list': this.selected_index_data_2.index_list,
        'name': this.SelectedParamData_second.name
      };
      if (math_operator) {
        data.if.friend[this.SelectedParamData_second.name.value]['math_operator'] = { 'status': 'yes', 'operator': '', 'number_1': '', 'number_2': '' };
      }
      if (JSON.stringify(this.selected_index_data.index_list.slice(0, this.selected_index_data.index_list.length - 1)) === JSON.stringify(this.selected_index_data_2.index_list.slice(0, this.selected_index_data_2.index_list.length - 1))) {
        data.if.friend[this.SelectedParamData_second.name.value]['parent_param'] = 'yes';
      }
      this.$forceUpdate();
    },
    f_cleanEvalFunction: function () {
      this.$delete(this.SelectedParamData, 'eval');
      this.$forceUpdate();
    },
    f_addForEvalFunction: function (type) {
      if (!this.SelectedParamData.eval) {
        this.SelectedParamData.eval = {
          'parameters': []
        };
      }
      if (type === 'wdm_param') {
        let x = {
          'type': 'wdm',
          'label': this.SelectedParamData_second.name.label,
          'value': this.SelectedParamData_second.name.value,
          'index_list': this.selected_index_data_2.index_list
        };
        let index_list_1 = JSON.parse(JSON.stringify(this.selected_index_data.index_list));
        let index_list_2 = JSON.parse(JSON.stringify(this.selected_index_data_2.index_list));
        if (JSON.stringify(index_list_1.slice(0, index_list_1.length - 1)) === JSON.stringify(index_list_2.slice(0, index_list_2.length - 1))) {
          x.mother_param = 'yes';
        }
        this.SelectedParamData.eval.parameters.push(x);
      } else if (type === 'math_operator') {
        if (this.d_selectedMathOperator) {
          this.SelectedParamData.eval.parameters.push(this.d_selectedMathOperator);
        }
      } else if (type === 'manuel_parameter') {
        if (this.d_manualParameter) {
          let x = {
            'label': this.d_manualParameter,
            'value': this.d_manualParameter,
            'type': 'manuel_parameter'
          };
          this.SelectedParamData.eval.parameters.push(x);
        }
      } else if (type === 'prepared_parameter') {
        if (this.d_selectedPreparedParameter) {
          this.SelectedParamData.eval.parameters.push(this.d_selectedPreparedParameter);
        }
      }
      this.$forceUpdate();
    },
    CalculateVariant: function (param_type) {
      if (param_type === 'select' || param_type === 'checkbox' || param_type === 'radiogroup') {
        return 'warning';
      } else if (param_type === 'textarea') {
        return 'secondary';
      } else if (param_type === 'integer') {
        return 'primary';
      } else if (param_type === 'object_list_options') {
        return 'success';
      } else if (param_type === 'object_list') {
        return 'info';
      } else if (param_type === 'date') {
        return 'dark';
      }
    },
    copy_groups_all_parameters (from_group, to_group) {
      if (confirm(this.$t('wdm16.12666'))) {
        for (let i in this.option_data[this.data_type][from_group].param_order) {
          let param = this.option_data[this.data_type][from_group].param_order[i];
          if (this.option_data[this.data_type][to_group].param_order.indexOf(param) === -1) {
            this.option_data[this.data_type][to_group].param_order.push(param);
          }
          this.option_data[this.data_type][to_group].parameters[param] = this.option_data[this.data_type][from_group].parameters[param];
        }
        this.$forceUpdate();
      }
    },
    delete_param_group: function () {
      if (this.option_data[this.data_type][this.selected_index_data.index_list[0]].param_order.length > 0) {
        /*
        :::::::::::::::modal_data variables::::::::::::::
        header_bg_variant: not required | default info
        header_text_variant: not required | default dark
        text: not required | if no text, modal will show free screen
        centered: not required | default top-center
        title: not required | default 'Uyarı'
        function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
        */
        let msg = this.$t('wdm16.12667');
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12668') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        this.option_data[this.data_type].param_group.param_order.splice(this.option_data[this.data_type].param_group.param_order.indexOf(this.selected_index_data.index_list[0]), 1);
        this.$delete(this.option_data[this.data_type].param_group.parameters, this.selected_index_data.index_list[0]);
        this.$delete(this.option_data[this.data_type], this.selected_index_data.index_list[0]);
        this.paramgroup = '';
        this.selected_tab = '';
        this.is_eligible_for_new_parameter = false;
        this.selected_index = '';
        this.$forceUpdate();
      }
    },
    analyze_key: function (type, text) {
      if (type === 'new_param_label') {
        if (text.indexOf('--') !== -1) {
          this.new_param.name.value = text.replace('--', '-');
          // this.$forceUpdate();
        }
      }
    },
    create_rule_data: function (param_data, data_type) {
      let rule_data = {
        'buck': 'wisdom',
        'type': '',
        'time_of_value': 'any_value',
        'data_type': 'old_recorded_data',
        'data_types': ['requesting_data', 'old_recorded_data'],
        'record_type': 'many',
        'operators': [],
        'val_list': [],
        'date': '1',
        'requesting_operation': 'wisdom_data_save',
        'significant': 'true',
        'op': '='
      };
      rule_data.type = this.option_data[this.data_type].name;
      if (['select', 'object_list'].indexOf(param_data.type) !== -1) {
        rule_data.operators = ['=', '<>'];
      } else if (['integer', 'float', 'date'].indexOf(param_data.type) !== -1) {
        rule_data.operators = ['=', '<>', '>', '<', '>=', '<='];
      }
      return rule_data;
    },
    param_name_func: function (param_data) {
      param_data.naming ? this.$delete(param_data, 'naming') : param_data.naming = '1';
      this.$forceUpdate();
    },
    param_dont_use_label_func: function (param_data) {
      param_data.dont_use_label ? this.$delete(param_data, 'dont_use_label') : param_data.dont_use_label = '1';
      this.$forceUpdate();
    },
    param_lab_unit_func: function (param_data) {
      param_data.lab_unit ? this.$delete(param_data, 'lab_unit') : param_data.lab_unit = '1';
      this.$forceUpdate();
    },
    param_show_size_units_func: function (param_data) {
      param_data.show_size_units ? this.$delete(param_data, 'show_size_units') : param_data.show_size_units = '1';
      this.$forceUpdate();
    },
    param_show_weight_units_func: function (param_data) {
      param_data.show_weight_units ? this.$delete(param_data, 'show_weight_units') : param_data.show_weight_units = '1';
      this.$forceUpdate();
    },
    param_show_dosage_units_func: function (param_data) {
      param_data.show_dosage_units ? this.$delete(param_data, 'show_dosage_units') : param_data.show_dosage_units = '1';
      this.$forceUpdate();
    },
    param_not_schema_func: function (param_data) {
      param_data.not_schema ? this.$delete(param_data, 'not_schema') : param_data.not_schema = '1';
      this.$forceUpdate();
    },
    param_anonym_func: function (param_data) {
      param_data.anonym ? this.$delete(param_data, 'anonym') : param_data.anonym = '1';
      this.$forceUpdate();
    },
    param_option_search_func: function (param_data) {
      param_data.option_search ? this.$delete(param_data, 'option_search') : param_data.option_search = '';
      this.$forceUpdate();
    },
    param_page_not_show_func: function (param_data) {
      param_data.page_not_show ? this.$delete(param_data, 'page_not_show') : param_data.page_not_show = '0';
      this.$forceUpdate();
    },
    param_hims_match_operations: function (param_data) {
      param_data.hims_match_operations ? this.$delete(param_data, 'hims_match_operations') : param_data.hims_match_operations = '0';
      this.$forceUpdate();
    },
    add_to_model_about: function () {
      if (this.about_model && this.option_data[this.data_type].about.indexOf(this.about_model) === -1) {
        this.option_data[this.data_type].about.push(this.about_model);
        /*
        :::::::::::::::modal_data variables::::::::::::::
        header_bg_variant: not required | default info
        header_text_variant: not required | default dark
        text: not required | if no text, modal will show free screen
        centered: not required | default top-center
        title: not required | default 'Uyarı'
        function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
        */
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': this.$t('wdm16.12670'), 'centered': true, 'title': this.$t('wdm16.12673') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.$forceUpdate();
      }
    },
    bring_caption_of_option: function (options, op) {
      for (let i in options) {
        if (options[i].value === op) {
          return options[i].label;
        }
      }
    },
    carry_param_func: function () {
      let order = parseInt(this.carry_param.order) - 1;
      let param_order_list = [];
      let pr = '';
      if (this.carry_param.sub_param) {
        param_order_list = this.option_data[this.data_type][this.carry_param.group].parameters[this.carry_param.param].param_order;
        pr = this.carry_param.sub_param;
      } else {
        param_order_list = this.option_data[this.data_type][this.carry_param.group].param_order;
        pr = this.carry_param.param;
      }
      if (order > param_order_list.length || order < 0) {
        /*
        :::::::::::::::modal_data variables::::::::::::::
        header_bg_variant: not required | default info
        header_text_variant: not required | default dark
        text: not required | if no text, modal will show free screen
        centered: not required | default top-center
        title: not required | default 'Uyarı'
        function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
        */
        let msg = this.$t('wdm16.12671') + param_order_list.length.toString() + this.$t('wdm16.12672');
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12673') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.$forceUpdate();
      } else {
        param_order_list.splice(param_order_list.indexOf(pr), 1);
        param_order_list.splice(order, 0, pr);
      }
      this.show_carry_param = false;
      this.$forceUpdate();
    },
    carry_param_to_an_order (group, param, sub_param) {
      this.carry_param.group = group;
      this.carry_param.param = param;
      this.carry_param.sub_param = sub_param;
      this.show_carry_param = true;
    },
    paste_param_option: function (param_data) {
      let det = false;
      for (let i in param_data.options) {
        if (param_data.options[i].value === this.copy_option.value) {
          det = true;
          break;
        }
      }
      if (!det) {
        param_data.options.push(JSON.parse(JSON.stringify(this.copy_option)));
      } else {
        /*
        :::::::::::::::modal_data variables::::::::::::::
        header_bg_variant: not required | default info
        header_text_variant: not required | default dark
        text: not required | if no text, modal will show free screen
        centered: not required | default top-center
        title: not required | default 'Uyarı'
        function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
        */
        let msg = this.$t('wdm16.12674');
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12675') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
      this.$forceUpdate();
    },
    copy_param_option: function (option) {
      this.copy_option = option;
      this.$forceUpdate();
    },
    add_if_data: function (group, param, sub_param = '', x, selected_op, type) {
      let data = {};
      let param_data = {};
      if (sub_param) {
        param_data = this.option_data[this.data_type][group].parameters[param].parameters[sub_param];
      } else {
        param_data = this.option_data[this.data_type][group].parameters[param];
      }
      if (x === 'pr') {
        data = param_data;
      } else if (x === 'op') {
        for (let i in param_data.options) {
          if (selected_op.value === param_data.options[i].value) {
            data = param_data.options[i];
            break;
          }
        }
        if (!param_data.op_if) {
          param_data.op_if = '1';
        }
      }
      if (!data.if) {
        data.if = {};
      }
      if (type === 'friend') {
        if (!data.if.friend) {
          data.if.friend = {};
        }
        for (let i in this.friend_if) {
          data.if.friend[i] = JSON.parse(JSON.stringify(this.friend_if[i]));
        }
      } else if (['mother_anatomy', 'ana_parent'].indexOf(type) !== -1) {
        if (!data.if[type]) {
          data.if[type] = [];
        }
        if (type === 'mother_anatomy') {
          data.if[type].push(this.anatomy_if);
        } else if (type === 'ana_parent') {
          data.if[type].push(this.ana_parent_if);
        }
      }
      this.$forceUpdate();
    },
    copy_if_data: function (data, type, pr = '', pr_ind = '') {
      if (type === 'mother_anatomy') {
        this.anatomy_if = data;
      } else if (type === 'ana_parent') {
        this.ana_parent_if = data;
      } else if (type === 'friend') {
        this.friend_if = {};
        this.friend_if[pr_ind] = pr;
      }
      this.$forceUpdate();
    },
    copy_parameter_json: function (param_group, param, sub_param = '') {
      this.param_json_data = '';
      if (sub_param) {
        this.param_json_data = this.option_data[this.data_type][param_group].parameters[param].parameters[sub_param];
      } else {
        this.param_json_data = this.option_data[this.data_type][param_group].parameters[param];
      }
      this.param_json_data = JSON.stringify(this.param_json_data);
      this.show_parameter_json = true;
    },
    show_add_new_param_from_json: function (group, param = '', subparam = '') {
      this.param_json_data = '';
      this.selected_group = group;
      this.selected_param = param;
      this.selected_sub_param = subparam;
      this.show_new_parameter_from_json = true;
      this.$forceUpdate();
    },
    ShowAddOptionsFromJsonText: function () {
      this.JsonOptions = { 'is_deleted': '0', 'create_new_value': '0', 'json_text': '' };
      this.ShowJsonOptions = true;
    },
    AddJsonOptionsToThisParameter: function () {
      let option_data = [];
      try {
        let added_option_labels = [];
        let added_option_values = [];
        if (!this.SelectedParamData.options) {
          this.SelectedParamData.options = [];
        }
        for (let i in this.SelectedParamData.options) {
          added_option_labels.push(this.SelectedParamData.options[i].label);
          added_option_values.push(this.SelectedParamData.options[i].value);
        }
        option_data = JSON.parse(this.JsonOptions.json_text);
        if (this.JsonOptions.is_deleted === '1') {
          this.SelectedParamData.options = [];
        }
        for (let i in option_data) {
          if (added_option_labels.indexOf(option_data[i].label) === -1) {
            added_option_labels.push(option_data[i].label);
            if (this.JsonOptions.create_new_value) {
              option_data[i].value = this.f_findMinimumNumberAsStringValue(added_option_values);
            }
            if (added_option_values.indexOf(option_data[i].value) === -1) {
              added_option_values.push(option_data[i].value);
              this.SelectedParamData.options.push(option_data[i]);
            }
          }
        }
        this.$forceUpdate();
      } catch (err) {
        alert(this.$t('wdm16.12676'));
      }
    },
    add_new_param_from_json_data: function () {
      let x = '';
      try {
        x = JSON.parse(this.param_json_data);
        if (x.rule) {
          x.rule.type = this.data_type;
        }
        if (x.if) {
          this.$delete(x, 'if');
        }
        let label = x.name.value;
        this.$delete(x, 'label');
        let data = {};
        if (this.selected_group && !this.selected_param && !this.selected_sub_param) {
          data = this.option_data[this.data_type][this.selected_group];
        } else if (this.selected_group && this.selected_param && !this.selected_sub_param) {
          data = this.option_data[this.data_type][this.selected_group].parameters[this.selected_param];
        } else if (this.selected_group && this.selected_param && this.selected_sub_param) {
          data = this.option_data[this.data_type][this.selected_group].parameters[this.selected_param].parameters[this.selected_sub_param];
        }
        if (data.param_order.indexOf(label) === -1) {
          data.param_order.push(label);
          data.parameters[label] = x;
        }
        this.show_new_parameter_from_json = false;
      } catch (err) {
        alert(this.$t('wdm16.12677') + err);
      }
    },
    param_require_func: function (param_data) {
      if (param_data.required === 'yes') {
        param_data.required = 'no';
      } else {
        param_data.required = 'yes';
      }
      this.$forceUpdate();
    },
    param_rule_func: function (param_data) {
      if (param_data.rule) {
        this.$delete(param_data, 'rule');
      } else {
        let rule_data = {
          'buck': 'wisdom',
          'type': '',
          'time_of_value': 'any_value',
          'data_type': 'old_recorded_data',
          'data_types': ['requesting_data', 'old_recorded_data'],
          'record_type': 'many',
          'operators': [],
          'val_list': [],
          'date': '1',
          'requesting_operation': 'wisdom_data_save',
          'significant': 'true',
          'op': '='
        };
        rule_data.type = this.option_data[this.data_type].name;
        if (['select', 'object_list'].indexOf(param_data.type) !== -1) {
          rule_data.operators = ['=', '<>'];
        } else if (['integer', 'float', 'date'].indexOf(param_data.type) !== -1) {
          rule_data.operators = ['=', '<>', '>', '<', '>=', '<='];
        }
        param_data.rule = rule_data;
      }
      this.$forceUpdate();
    },
    param_show_func: function (param_data) {
      if (param_data.show === 'yes') {
        param_data.show = 'no';
      } else {
        param_data.show = 'yes';
      }
      this.$forceUpdate();
    },
    param_ability: function (param_data) {
      if (param_data.disabled) {
        this.$delete(param_data, 'disabled');
      } else {
        param_data.disabled = 1;
      }
      this.$forceUpdate();
    },
    param_fav_show_func: function (param_data) {
      if (param_data.fav_show && param_data.fav_show === 'yes') {
        param_data.fav_show = 'no';
      } else {
        param_data.fav_show = 'yes';
      }
      this.$forceUpdate();
    },
    param_show_last_recorded_value: function (param_data) {
      if (param_data.show_last_recorded_value && param_data.show_last_recorded_value === 'yes') {
        this.$delete(param_data, 'last_show_count');
        param_data.show_last_recorded_value = 'no';
      } else {
        param_data.show_last_recorded_value = 'yes';
        param_data.last_show_count = 1;
      }
      this.$forceUpdate();
    },
    f_addNewParamGroup: function () {
      for (let i in this.option_data[this.data_type].param_group.parameters) {
        if (this.option_data[this.data_type].param_group.parameters[i].label === this.new_param_group.label) {
          /*
          :::::::::::::::modal_data variables::::::::::::::
          header_bg_variant: not required | default info
          header_text_variant: not required | default dark
          text: not required | if no text, modal will show free screen
          centered: not required | default top-center
          title: not required | default 'Uyarı'
          function_name: not required | to use add a watch parameter "StoreModal.show" and "if (!StoreModal.show)" and control function_name, StoreModal.data.confirm is true. Dont forget to get --StoreModal: 'modal'-- in mapgetters
          */
          let msg = this.$t('wdm16.12678');
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12679') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      let group_value = '';
      if (!this.iWillDefineTheGroupValue) {
        for (let i = 1000; i < 1000000; i += 1000) {
          // console.log(i);
          if (!this.option_data[this.data_type].param_group.parameters[i.toString()]) {
            group_value = i.toString();
            break;
          }
        }
      } else {
        group_value = this.new_param_group.value;
      }
      this.new_param_group.value = group_value;
      this.new_param_group.translation.tr = this.new_param_group.label;
      this.new_param_group.translation.en = this.new_param_group.label;
      this.option_data[this.data_type].param_group.param_order.push(group_value);
      this.option_data[this.data_type].param_group.parameters[group_value] = JSON.parse(JSON.stringify(this.new_param_group));
      this.show_new_param_group = false;
      this.option_data[this.data_type][group_value] = { 'parameters': {}, 'param_order': [] };
      this.f_motherChildren('update');
    },
    list_param_order: function (list, index, direction) {
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    carry_parameter: function (param, param_group_1, param_group_2) {
      let data_from = this.option_data[this.data_type][param_group_1];
      let data_to = this.option_data[this.data_type][param_group_2];
      if (data_to.param_order.indexOf(param) === -1) {
        data_to.param_order.push(param);
        data_to.parameters[param] = JSON.parse(JSON.stringify(data_from.parameters[param]));
        data_from.param_order.splice(data_from.param_order.indexOf(param), 1);
        this.$delete(this.data_from.parameters, param);
      } else {
        alert(this.$t('wdm16.12681'));
      }
      this.$forceUpdate();
    },
    f_addNewParameter () {
      let new_param_value = '';
      let data = {};
      if (this.selected_index_data.index_list.length === 1) {
        data = this.option_data[this.data_type][this.selected_index_data.index_list[0]];
      } else {
        data = this.SelectedParamData;
      }
      if (this.ShowDefineParameterValue) {
        new_param_value = this.new_param.name.value;
      } else {
        let selected_option_data = this.option_data[this.d_selectedModel.name.value];
        for (let i = data.param_order.length; i < 10000; i++) {
          let det_val = { 'x': false };
          for (let k in selected_option_data.param_group.param_order) {
            if (!det_val.x) {
              let group_name = selected_option_data.param_group.param_order[k];
              this.f_controlVariableIsContaining(selected_option_data[group_name].parameters, i.toString(), det_val);
            }
          }
          if (det_val.x) {
            continue;
          } else {
            new_param_value = i.toString();
            break;
          }
        }
        this.new_param.name.value = new_param_value;
      }
      this.new_param.name.translation.tr = this.new_param.name.label;
      this.new_param.name.translation.en = this.new_param.name.label;
      if (this.select_types.indexOf(this.new_param.type) === -1) {
        this.$delete(this.new_param, 'options');
      }
      if (this.new_param.type !== 'object_list_options' && this.new_param.mother_param) {
        this.$delete(this.new_param, 'mother_param');
      }
      if (['object_list_options', 'list_parameter'].indexOf(this.new_param.type) !== -1) {
        this.new_param.param_order = [];
        this.new_param.parameters = {};
        this.new_param.object_type = 'object';
      } else {
        this.$delete(this.new_param, 'param_order');
        this.$delete(this.new_param, 'parameters');
      }
      data.param_order.push(new_param_value);
      data.parameters[new_param_value] = JSON.parse(JSON.stringify(this.new_param));
      data.parameters[new_param_value].show = 'yes';
      data.parameters[new_param_value].rule = this.create_rule_data(this.new_param);
      this.new_param.name.value = '';
      this.f_motherChildren('new_parameter_added');
    },
    delete_parameter: function () {
      let prm = this.SelectedParamData.name.value;
      let msg = '';
      let object_list_param = '';
      if (this.SelectedParamData.type === 'object_list') {
        if (this.SelectedParamData.object_param && this.SelectedParamData.object_param.value && this.upper_parameters[this.SelectedParamData.object_param.value]) {
          msg += this.upper_parameters[this.SelectedParamData.object_param.value].name.label +
            ' parametresinin varlığı şu an silmekte olduğunuz parametreye bağlıdır. Bu parametreyi silmeniz halinde bağlı olan parametre de silinecektir. \n';
        }
      }
      if (this.SelectedParamData.type === 'object_list_options') {
        for (let i in this.upper_parameters) {
          if (this.upper_parameters[i].object_param && this.upper_parameters[i].object_param === this.SelectedParamData.name.value) {
            object_list_param = i;
            msg += this.upper_parameters[i].name.label +
              ' parametresinin varlığı şu an silmekte olduğunuz parametreye bağlıdır. Bu parametreyi silmeniz halinde bağlı olan parametre de silinecektir. \n';
            break;
          }
        }
      }
      let turning_data = { 'msg': '' };
      for (let pg_index in this.option_data[this.data_type].param_group.param_order) {
        let pg_value = this.option_data[this.data_type].param_group.param_order[pg_index];
        this.f_lookForFriendStatus(this.option_data[this.data_type][pg_value], prm, turning_data);
      }
      msg += turning_data.msg;
      //
      msg += this.$t('wdm16.12683');
      if (confirm(msg)) {
        for (let pg_index in this.option_data[this.data_type].param_group.param_order) {
          let pg_value = this.option_data[this.data_type].param_group.param_order[pg_index];
          this.f_deleteParameterFromFriends(this.option_data[this.data_type][pg_value], prm);
        }
        this.upper_param_order.splice(this.upper_param_order.indexOf(prm), 1);
        this.$delete(this.upper_parameters, prm);
        if (this.SelectedParamData.type === 'object_list' && this.SelectedParamData.object_param && this.SelectedParamData.object_param.value) {
          this.upper_param_order.splice(this.upper_param_order.indexOf(this.SelectedParamData.object_param.value), 1);
          this.$delete(this.upper_parameters, this.SelectedParamData.object_param.value);
        }
        if (this.SelectedParamData.type === 'object_list_options' && object_list_param) {
          this.upper_param_order.splice(this.upper_param_order.indexOf(object_list_param), 1);
          this.$delete(this.upper_parameters, object_list_param);
        }
        this.selected_index = '';
        this.f_motherChildren('update');
        this.$forceUpdate();
      }
    },
    f_motherChildren: function (op) {
      if (this.param_change[op] === 0) {
        this.param_change[op] = 1;
      } else {
        this.param_change[op] = 0;
      }
    },
    f_deleteParameterFromFriends: function (param_list, lookfor_param) {
      // param_list contains {'param_order': [], 'parameters': {}}
      // lookfor_param = 'selected_diagnosis'
      for (let pr_index in param_list['param_order']) {
        let param_value = param_list['param_order'][pr_index];
        let param_data = param_list.parameters[param_value];
        if (param_data.if && param_data.if.friend && param_data.if.friend[lookfor_param]) {
          this.$delete(param_data.if.friend, lookfor_param);
        }
        if (param_list.parameters[param_value].param_order && param_list.parameters[param_value].param_order.length > 0) {
          this.f_lookForFriendStatus(param_list.parameters[param_value], lookfor_param);
        }
      }
    },
    f_lookForFriendStatus: function (param_list, lookfor_param, turning_data) {
      // param_list contains {'param_order': [], 'parameters': {}}
      // lookfor_param = 'selected_diagnosis'
      // turning_data = {'msg': ''}
      for (let pr_index in param_list['param_order']) {
        let param_value = param_list['param_order'][pr_index];
        let param_data = param_list.parameters[param_value];
        if (param_data.if && param_data.if.friend && param_data.if.friend[lookfor_param]) {
          turning_data.msg += param_data.name.label + ' parametresinde durum arkadaşı olarak kullanılmıştır. \n';
        }
        if (param_list.parameters[param_value].param_order && param_list.parameters[param_value].param_order.length > 0) {
          this.f_lookForFriendStatus(param_list.parameters[param_value], lookfor_param, turning_data);
        }
      }
    },
    reset_changes: function () {
      let data = { option_list: [], 'page_type': this.page_type };
      data.option_list.push(this.data_type);
      OptionService.get_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data = resp.data.result;
            alert(this.$t('wdm16.12652'));
          } else {
            console.log('FUNC, SelectModel, ERROR::: ', resp.data.message);
          }
          this.$forceUpdate();
        });
    },
    copy_parameter: function (param_group, param, sub_param = '') {
      this.new_param_data = { param_group: param_group, param: param, sub_param: sub_param, new_label: '' };
      if (this.new_param_data.sub_param) {
        this.new_copy_parameter = JSON.parse(JSON.stringify(this.option_data[this.data_type][this.new_param_data.param_group].parameters[this.new_param_data.param].parameters[this.new_param_data.sub_param]));
      } else {
        this.new_copy_parameter = JSON.parse(JSON.stringify(this.option_data[this.data_type][this.new_param_data.param_group].parameters[this.new_param_data.param]));
      }
      this.show_copy_parameter = true;
    },
    add_new_copy_parameter: function () {
      let data_parameters = {};
      if (this.new_param_data.sub_param) {
        data_parameters = this.option_data[this.data_type][this.new_param_data.param_group].parameters[this.new_param_data.param];
      } else {
        data_parameters = this.option_data[this.data_type][this.new_param_data.param_group];
      }
      if (data_parameters.parameters[this.new_param_data.new_label] === undefined) {
        data_parameters.parameters[this.new_param_data.new_label] = this.new_copy_parameter;
        data_parameters.param_order.push(this.new_param_data.new_label);
        this.show_copy_parameter = false;
        alert(this.$t('wdm16.12686'));
      } else {
        alert(this.$t('wdm16.12687'));
      }
    },
    f_matchControlWithWisdomLabTests: function () {
      for (let i in this.ShowhimsLaboratoryList) {
        let test_id = this.ShowhimsLaboratoryList[i].id.toString();
        let det = false;
        for (let pg in this.option_data.laboratory.param_group.param_order) {
          let pg_value = this.option_data.laboratory.param_group.param_order[pg];
          for (let pr in this.option_data.laboratory[pg_value].param_order) {
            let pr_value = this.option_data.laboratory[pg_value].param_order[pr];
            let paramData = this.option_data.laboratory[pg_value].parameters[pr_value];
            if (paramData.himsTestData && paramData.himsTestData[test_id]) {
              this.ShowhimsLaboratoryList[i].wisdom_test = paramData.name.label;
              det = true;
            }
            if (det) {
              break;
            }
          }
          if (det) {
            break;
          }
        }
        if (!det) {
          this.$delete(this.ShowhimsLaboratoryList[i], 'wisdom_test');
        }
      }
      this.f_searchTextHimsLabParam();
      // this.f_showTrueFalse();
    },
    f_controlVariableIsContaining: function (listData, val, det_val) {
      // sonsuz döngü ile çalışıyor
      for (let i in listData) {
        if (!det_val.x) {
          if (listData[i].name && listData[i].name.value === val) {
            det_val.x = true;
          }
          if (!det_val.x && listData[i].parameters) {
            // console.log('4245::::::::::::::::::::::::::::::');
            this.f_controlVariableIsContaining(listData[i].parameters, val, det_val);
          }
        }
      }
    },
    f_turnInfinityInParameters: function (param_list, search_list, turning_data, param_list_type) {
      // param_list contains {'param_order': [], 'parameters': {}}
      // search_list = ['general', 'selected_diagnosis']
      // turning_data = {'completed': false, 'param_value_list': ['general', 'selected_diagnosis']}
      if (!turning_data.completed) {
        for (let pr_index in param_list['param_order']) {
          let param_value = param_list['param_order'][pr_index];
          if (parseInt(pr_index) === 0) {
            turning_data.param_value_list.push(param_value);
          } else {
            turning_data.param_value_list[turning_data.param_value_list.length - 1] = param_value;
          }
          if (turning_data.param_value_list.join() === search_list.join()) {
            if (param_list_type === 1) {
              this.SelectedParamData = param_list.parameters[param_value];
              turning_data.completed = true;
              this.upper_parameters = param_list.parameters;
              this.upper_param_order = param_list.param_order;
              this.MotherObjectListParam = {};
              if (this.SelectedParamData.type === 'object_list_options') {
                if (this.SelectedParamData.mother_param && this.upper_parameters[this.SelectedParamData.mother_param]) {
                  this.MotherObjectListParam = this.upper_parameters[this.SelectedParamData.mother_param];
                }
              }
              if (this.SelectedParamData.option_search) {
                this.parameter_option_method = 'option_search';
              } else {
                this.parameter_option_method = 'manuel';
              }
            } else if (param_list_type === 2) {
              this.SelectedParamData_second = param_list.parameters[param_value];
              turning_data.completed = true;
            }
          }
          if (param_list.parameters[param_value].param_order && param_list.parameters[param_value].param_order.length > 0 && !turning_data.completed) {
            this.f_turnInfinityInParameters(param_list.parameters[param_value], search_list, turning_data, param_list_type);
            turning_data.param_value_list.splice(turning_data.param_value_list.length - 1, 1);
          }
        }
      }
    },
    f_searchTextHimsLabParam: function () {
      if (this.d_himsParameterSearchText === '') {
        this.d_showHimsLabList = JSON.parse(JSON.stringify(this.ShowhimsLaboratoryList));
      } else {
        this.d_showHimsLabList = ClsSearch.list(this.d_himsParameterSearchText, this.ShowhimsLaboratoryList, this.d_showHimsLabList, Object.keys(this.ShowhimsLaboratoryList[0]));
      }
    }
  },
  components: {
    Anatomy,
    Modal,
    vSelect,
    WdmAndParameterStyle,
    TreeList: () =>
      import ('@/components/widgets/TreeList'),
    WisdomDataModal: () =>
      import ('@/components/widgets/WisdomDataModal'),
    WdmParameters
  },
  watch: {
    'd_himsParameterSearchText': function () {
      this.f_searchTextHimsLabParam();
    },
    'selected_index_data.index_list': function () {
      this.selected_index = this.selected_index_data.index_list.join('--');
      this.selected_first_param_option = '';
      this.SelectedParamData = {};
      // First we are controlling the index list. If it's length is equal to 1, it means we are on a paramgroup.
      if (this.selected_index_data.index_list.length === 1) {
        let pg_value = this.selected_index_data.index_list[this.selected_index_data.index_list.length - 1];
        this.SelectedParamData = this.option_data[this.data_type][pg_value];
      } else if (this.selected_index_data.index_list.length > 1) {
        for (let pg_index in this.option_data[this.data_type].param_group.param_order) {
          let pg_value = this.option_data[this.data_type].param_group.param_order[pg_index];
          let turning_data = { 'param_value_list': [pg_value], 'completed': false };
          this.f_turnInfinityInParameters(this.option_data[this.data_type][pg_value], this.selected_index_data.index_list, turning_data, 1);
          if (turning_data.completed) {
            break;
          }
        }
      }
      this.$forceUpdate();
    },
    'selected_index_data_2.index_list': function () {
      this.selected_index_second = this.selected_index_data_2.index_list.join('--');
      this.SelectedParamData_second = {};
      // First we are controlling the index list. If it's length is equal to 1, it means we are on a paramgroup.
      if (this.selected_index_data_2.index_list.length === 1) {
        let pg_value = this.selected_index_data_2.index_list[this.selected_index_data_2.index_list.length - 1];
        this.SelectedParamData_second = this.option_data[this.data_type][pg_value];
      } else if (this.selected_index_data_2.index_list.length > 1) {
        for (let pg_index in this.option_data[this.data_type].param_group.param_order) {
          let pg_value = this.option_data[this.data_type].param_group.param_order[pg_index];
          let turning_data = { 'param_value_list': [pg_value], 'completed': false };
          this.f_turnInfinityInParameters(this.option_data[this.data_type][pg_value], this.selected_index_data_2.index_list, turning_data, 2);
          if (turning_data.completed) {
            break;
          }
        }
      }
      this.$forceUpdate();
    },
    'new_param.name.value': function () {
      if (this.new_param && this.new_param.name && this.new_param.name.value) {
        this.d_duplicatedValue = false;
        let det_val = { 'x': false };
        let selected_option_data = this.option_data[this.d_selectedModel.name.value];
        for (let i in selected_option_data.param_group.param_order) {
          // console.log('4265,i',i);
          if (!det_val.x) {
            let group_name = selected_option_data.param_group.param_order[i];
            // console.log('4268,group_name', group_name);
            this.f_controlVariableIsContaining(selected_option_data[group_name].parameters, this.new_param.name.value, det_val);
          }
        }
        if (det_val.x) {
          this.d_duplicatedValue = true;
        }
      } else {
        this.d_duplicatedValue = false;
      }
    },
    'new_param_group.value': function () {
      if (this.new_param_group && this.new_param_group.label && this.new_param_group.value) {
        this.d_duplicatedGroupName = false;
        let selected_option_data = this.option_data[this.d_selectedModel.name.value];
        // console.log(selected_option_data.param_group.parameters);
        if (selected_option_data.param_group.parameters[this.new_param_group.value]) {
          this.d_duplicatedGroupName = true;
        }
      } else {
        this.d_duplicatedGroupName = false;
      }
    },
    'new_wisdom_data_model.permission_by_superuser': function () {
      if (this.new_wisdom_data_model.permission_by_superuser === 'no') {
        this.new_wisdom_data_model.permissions_list_for_superuser = [];
      }
    },
    'parameter_design_full_screen': function () {
      if (this.parameter_design_full_screen) {

      }
    },
    'search_in_first_parameter_list': function () {},
    'parameter_option_method': function () {
      if (this.parameter_option_method === 'manuel') {
        this.$delete(this.SelectedParamData, 'option_search');
        this.$forceUpdate();
      }
    },
    'selected_general_tab': function () {
      if (this.selected_general_tab !== 'parameter_list') {
        this.selected_tab = '';
        this.selected_index = '';
        this.SelectedParamData = { 'name': '' };
        this.SelectedParamData_second = { 'name': '' };
        this.paramgroup = '';
        this.paramgroup_second = '';
        this.$forceUpdate();
      }
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'SaveParameterLabel') {
          if (this.StoreModal.data.confirm) {
            this.SelectedParamData.name.label = this.new_param_label;
            this.new_param_label = '';
            this.show_edit_parameter_label = false;
            this.$forceUpdate();
          } else {
            this.new_param_label = '';
            this.show_edit_parameter_label = false;
            this.$forceUpdate();
          }
        }
      }
    },
    'selected_tab': function () {
      this.selected_anatomy_parent = { 'value': '' };
      this.selected_anatomy_region = { 'value': '' };
      if (this.selected_tab === 'match_parameter_from_webservice' && this.himsLaboratoryList.length === 0) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane laboratuar listesi yükleniyor.' } });
        let query = 'hospitalGroup=' + this.option_data[this.data_type].hospital_group + '&' + 'hospitalCode=' + this.option_data[this.data_type].hospital_id;
        WebService.getHospitalLaboratoryTestListByHospicalCode(query)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.himsLaboratoryList = resp.data.result;
              // this.ShowhimsLaboratoryList = resp.data.result;
              this.showHimsLaboratoryListColumns = JSON.parse(JSON.stringify(this.himsLaboratoryListColumns));
              this.testIDListedAsOne = true;
              this.ShowhimsLaboratoryList = [];
              let tempList = [];
              for (let i in this.himsLaboratoryList) {
                if (tempList.indexOf(this.himsLaboratoryList[i].id) === -1) {
                  tempList.push(this.himsLaboratoryList[i].id);
                  this.ShowhimsLaboratoryList.push(this.himsLaboratoryList[i]);
                }
              }
              this.f_matchControlWithWisdomLabTests();
              this.d_showHimsLabList = JSON.parse(JSON.stringify(this.ShowhimsLaboratoryList));
            } else {
              this.himsLaboratoryList = [];
              this.ShowhimsLaboratoryList = [];
              alert(resp.data.WebServiceMessage);
            }
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          });
      }
    },
    'selected_anatomy_parent.value': function () {
      this.$forceUpdate();
    },
    'selected_anatomy_region.value': function () {
      this.$forceUpdate();
    },
    'selected_general_tab': function () {
      if (this.selected_general_tab === 'give_permission' && this.users_for_permission.length === 0) {
        let query = 'hospitalGroup=' + this.user.hospital_group +
          '&hospitalCode=' + this.user.hospital_id;
        UserService.getUsersWisdomDataModelPermission(query)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.users_for_permission = [];
              for (let i in resp.data.result) {
                if (resp.data.result[i].username !== this.user.username) {
                  this.users_for_permission.push(resp.data.result[i]);
                }
              }
            } else {
              alert('error', resp.data.message);
            }
          });
      }
    }
  }
};

</script>

<style type="text/css">
.second-param-option-selected {
  color: green;
  margin: 0px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px black;
}

.model-selected {
  background-color: #8ee08e;
  margin: 0px;
  cursor: pointer;
  border-radius: 3px;
}

.model-not-selected {
  margin: 0px;
  cursor: pointer;
}

.second-param-option-not-selected {
  margin: 0px;
  cursor: pointer;
}

.first-param-option-selected {
  color: green;
  margin: 0px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px black;
}

.first-param-option-not-selected {
  margin: 0px;
  cursor: pointer;
}

.param-group-not-selected {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.param-group-selected {
  background-color: #55ec12;
  font-weight: bold;
  cursor: pointer;
}

.param-not-selected-1 {
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-selected-1 {
  background-color: #55ec12;
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-not-selected-2 {
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-selected-2 {
  background-color: #55ec12;
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-not-selected-3 {
  cursor: pointer;
}

.param-selected-3 {
  background-color: #55ec12;
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-group-not-selected:hover,
.param-not-selected-1:hover,
.param-not-selected-2:hover,
.param-not-selected-3:hover {
  background-color: yellow;
  padding-top: 3px;
  padding-bottom: 3px;
}

.group-level {
  padding-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
}

.level-1-param {
  padding-left: 20px;
  margin-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
}

.level-2-param {
  padding-left: 20px;
  margin-left: 40px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
}

.level-3-param {
  padding-left: 20px;
  margin-left: 60px;
  border-left: dotted 1px green;
  border-bottom: dotted 1px green;
}

.parameter-design-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-parameter-design-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.bulkOptionTableTable {
  border: solid 1px;
  width: 100%;
}

.bulkOptionTableTr {
  border: solid 1px;
}

.bulkOptionTableTd {
  border: solid 1px;
}

.bulkOptionTableTh {
  border: solid 1px;
  text-align: center;
  background-color: #ececec;
}

</style>

