<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import WmanagerService from '@/services/wmanager';
import moment from "moment";


var templateRenderFns = [];
export default {
  name: 'LayerTree',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {},
  props: {
    p_treeListData: {
      type: Object,
      required: true
    }
  },
  computed: mapGetters({
    device: 'device',
    lang: 'lang',
    help: 'help'
  }),
  mounted () {
    this.d_wdmrToken = JSON.parse(localStorage.getItem('wdmr_token'));
  },
  created: function () {
    this.d_selectedItem = this.p_treeListData;
    this.render_template();
  },
  data () {
    return {
      d_wdmrShowModal: { 'show': false, 'url_iframe': '' },
      d_showSelectedWdmrItem: true,
      d_selectedTreeItemIndex: '',
      d_wdmrEditNewWebTab: {
        'show': false,
        'model_id': '',
        'schema_id': '',
        'mode': '1',
      },
      d_wisdomData: {
        'wdmr_id': '',
        'show': false,
        'wisdom_op': '', // edit or new
        'url_iframe': ''
      },
      d_newWdmrModal: { 'show': false, 'label': '' },
      d_selectedTreeItem: '',
      d_selectedItem: { 'list': {} },
      d_selectedDecisionPoint: '',
      d_fullScreen: false,
      template: null,
      d_levelCount: 20, // this is defined as default value, to be in safe.
      d_startFromDragType: '',
      d_level: 0,
      WDMR_LINKS: require('@/config/index').WDMR_LINKS,
      d_wdmrToken: { 'access_token': '', 'refresh_token': '' },
      d_icons: {
        '931299': require('@/icon/931299.png'),
        '3039083': require('@/icon/3039083.png'),
        '2766716': require('@/icon/2766716.png'),
        '2960590': require('@/icon/2960590.png'),
        '680848': require('@/icon/680848.png'),
        '3094236': require('@/icon/3094236.png'),
        '629153': require('@/icon/629153.png')
      },
      d_pagination: {
        'start_type': 'begin', // end or begin
        'start': 0,
        'end': 49,
        'current': 0,
        'perpage': 50
      },
    };
  },
  methods: {
    render_template: function () {
      // this.d_level = 0; // When we call this function again, this.d_level must be 0.
      this.d_level = 0;
      this.f_calculateDeepLevel();
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
    },
    f_calculateDeepLevel: function () {
      let result = { 'item_index': [], 'max': 0 };
      this.f_calculateMaximumDeepLevelCount(this.p_treeListData.list, result);
      this.d_levelCount = result.max;
      // this.d_levelCount = this.p_treeListData.items.length;
      // console.log('this.d_levelCount: ', this.d_levelCount);
    },
    build_form: function () {
      let html = '';
      let it_style = ' style="background-color: white;" ';
      let it_drag = ' draggable @dragstart="f_startDragRule($event, it_ind, \'rule_add_tree\')" @dragover.prevent @dragenter.prevent ';
      html += '<b-row style="margin: 0px;" :class="[d_fullScreen ? \'full-screen-mode\' : \'normal-mode\']">';
      // html += '   <b-col sm="12" md="12" :style="f_calculateRuleTreeStyleRight()">';
      html += '   <b-col sm="12" md="12">';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col sm="12" md="6">';
      html += '         </b-col>';
      html += '         <b-col sm="12" md="6" style="text-align: right;">';
      html += '            <b-button variant="white" size="md" style="margin-right: 5px; padding: 0px;" @click="f_resetTreeList()">';
      html += '               <img :src="d_icons[\'3039083\']" title="Reset" style="width: 2em;" />';
      html += '            </b-button>';
      html += '            <b-button size="md" :variant="d_fullScreen ? \'warning\' : \'white\'" :style="d_fullScreen ? \'margin-right: 5px; padding: 0px; border: solid 2px green;\' : \'margin-right: 5px; padding: 0px;\'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">';
      html += '               <img :src="d_icons[\'629153\']" :title="$t(\'wdm16.6589\')" style="width: 2em;" />';
      html += '            </b-button>';
      html += '         </b-col>';
      html += '      </b-row>';
      // html += '      <b-row :style="f_calculateRuleTreeStyleBelow()">';
      html += '      <b-row :style="f_calculateRuleTreeStyleBelow()">';
      html += '         <b-col sm="12" md="3" style="background-color: white; padding-left: 1px; padding-right: 1px;">';
      html += '            <b-card-header class="p-1" style="min-height: 50px; background-color: #caeee0;">';
      html += '               Katmanlar';
      html += '            </b-card-header>';
      html += '               <div :style="f_calculateBottomTabStyle()">';
      html += this.f_createTree();
      html += '               </div>';
      html += '         </b-col>';
      html += '         <b-col sm="12" md="3" style="padding-left: 1px; padding-right: 1px; background-color: white;">';
      html += '            <b-card-header class="p-1" style="min-height: 50px; background-color: #caeee0;">';
      html += '                  <b-row style="margin: 0px;">';
      html += '                     <b-col sm="12" md="3" style="padding-right: 1px; padding-left: 1px;">';
      html += '                        Katman verileri';
      html += '                     </b-col>';
      html += '                     <b-col sm="12" md="2" style="padding-right: 1px; padding-left: 1px;">';
      html += '                        <b-dropdown variant="white" size="md">';
      html += '                           <template slot="button-content"> <img :src="d_icons[\'680848\']" style="width: 2em;" /> </template>';
      html += '                           <b-dropdown-item @click="f_editWdmrData(\'\', \'1\')"><img :src="d_icons[\'3094236\']" style="width: 2em;" /> seçili veriyi düzenle (Mod 1, parametrik) </b-dropdown-item>';
      html += '                           <b-dropdown-item @click="f_editWdmrData(\'\', \'4\')"><img :src="d_icons[\'3094236\']" style="width: 2em;" /> seçili veriyi düzenle (Mod 4, cümle editörü) </b-dropdown-item>';
      html += '                           <b-dropdown-item @click="f_editWdmrData(\'yes\', \'1\')"><img :src="d_icons[\'3094236\']" style="width: 2em;" /> seçili veriyi düzenle (Mod 1, parametrik, yeni sayfa) </b-dropdown-item>';
      html += '                           <b-dropdown-item @click="f_editWdmrData(\'yes\', \'4\')"><img :src="d_icons[\'3094236\']" style="width: 2em;" /> seçili veriyi düzenle (Mod 4, cümle editörü, yeni sayfa) </b-dropdown-item>';
      html += '                           <b-dropdown-item @click="f_editWdmrData(\'\', \'4\', \'yes\')"><img :src="d_icons[\'3094236\']" style="width: 2em;" /> seçili veriyi düzenleme linkini al (Mod 4, cümle editörü, yeni sayfa) </b-dropdown-item>';
      html += '                           <b-dropdown-item @click="f_openNewWdmrModal()"><img :src="d_icons[\'2766716\']" style="width: 2em;" /> yeni veri ekle</b-dropdown-item>';
      html += '                        </b-dropdown>';
      html += '                     </b-col>';
      html += '                     <b-col sm="12" md="7" style="padding-right: 1px; padding-left: 1px; text-align: right;">';
      html += '                        <b-pagination v-if="d_selectedTreeItem && d_selectedTreeItem.wdmr_list && d_selectedTreeItem.wdmr_list.total_count && d_selectedTreeItem.wdmr_list.total_count > 0"  size="sm" style="margin-bottom: 0px; padding: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_selectedTreeItem.wdmr_list.total_count" :per-page="d_pagination.perpage"></b-pagination>';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '            </b-card-header>';
      html += '            <bcard v-if="d_selectedTreeItem" no-body>';
      html += '               <template v-if="d_selectedTreeItem.wdmr_list.list && d_selectedTreeItem.wdmr_list.list.length > 0">';
      html += '                  <div :style="f_calculateBottomTabStyle()">';
      html += '                     <template v-for="(x, x_ind) in d_selectedTreeItem.wdmr_list.list">';
      html += '                        <b-card :style="d_selectedTreeItem.wdmr_list.selected_index === x_ind ? \'background: #9adcd3; margin-bottom: 3px; cursor: pointer;\' : \'margin-bottom: 3px; cursor: pointer;\'" no-body @click="f_selectWdmrRecord(x_ind)">';
      html += '                           <b-row>';
      html += '                              <b-col cols="12">';
      html += '                                 <div>';
      html += '                                    <span> {{ x_ind + 1 }} - {{ x.label }} </span>';
      html += '                                    <small style="color: blue;">[ {{ x.w_id }} ]</small>';
      html += '                                    <small v-if="x.status" style="color: blue;">[ {{ x.status }} ]</small>';
      html += '                                    <small v-if="x.process_date" style="color: blue;">[ {{ f_dateFormat(x.process_date) }} ]</small>';
      html += '                                    <small v-if="x.date" style="color: blue;">[ {{ f_dateFormat(x.date) }} ]</small>';
      html += '                                 </div>';
      html += '                              </b-col>';
      html += '                           </b-row>';
      html += '                        </b-card>';
      html += '                     </template>';
      html += '                  </div>';
      html += '               </template>';
      html += '            </bcard>';
      html += '         </b-col>';
      html += '         <b-col sm="12" md="6" style="padding-left: 1px; padding-right: 1px; background-color: white;">';
      html += '            <b-card-header class="p-1" style="min-height: 50px; background-color: #caeee0;">';
      html += '               <b-row style="margin: 0px;">';
      html += '                  <b-col sm="12" md="9" style="padding-right: 1px; padding-left: 1px;">';
      html += '                    Seçili veri detayları';
      html += '                  </b-col>';
      html += '                  <b-col sm="12" md="3" style="padding-right: 1px; padding-left: 1px; text-align: right;">';
      html += '                     <b-dropdown variant="white" size="md">';
      html += '                        <template slot="button-content"> <img :src="d_icons[\'680848\']" style="width: 2em;" /> </template>';
      html += '                        <b-dropdown-item v-if="d_showSelectedWdmrItem && d_selectedTreeItem && d_selectedTreeItem.wdmr_list && d_selectedTreeItem.wdmr_list.url_show_iframe" @click="f_showWdmrModal()"><img :src="d_icons[\'2766716\']" style="width: 2em;" /> ön pencerede göster</b-dropdown-item>';
      html += '                     </b-dropdown>';
      html += '                  </b-col>';
      html += '               </b-row>';
      html += '            </b-card-header>';
      html += '            <b-row v-if="d_showSelectedWdmrItem && d_selectedTreeItem && d_selectedTreeItem.wdmr_list && d_selectedTreeItem.wdmr_list.url_show_iframe" :style="f_calculateSubTabStyle()">';
      html += '               <b-col sm="12" md="12">';
      html += '                  <iframe :src="d_selectedTreeItem.wdmr_list.url_show_iframe" style="width: 100%; height: 100%;"></iframe>';
      html += '               </b-col>';
      html += '            </b-row>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </b-col>';
      html += '</b-row>';
      html += this.f_createNewWdmrLabelModal();
      html += this.f_createNewWdmrModal();
      html += this.f_createShowWdmrModal();
      return '<div>' + html + '</div>';
    },
    f_createNewWdmrLabelModal: function () {
      let html = '';
      html += '<b-modal v-if="d_newWdmrModal.show" v-model="d_newWdmrModal.show" size="md" title="Yeni veri kayıt oluşturma ekranı" centered scrollable hide-footer header-bg-variant="primary">';
      html += '   <b-card no-body>';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col cols="12">';
      html += '            <strong>Veri Başlığı</strong>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col cols="12">';
      html += '            <b-form-input v-model="d_newWdmrModal.label"></b-form-input>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <b-row>';
      html += '         <b-col cols="12" style="text-align: right;">';
      html += '            <button type="button" class="btn btn-success" v-on:click="f_addNewWdmrToThisLayer()">yeni kayıt oluştur</button>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </b-card>';
      html += '</b-modal>';
      return html;
    },
    f_createNewWdmrModal: function () {
      let html = '';
      html += '<b-modal v-if="d_wisdomData.show" v-model="d_wisdomData.show" centered class="modal-success" @ok="d_wisdomData.show = false" ok-variant="success" hide-footer size="xl">';
      html += '   <iframe :src="d_wisdomData.url_iframe" style="width: 100%; min-height: 500px;"></iframe>';
      html += '   <b-row>';
      html += '      <b-col cols="12" style="text-align: right;">';
      html += '         <b-button variant="danger" v-on:click="d_wisdomData.show = false">kapat</b-button>';
      html += '         <b-button v-if="d_wisdomData.wisdom_op === \'new\'" variant="success" v-on:click="f_completeWdmrSave()">yeni kayıt oluştur</b-button>';
      html += '         <b-button v-if="d_wisdomData.wisdom_op === \'edit\'" variant="success" v-on:click="f_completeWdmrSave()">güncelle</b-button>';
      html += '      </b-col>';
      html += '   </b-row>';
      html += '</b-modal>';
      return html;
    },
    f_createShowWdmrModal: function () {
      let html = '';
      html += '<b-modal v-if="d_wdmrShowModal.show" v-model="d_wdmrShowModal.show" centered class="modal-success" @ok="d_wdmrShowModal.show = false" ok-variant="success" hide-footer size="xl">';
      html += '   <iframe :src="d_wdmrShowModal.url_iframe" style="width: 100%; min-height: 500px;"></iframe>';
      html += '</b-modal>';
      return html;
    },
    f_completeWdmrSave: function () {
      this.d_wisdomData.show = false;
      if (this.d_wisdomData.wisdom_op === 'edit') {
        this.f_showTrueFalseWdmrIframe();
      } else {
        this.f_getWdmrList();
      }
    },
    f_showWdmrModal: function () {
      this.d_wdmrShowModal.url_iframe = this.d_selectedTreeItem.wdmr_list.url_show_iframe;
      this.d_wdmrShowModal.show = true;
    },
    f_resetTreeList: function () {
      this.d_selectedItem = this.p_treeListData;
    },
    f_clearTreeList: function () {
      this.p_treeListData.list = [];
    },
    f_calculateRuleTreeStyleBelow: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 50;
      } else {
        h = window.innerHeight - 200;
      }
      return "border: solid 0.1em green; overflow-y: auto; overflow-x: auto; height: " + h + "px;";
    },
    f_calculateRuleTreeStyleRight: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 30;
      } else {
        h = window.innerHeight * 50 / 100;
      }
      return "height: " + h + "px;";
    },
    f_calculateRuleTreeStyleLeft: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 30;
      } else {
        h = window.innerHeight * 50 / 100;
      }
      return "overflow-y: auto; overflow-x: auto; height: " + h + "px;";
    },
    f_createTree: function () {
      let html = '';
      this.d_level += 1;
      let it_style = ' style="box-shadow: #cfecea -1px -1px 1px 1px; width: 100%; margin-bottom: 3px; margin-bottom: 3px; background-color: white; color: black;"';
      html += '<template v-for="(item' + this.d_level.toString() + ', item_index' + this.d_level.toString() + ') in ' + this.f_upperItem() + '.list">';
      let margin_left_style = 'margin-left:' + ((this.d_level - 1) * 10).toString() + 'px;';
      html += '<b-card no-body ' + it_style + '>';
      html += '<div :style="f_calculateItemHeaderStyle([' + this.f_itemIndexList() + '], [' + this.f_upperItemIndexList() + '], item' + this.d_level.toString() + ', ' + this.f_upperItem() + ')">';
      html += '   <b-row style="margin: 0px; min-height: 20px; width: 100%;">';
      html += '      <b-col sm="12" md="10" style="cursor: pointer; padding: 1px">';
      html += '         <b-row style="margin: 0px; min-height: 20px; width: 100%; ' + margin_left_style + '">';
      html += '            <b-col sm="12" md="2">';
      html += '               <b-button variant="white" style="padding: 0px;" @click="f_selectItem([' + this.f_itemIndexList() + '], [' + this.f_upperItemIndexList() + '], item' + this.d_level.toString() + ')"><i class="fa fa-star" style="color: green;"></i></b-button>';
      html += '            </b-col>';
      html += '            <b-col sm="12" md="8" style="cursor: pointer;" @click="f_selectTreeItem([' + this.f_itemIndexList() + '], [' + this.f_upperItemIndexList() + '], item' + this.d_level.toString() + ', ' + this.f_upperItem() + ')" >';
      html += '               <strong :title="item' + this.d_level.toString() + '.label" style="font-size: 10px;"> {{ item' + this.d_level.toString() + '.label.length > 20 ? item' + this.d_level.toString() + '.label.substring(0,50) + "..." : item' + this.d_level.toString() + '.label }} </strong>';
      html += '               <small>({{ item' + this.d_level.toString() + '.id }})</small>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </b-col>';
      html += '      <b-col sm="12" md="2">';
      html += '         <b-button variant="white" style="padding: 0px;" @click="f_infoLayer([' + this.f_itemIndexList() + '], item' + this.d_level.toString() + ')"><i class="fa fa-info"></i></b-button>';
      html += '      </b-col>';
      html += '   </b-row>';
      html += "</div>";
      html += '   <b-row v-if="item' + this.d_level.toString() + '.wdmr_list && item' + this.d_level.toString() + '.wdmr_list.selected_index !== undefined && item' + this.d_level.toString() + '.wdmr_list.selected_index !== \'\'" style="margin-left: 5px; margin-right: 0px;">';
      html += '      <b-col cols="12">';
      html += '          <i class="fa fa-check"></>';
      html += '         <span> {{ item' + this.d_level.toString() + '.wdmr_list.list[item' + this.d_level.toString() + '.wdmr_list.selected_index].label }} </span>';
      html += '         <small> {{ item' + this.d_level.toString() + '.wdmr_list.list[item' + this.d_level.toString() + '.wdmr_list.selected_index].w_id }} </small>';
      html += '      </b-col>';
      html += '   </b-row>';
      html += '</b-card>';
      // html += '<img v-if="item' + this.d_level.toString() + '.list.length !== 0" :src="d_icons[\'931299\']" style="width: ' + (4 / this.d_level) + 'em; margin-left:' + (this.d_level * 30) + 'px;" />';
      // html += '<img v-if="item' + this.d_level.toString() + '.list.length !== 0" :src="d_icons[\'931299\']" style="width: 0.7em; margin-left:' + (this.d_level * 30) + 'px;" />';
      // html += '<div v-if="item' + this.d_level.toString() + '.list.length === 0" style="height:' + (2 / this.d_level) + 'em;"></div>'
      if (this.d_level <= this.d_levelCount) {
        html += this.f_createTree();
        this.d_level -= 1;
      }
      html += '</template>';
      return html;
    },
    f_calculateItemHeaderStyle: function (item_index, upper_index_list, item, upper_item) {
      let style = '';
      if (this.d_selectedTreeItemIndex && this.d_selectedTreeItemIndex.join('.') === item_index.join('.')) {
        style += 'border-bottom: 1px solid #989898; background-color: #5cf28e;';
      } else {
        // style += 'border-bottom: 1px solid #989898; background-color: #c9f4e3;';
        // style += 'border-bottom: 1px solid #989898; background-color: #dcdcdc;';
        // style += 'border-bottom: 1px solid #989898; background-color: white;';
        // style += 'border-bottom: 1px solid #989898; background-color: #92e8d3;';
        style += 'border-bottom: 1px solid #989898; background-color: #daf0e1;';
      }
      return style;
    },
    f_deleteItem: function (index_list, item) {
      let list = this.f_getTreeLocation(index_list, 'list');
      list.splice(index_list[index_list.length - 1], 1);
      for (let i in this.p_treeListData.items) {
        if (this.p_treeListData.items[i].value === item.value) {
          this.$delete(this.p_treeListData.items[i], 'is_added');
          break;
        }
      }
      this.p_treeListData.items
      this.$forceUpdate();
    },
    f_selectItem: function (index_list, upper_index_list, item) {
      this.d_selectedItem = { 'list': [item] };
      // this.render_template();
      this.$forceUpdate();
    },
    f_startDragRule: function (evt, item_index, type) {
      // console.log('item_index', item_index);
      /*
        if (!this.p_treeListData.items[item_index].is_added) {
        }
      */
      this.d_startFromDragType = type;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', item_index);
    },
    f_onDropRule: function (evt, index_list = [], item_data = '') {
      // console.log('index_list: ', index_list);
      // console.log('this.d_startFromDragTypeDate ', this.d_startFromDragTypeDate);
      let fromInd = evt.dataTransfer.getData('fromInd');
      console.log('fromInd', fromInd);
      if (fromInd !== undefined && this.p_treeListData.items[fromInd]) {
        this.p_treeListData.items[fromInd].is_added = 1;
        let item = { 'value': this.p_treeListData.items[fromInd].value, 'label': this.p_treeListData.items[fromInd].label };
        item.list = [];
        if (index_list.length === 0) {
          this.p_treeListData.list.push(item);
        } else {
          // let item_data = this.f_getTreeLocation(index_list, 'item_data');
          item_data.list.push(item);
        }
        // this.$forceUpdate();
        this.render_template();
        // this.render_template();
        // if (this.d_startFromDragTypeField === 'free_div') {}
        // this.f_controlCompileStrFirstError();
      }
    },
    f_getTreeLocation: function (item_index_list, type) {
      let listLoc = this.d_selectedItem.list;
      for (let i in item_index_list) {
        if (parseInt(i) !== item_index_list.length - 1) {
          listLoc = listLoc[item_index_list[i]].list;
          // console.log('listLoc: ', listLoc);
        } else {
          if (type === 'item_data') {
            return listLoc[item_index_list[i]];
          } else if (type === 'list') {
            // console.log('listLoc:', listLoc);
            return listLoc;
          }
        }
      }
      if (type === 'list') {
        return listLoc;
      } else if (type === 'item_data') {
        return this.d_selectedItem;
      }
    },
    f_calculateMaximumDeepLevelCount: function (list_data, result) {
      for (let i in list_data) {
        if (parseInt(i) === 0) {
          result.item_index.push(i);
        } else {
          result.item_index[result.item_index.length - 1] = i;
        }
        // console.log(result.item_index);
        if (result.item_index.length > result.max) {
          result.max = result.item_index.length;
        }
        list_data[i].index = result.item_index.join(',');
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_calculateMaximumDeepLevelCount(list_data[i].list, result);
          result.item_index.splice(result.item_index.length - 1, 1);
        }
      }
    },
    f_itemIndexList: function () {
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_upperItemIndexList: function () {
      // upper index list
      let str = '';
      for (let i = 1; i <= this.d_level; i++) {
        str += 'item_index' + i.toString();
        if (i !== this.d_level) {
          str += ', ';
        }
      }
      return str;
    },
    f_infoLayer: function (item_index, item) {
      alert(item.label);
    },
    f_upperItem: function () {
      if (this.d_level === 1) {
        return 'd_selectedItem';
      } else {
        return 'item' + (this.d_level - 1).toString();
      }
    },
    f_selectTreeItem: function (item_index, upper_index_list, item, upper_item) {
      this.d_selectedTreeItemIndex = item_index;
      this.f_clearBelowWdmrList(item);
      this.d_selectedTreeItem = '';
      this.d_pagination = {
        'start_type': 'begin', // end or begin
        'start': 0,
        'end': 49,
        'current': 0,
        'perpage': 50
      };
      // console.log("item_index        : ", item_index);
      // console.log("item              : ", item);
      // console.log("upper_index_list  : ", upper_index_list);
      // console.log("upper_item        : ", upper_item);
      // console.log("this.f_selectTreeItem ");
      if (upper_item.wdmr_list && upper_item.wdmr_list.selected_index !== undefined && upper_item.wdmr_list.selected_index !== '') {
        let upper_item_selected_wdmr_id = upper_item.wdmr_list.list[upper_item.wdmr_list.selected_index].w_id;
        // console.log('upper_item_selected_wdmr_id : ', upper_item_selected_wdmr_id);
        let layer_id = item.id;
        let get_wdmr_data = '0';
        let parent_layer_id = upper_item.id;
        let query = 'layer_id=' + layer_id;
        query += '&get_wdmr_data=' + get_wdmr_data;
        query += '&parent_layer_id=' + parent_layer_id;
        query += '&parent_wdmr_id=' + upper_item_selected_wdmr_id;
        let data = {};
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler hazırlanıyor. Lütfen bekleyiniz' } });
        WmanagerService.get_wdmr_list_by_parent_wdmr_id(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000' || resp.data.status_code === '3029') {
              item.wdmr_list = { 'list': [], 'selected_index': '', 'total_count': 0 };
              item.wdmr_list.selected_index = '';
              item.wdmr_list.list = resp.data.list;
              if (resp.data.count) {
                item.wdmr_list.total_count = resp.data.count;
              } else {
                item.wdmr_list.total_count = 10000;
              }
              this.d_selectedTreeItem = item;
              this.d_selectedTreeItem.upper_item_selected_wdmr_id = upper_item_selected_wdmr_id;
              this.$forceUpdate();
            }
          }, resp => {
            console.log(resp);
          });
        /*
          let start_type = this.d_pagination.start_type;
          if (start_type === 'begin') {
            start_type = 'beginning';
          }
          let limit = this.d_pagination.perpage.toString();
          let offset = this.d_pagination.start.toString();
          if (limit) {
            query += '&limit=' + limit;
          }
          if (offset) {
            query += '&offset=' + offset;
          }
          if (start_type) {
            query += '&start_type=' + start_type;
          }
          let w_id_list = [upper_item_selected_wdmr_id];
          let data = {
            'w_id_list': w_id_list
          };
        WmanagerService.get_wdmr_list_by_wdmr_id(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000' || resp.data.status_code === '3029') {
              item.wdmr_list = { 'list': [], 'selected_index': '', 'total_count': 0 };
              item.wdmr_list.selected_index = '';
              item.wdmr_list.list = resp.data.list;
              if (resp.data.count) {
                item.wdmr_list.total_count = resp.data.count;
              } else {
                item.wdmr_list.total_count = 10000;
              }
              this.d_selectedTreeItem = item;
              this.d_selectedTreeItem.upper_item_selected_wdmr_id = upper_item_selected_wdmr_id;
              this.$forceUpdate();
            }
          }, resp => {
            console.log(resp);
          });
          */
      } else {
        let start_type = this.d_pagination.start_type;
        let limit = this.d_pagination.perpage.toString();
        let offset = this.d_pagination.start.toString();
        let layer_id = item.id;
        let get_wdmr_data = '0';
        let query = 'layer_id=' + layer_id + '&get_wdmr_data=' + get_wdmr_data;
        if (limit) {
          query += '&limit=' + limit;
        }
        if (offset) {
          query += '&offset=' + offset;
        }
        if (start_type) {
          query += '&start_type=' + start_type;
        }
        WmanagerService.get_layer_wdmr_list(query)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000' || resp.data.status_code === '3029') {
              item.wdmr_list = { 'list': [], 'selected_index': '', 'total_count': 0 };
              item.wdmr_list.selected_index = '';
              item.wdmr_list.list = resp.data.list;
              item.wdmr_list.total_count = resp.data.count;
              this.d_selectedTreeItem = item;
              this.$forceUpdate();
            }
          }, resp => {
            console.log(resp);
          });
      }
    },
    f_clearBelowWdmrList: function (item_data) {
      this.f_clearBelowWdmrListInfinity(item_data.list);
    },
    f_clearBelowWdmrListInfinity: function (list_data) {
      for (let i in list_data) {
        if (list_data[i].wdmr_list !== undefined) {
          this.$delete(list_data[i], 'wdmr_list');
        }
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_clearBelowWdmrListInfinity(list_data[i].list);
        }
      }
    },
    f_getWdmrList: function () {
      if (this.d_selectedTreeItem && this.d_selectedTreeItem.id) {
        if (this.d_selectedTreeItem.upper_item_selected_wdmr_id) {
          let upper_item_selected_wdmr_id = this.d_selectedTreeItem.upper_item_selected_wdmr_id;
          let start_type = this.d_pagination.start_type;
          if (start_type === 'begin') {
            start_type = 'beginning';
          }
          let limit = this.d_pagination.perpage.toString();
          let offset = this.d_pagination.start.toString();
          let layer_id = this.d_selectedTreeItem.id;
          let get_wdmr_data = '0';
          let query = 'layer_id=' + layer_id + '&wdmr_data=' + get_wdmr_data;
          if (limit) {
            query += '&limit=' + limit;
          }
          if (offset) {
            query += '&offset=' + offset;
          }
          if (start_type) {
            query += '&start_type=' + start_type;
          }
          let w_id_list = [upper_item_selected_wdmr_id];
          let data = {
            'w_id_list': w_id_list
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler hazırlanıyor. Lütfen bekleyiniz' } });
          WmanagerService.get_wdmr_list_by_wdmr_id(query, data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '3000') {
                this.d_selectedTreeItem.wdmr_list = { 'list': [], 'selected_index': '', 'total_count': 0 };
                this.d_selectedTreeItem.wdmr_list.selected_index = '';
                this.d_selectedTreeItem.wdmr_list.list = resp.data.list;
                if (resp.data.count) {
                  this.d_selectedTreeItem.wdmr_list.total_count = resp.data.count;
                } else {
                  this.d_selectedTreeItem.wdmr_list.total_count = 10000;
                }
                this.$forceUpdate();
              }
            }, resp => {
              console.log(resp);
            });
        } else {
          let start_type = this.d_pagination.start_type;
          let limit = this.d_pagination.perpage.toString();
          let offset = this.d_pagination.start.toString();
          let layer_id = this.d_selectedTreeItem.id;
          let get_wdmr_data = '0';
          let query = 'layer_id=' + layer_id + '&get_wdmr_data=' + get_wdmr_data;
          if (limit) {
            query += '&limit=' + limit;
          }
          if (offset) {
            query += '&offset=' + offset;
          }
          if (start_type) {
            query += '&start_type=' + start_type;
          }
          WmanagerService.get_layer_wdmr_list(query)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '3000') {
                this.d_selectedTreeItem.wdmr_list = { 'list': [], 'selected_index': '', 'total_count': 0 };
                this.d_selectedTreeItem.wdmr_list.selected_index = '';
                this.d_selectedTreeItem.wdmr_list.list = resp.data.list;
                this.d_selectedTreeItem.wdmr_list.total_count = resp.data.count;
                this.$forceUpdate();
              }
            }, resp => {
              console.log(resp);
            });
        }
      }
    },
    f_calculateBottomTabStyle: function () {
      let height = '';
      let overflow_x = '';
      if (this.d_fullScreen) {
        height = window.innerHeight - 125;
        overflow_x = 'hidden';
      } else {
        height = window.innerHeight - 270;
        overflow_x = 'hidden';
        // console.log('height: ', height);
      }
      if (this.device.isMobile) {
        return 'margin-bottom: 0px; border: none;';
      } else {
        return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
      }
    },
    f_selectWdmrRecord: function (x_ind) {
      let layer_id = this.d_selectedTreeItem.id;
      this.d_selectedTreeItem.wdmr_list.selected_index = x_ind;
      let wdmr_id = this.d_selectedTreeItem.wdmr_list.list[x_ind].w_id;
      /*
        if (this.d_layerInformation.data && this.d_layerInformation.data.general.generate_wdmr_token && this.d_layerInformation.data.general.generate_wdmr_token.val && this.d_layerInformation.data.general.generate_wdmr_token.val.value) {
          if (this.d_layerInformation.data.general.generate_wdmr_token.val.value === 'yes') {

          }
        }
      */
      // For every selection, we select the wdmr as parent. But it is not every time. This approach must be differently designed.
      this.f_updateUrlIframe(this.d_selectedTreeItem.wdmr_list, 'url_show_iframe', this.f_createUrlIframeShow(wdmr_id));
      this.f_updateUrlIframe(this.d_selectedTreeItem.wdmr_list, 'url_record_iframe', this.f_createUrlIframeRecord(wdmr_id, '', 'manuel'));
      this.f_showTrueFalseWdmrIframe();
      /*
        let query = 'layer_id=' + layer_id;
        query += '&wdmr_id=' + wdmr_id;
        WmanagerService.get_layer_wdmr(query)
          .then(resp => {
            if (resp.data.status_code === "3000") {
              this.d_selectedTreeItem.wdmr_list.data = resp.data.wdmr;
              this.$forceUpdate();
            } else {
              console.log(resp);
            }
          }), resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          };
      */
    },
    f_showTrueFalseWdmrIframe: function () {
      this.d_showSelectedWdmrItem = false;
      setTimeout(function () {
        this.d_showSelectedWdmrItem = true;
        this.$forceUpdate();
      }.bind(this), 100);
    },
    f_updateUrlIframe: function (obj, url_iframe_var, new_url) {
      obj[url_iframe_var] = new_url;
    },
    f_createUrlIframeShow: function (wdmr_id) {
      let layer_id = this.d_selectedTreeItem.id;
      let url_iframe_show = this.WDMR_LINKS['wdmr_data_show'] + 'layer_id=' + layer_id + '&wdmr_id=' + wdmr_id + '&wisdom=' + this.d_wdmrToken.access_token + '&modsiw=' + this.d_wdmrToken.refresh_token;
      // console.log('url_iframe_show : ', url_iframe_show);
      return url_iframe_show;
    },
    f_dateFormat: function (date) {
      if (date) {
        let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
        return x;
      } else {
        return "";
      }
    },
    f_createUrlIframeRecord: function (wdmr_id = '', parent_wdmr_id = '', record_type = 'manuel', model_id = '', schema_id = '', screen_mode = '1') {
      let layer_id = this.d_selectedTreeItem.id;
      let url_iframe_record = this.WDMR_LINKS['wdmr_data_record'] + 'layer_id=' + layer_id;
      if (wdmr_id) {
        url_iframe_record += '&wdmr_id=' + wdmr_id;
      }
      if (model_id) {
        url_iframe_record += '&model_id=' + model_id;
      }
      if (schema_id) {
        url_iframe_record += '&schema_id=' + schema_id;
      }
      if (parent_wdmr_id) {
        url_iframe_record += '&parent_wdmr_id=' + parent_wdmr_id;
      }
      if (screen_mode) {
        url_iframe_record += '&screen_mode=' + screen_mode;
      }
      url_iframe_record += '&record_type=' + record_type;
      url_iframe_record += '&wisdom=' + this.d_wdmrToken.access_token + '&modsiw=' + this.d_wdmrToken.refresh_token;
      // console.log('url_iframe_recordu : ', url_iframe_record);
      return url_iframe_record;
    },
    f_addNewWdmrToThisLayer: function () {
      if (confirm('İşleme devam etmeye emin misiniz ?')) {
        let layer_id = this.d_selectedTreeItem.id;
        let wdmr_data = {
          'data': {}
        };
        if (this.d_newWdmrModal.label) {
          wdmr_data.label = this.d_newWdmrModal.label;
        }
        let data = {
          'wdmr': wdmr_data
        };
        let query = 'layer_id=' + layer_id;
        if (this.d_selectedTreeItem && this.d_selectedTreeItem.upper_item_selected_wdmr_id) {
          query += '&parent_wdmr_id=' + this.d_selectedTreeItem.upper_item_selected_wdmr_id;
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veri kaydı oluşturuluyor. Lütfen bekleyiniz.' } });
        WmanagerService.layer_wdmr_record(query, data)
          .then(resp => {
            /*
              {
                  "status_code": "<string> | 3000",
                  "status_message": "<string> | Successfully responded",
                  "w_id": "<string> | new created or updated wdmr’s id",
                  "cas": "<string> | new created or updated wdmr’s cas"
              }
            */
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status_code === "3000") {
              let wdmr_id = resp.data.w_id;
              let url_show_iframe = this.f_createUrlIframeRecord(wdmr_id, '', 'manuel');
              this.d_wisdomData = {
                'wdmr_id': wdmr_id,
                'show': true,
                'wisdom_op': 'new',
                'url_iframe': url_show_iframe
              };

            } else {
              console.log(resp);
            }
          }), resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          };
      }
    },
    f_openNewWdmrModal: function () {
      this.d_newWdmrModal.show = true;
    },
    f_editWdmrData: function (new_screen = '', screen_mode = '1', get_link = 'no') {
      let layer_id = this.d_selectedTreeItem.id;
      if (this.d_selectedTreeItem.wdmr_list && this.d_selectedTreeItem.wdmr_list.selected_index !== undefined && this.d_selectedTreeItem.wdmr_list.selected_index !== '') {
        if (get_link === 'yes') {
          let wdmr_id = this.d_selectedTreeItem.wdmr_list.list[this.d_selectedTreeItem.wdmr_list.selected_index].w_id;
          let model_id = this.d_wdmrEditNewWebTab.model_id;
          let schema_id = this.d_wdmrEditNewWebTab.schema_id;
          let url_show_iframe = this.f_createUrlIframeRecord(wdmr_id, '', 'manuel', model_id, schema_id, screen_mode);
          let modal_data = { 'type': 'alert', 'text': url_show_iframe, 'centered': true, 'title': 'Link' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        } else {
          let wdmr_id = this.d_selectedTreeItem.wdmr_list.list[this.d_selectedTreeItem.wdmr_list.selected_index].w_id;
          let model_id = this.d_wdmrEditNewWebTab.model_id;
          let schema_id = this.d_wdmrEditNewWebTab.schema_id;
          let url_show_iframe = this.f_createUrlIframeRecord(wdmr_id, '', 'manuel', model_id, schema_id, screen_mode);
          if (new_screen === 'yes') {
            window.open(url_show_iframe, '_blank');
          } else {
            this.d_wisdomData = {
              'wdmr_id': wdmr_id,
              'show': true,
              'wisdom_op': 'edit',
              'url_iframe': url_show_iframe
            };
          }
        }
      } else {
        alert('Lütfen öncelikle veri seçimi yapınız.');
      }
    },
    f_calculateSubTabStyle: function () {
      let h = '';
      if (this.d_fullScreen) {
        h = window.innerHeight - 125;
      } else {
        h = window.innerHeight - 270;
      }
      return 'overflow-y: auto; height:' + h.toString() + 'px; overflow-x: hidden; margin-bottom: 0px; border: none;';
    },
  },
  watch: {
    'd_pagination.current': function () {
      if (this.d_pagination.current <= 0) {
        this.d_pagination.current = 1;
      }
      this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
      this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
      this.f_getWdmrList();
    },
  }
};

</script>

<style>
.normal-mode {}

.pagination {
  padding: 0px;
  margin: 0px;
}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

