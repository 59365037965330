<template>
  <div>
    <b-card no-body header-tag="header" :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <!-- <b-dropdown class="pull-right">
            <b-dropdown-item @click=""> <img src="@/icon/2960590.png" style="width: 2em;"> </b-dropdown-item>
          </b-dropdown> -->
        <img src="@/icon/1430869.png" style="width: 2em;" /> {{ $t('wdm16.11147') }}
        <template v-if="!device.isMobile">
          <b-button class="pull-right" size="md" :variant="d_fullScreen ? 'warning' : 'secondary'" :style="d_fullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
            <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
          </b-button>
          <b-button class="pull-right" size="md" :variant="d_openAlgoritm ? 'warning' : 'secondary'" :style="d_openAlgoritm ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_openClose()">
            <img src="@/icon/45774.png" :title="$t('wdm16.11222')" style="width: 2em;" />
          </b-button>
          <!--           <b-button class="pull-right" size="md" :variant="p_wdmRuleAsettings.mode === 'param' ? 'warning' : 'secondary'" :style="p_wdmRuleAsettings.mode === 'param' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_selectMode('param')">
            PARAM
          </b-button>
          <b-button class="pull-right" size="md" :variant="p_wdmRuleAsettings.mode === 'param_query' ? 'warning' : 'secondary'" :style="p_wdmRuleAsettings.mode === 'param_query' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_selectMode('param_query')">
            PARAM & QUERY
          </b-button>
          <b-button class="pull-right" size="md" :variant="p_wdmRuleAsettings.mode === 'wdm_query' ? 'warning' : 'secondary'" :style="p_wdmRuleAsettings.mode === 'wdm_query' ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="f_selectMode('wdm_query')">
            WDM & QUERY
          </b-button> -->
        </template>
        <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px; width: 150px;" @click="f_selectMode()">
          <template v-if="p_wdmRuleAsettings.mode === 'param'">
            <img src="@/icon/719135.png" :title="$t('wdm16.11223')" style="width: 2em;" /> {{ $t('wdm16.11226') }}
          </template>
          <template v-else-if="p_wdmRuleAsettings.mode === 'param_query'">
            <img src="@/icon/719135.png" :title="$t('wdm16.11224')" style="width: 2em;" /> {{ $t('wdm16.11227') }}
          </template>
          <template v-else-if="p_wdmRuleAsettings.mode === 'wdm_query'">
            <img src="@/icon/719135.png" :title="$t('wdm16.11225')" style="width: 2em;" /> {{ $t('wdm16.11228') }}
          </template>
        </b-button>
        <!-- <select class="pull-right mode_cls" v-model="p_wdmRuleAsettings.mode">
            <option v-for="(opt, opt_ind) in p_wdmRuleAsettings.options" :value="opt.value">{{ opt.label }}</option>
          </select> -->
      </b-card-header>
      <template v-if="d_openAlgoritm">
        <template v-if="p_wdmRuleAsettings.mode === 'param'">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <wdm-parameter-select :option_data="option_data" :p_wdmList="p_wdmList" :mother_children="mother_children" :p_isPolestarColumn="p_isPolestarColumn" :p_wdmRuleAsettings="p_wdmRuleAsettings" :p_wdmParameterSelectData="p_wdmParameterSelectData" :p_fastAnalyzeMode="p_fastAnalyzeMode"></wdm-parameter-select>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="p_wdmRuleAsettings.mode === 'param_query'">
          <b-row style="margin: 0px;">
            <b-col sm="12" lg="12" style="padding-right: 3px;">
              <b-card no-body class="mb-1" header-tag="header">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <img src="@/icon/1430869.png" style="width: 2em;" /> {{ $t('wdm16.11229') }}
                </b-card-header>
                <b-row style="margin: 0px;">
                  <b-col sm="12" lg="11" style="padding: 0px;">
                    <wdm-parameter-select :option_data="option_data" :p_wdmList="p_wdmList" :mother_children="mother_children" :p_isPolestarColumn="p_isPolestarColumn" :p_wdmRuleAsettings="p_wdmRuleAsettings" :p_wdmParameterSelectData="p_wdmParameterSelectData" :p_fastAnalyzeMode="p_fastAnalyzeMode"></wdm-parameter-select>
                  </b-col>
                  <b-col sm="12" lg="1" style="padding: 0px;">
                    <b-dropdown variant="secondary" text="" size="md">
                      <template v-if="p_isPolestarColumn">
                        <template v-if="query_list.length === 0">
                          <b-dropdown-item @click="f_queryFromParam('polestar_column')"> {{ $t('wdm16.11230') }} </b-dropdown-item>
                        </template>
                        <template v-else>
                          <b-dropdown-item @click="f_addToThisQueryFromParam(0)">
                            <strong style="color: blue;"> {{ query_list[0].type.label }} => </strong> {{ $t('wdm16.11231') }}
                          </b-dropdown-item>
                        </template>
                      </template>
                      <template v-else>
                        <b-dropdown-item @click="f_queryFromParam()"> {{ $t('wdm16.11232') }} </b-dropdown-item>
                        <!-- <b-dropdown-item @click="f_queryFromParam('missing')"> yeni sorgu (Bu parametre dolu değilse, - missing- ) </b-dropdown-item> -->
                        <template v-for="(query, q_ind) in query_list">
                          <b-dropdown-item v-if="query.type.value === p_wdmParameterSelectData[0].selected_wdm.type" @click="f_addToThisQueryFromParam(q_ind, '')">
                            <strong style="color: blue;"> {{ q_ind + 1 }} ) {{ query.type.label }} => </strong> {{ $t('wdm16.11233') }}
                          </b-dropdown-item>
                          <b-dropdown-item v-if="query.type.value === p_wdmParameterSelectData[0].selected_wdm.type" @click="f_addToThisQueryFromParam(q_ind, 'missing')">
                            <strong style="color: blue;"> {{ q_ind + 1 }} ) {{ query.type.label }} => </strong> {{ $t('wdm16.11234') }}
                          </b-dropdown-item>
                        </template>
                      </template>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <template v-if="d_showRuleQuery && query_list.length > 0">
                  <rule-query :query_list="query_list" :compile_str="compile_str_array" :mode="'edit'" :p_isPolestarColumn="p_isPolestarColumn" :compile_str_date="compile_str_date" :compile_str_text="compile_str_text"></rule-query>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="p_wdmRuleAsettings.mode === 'wdm_query'">
          <b-row style="margin: 0px;">
            <b-col cols="6">
              <b-card no-body class="mb-1" header-tag="header">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <img src="@/icon/1430869.png" style="width: 2em;" /> {{ $t('wdm16.11235') }}
                  <v-select class="pull-right" style="width: 50%;" v-model="d_selectedDataType" :options="p_wdmList"></v-select>
                  <!--                   <select class="pull-right mode_cls" v-model="d_selectedDataType" placeholder="wdm seçiniz">
                    <option v-for="(opt, opt_ind) in p_wdmList" :value="opt">{{ opt.label }}</option>
                  </select>
 -->
                </b-card-header>
                <div v-if="data_type && option_data[data_type]" style="overflow-x: auto; overflow-y: auto; height: 500px;">
                  <wisdom-data-modal :data="prepare_data" :data_type="data_type" :option_data="option_data" :rule_engine="true" :query_list="query_list" :wdmr_change="wdmr_change" :polestar="p_isPolestarColumn" :query_list_type="p_isPolestarColumn ? 'one_query' : ''" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
                </div>
              </b-card>
            </b-col>
            <b-col cols="6">
              <template v-if="d_showRuleQuery && query_list.length > 0">
                <rule-query :query_list="query_list" :compile_str="compile_str_array" :mode="'edit'" :p_isPolestarColumn="p_isPolestarColumn" :compile_str_date="compile_str_date" :compile_str_text="compile_str_text"></rule-query>
              </template>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
  </div>
</template>

<script>
import { ClsRule } from '@/services/public/rule';
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as WdmParameterSelect
} from '@/components/widgets/WdmParameterSelect';
import moment from 'moment';
import { mapGetters } from 'vuex';
import RuleEngineService from '@/services/rule_engine';
import vSelect from 'vue-select';

export default {
  name: 'WdmRuleA',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  components: {
    RuleQuery,
    RuleCompileStr,
    WisdomDataModal,
    WdmParameterSelect,
    vSelect
  },
  props: {
    p_fastAnalyzeMode: {
      type: Boolean,
      required: false
    },
    p_wdmRuleAsettings: {
      type: Object,
      required: true
    },
    mother_children: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    polestar: {
      type: Boolean,
      required: false
    },
    p_isPolestarColumn: {
      type: Boolean,
      required: false
    },
    p_wdmParameterSelectData: {
      type: Array,
      required: true
    },
    wdmr_change: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    compile_str_array: {
      type: Array,
      required: true
    },
    compile_str_date: {
      type: Array,
      required: true
    },
    compile_str_text: {
      type: Array,
      required: true
    },
    query_list: {
      type: Array,
      required: true
    },
    p_wdmList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_fullScreen: false,
      prepare_data: { 'data': {} },
      d_selectedDataType: '',
      d_selectedValueList: [],
      d_selectedValue: '',
      d_selectedOperator: '=',
      d_selectedParameter: [],
      d_textRule: '',
      d_errorInText: 0,
      d_correctTextList: [],
      d_textRuleResult: { 'query_list': [], 'compile_str_array': [] },
      d_openAlgoritm: true,
      d_showRuleQuery: true
    };
  },
  created: function () {
    this.p_wdmRuleAsettings.mode = '';
  },
  mounted: function () {
    if (!this.f_queryListEligibleForParamMode()) {
      // console.log("!this.f_queryListEligibleForParamMode()");
      this.p_wdmRuleAsettings.mode = 'param_query';
      this.f_reShowRuleQuery();
    } else {
      this.p_wdmRuleAsettings.mode = '';
      this.f_goToParamMode();
    }
  },
  methods: {
    f_reShowRuleQuery: function () {
      this.d_showRuleQuery = false;
      this.$forceUpdate();
      setTimeout(function () {
        this.d_showRuleQuery = true;
      }.bind(this), 50)
    },
    f_queryFromParam: function (type = '') {
      ClsRule.f_queryFromParam(this.query_list, this.p_wdmParameterSelectData, this.option_data, type, this.compile_str_array);
      this.f_reShowRuleQuery();
    },
    f_addToThisQueryFromParam: function (query_ind, type = '') {
      ClsRule.f_addToThisQueryFromParam(query_ind, this.query_list, this.p_wdmParameterSelectData, this.option_data, type, this.compile_str_array);
      this.f_reShowRuleQuery();
    },
    f_openClose: function () {
      if (this.d_openAlgoritm) {
        this.d_openAlgoritm = false;
      } else {
        this.d_openAlgoritm = true;
      }
      this.$forceUpdate();
    },
    f_createCompileStrArray: function (key, list_count) {
      let tmp_array = []
      tmp_array.push('(');
      for (let i = 0; i < list_count; i++) {
        tmp_array.push(i);
        if (list_count - 1 !== i) {
          tmp_array.push(key);
        }
      }
      tmp_array.push(')');
      return tmp_array;
    },
    f_queryListEligibleForParamMode: function () {
      let eligible_for_param_mode = true;
      let query_type_list = {};
      if (this.query_list.length > 0) {
        // WE CONTROL COMPILE STR ARRAY, FIRST WE CREATE HOW WE WANT ANT CONTROL THE MATCHING.
        if (this.compile_str_array.length !== 0 && this.f_createCompileStrArray('and', this.query_list.length).join() !== this.compile_str_array.join()) {
          eligible_for_param_mode = false;
        } else {
          for (let q in this.query_list) {
            if (this.query_list[q].significant === 'false') {
              eligible_for_param_mode = false;
            } else {
              // WE CONTROL COMPILE STR ARRAY, FIRST WE CREATE HOW WE WANT ANT CONTROL THE MATCHING.
              if (this.query_list[q].compile_str_array.length !== 0 && this.f_createCompileStrArray('and', this.query_list[q].fields.length).join() !== this.query_list[q].compile_str_array.join()) {
                eligible_for_param_mode = false;
              } else {
                // WE CONTROL WHETHER DIFFIRENT QUERIES HAVE THE SAME WDM TYPE.
                if (!query_type_list[this.query_list[q].type.value]) {
                  query_type_list[this.query_list[q].type.value] = 0;
                }
                query_type_list[this.query_list[q].type.value] += 1;
                if (query_type_list[this.query_list[q].type.value] > 1) {
                  eligible_for_param_mode = false;
                  break;
                }
              }
            }
          }
        }
      }
      return eligible_for_param_mode;
    },
    f_goToParamMode: function () {
      // console.log('f_goToParamMode');
      let need_wdms = [];
      this.p_wdmParameterSelectData.splice(0, this.p_wdmParameterSelectData.length);
      for (let q in this.query_list) {
        for (let f in this.query_list[q].fields) {
          let field_data = this.query_list[q].fields[f];
          let new_wps = {
            'operation': field_data.operation,
            'selected_value': '',
            'loc': field_data['loc'],
            'val_list': [], // WE CREATE "values" variable as an array only contains the values, WHEN WE CREATE QUERY_LIST AND FIELDS.
            'selected_wdm': '',
            'd_level': 0,
            'd_level_count': 0,
            'significant': field_data.significant
          };
          if (field_data.is_column) {
            new_wps.is_column = 1;
          }
          /*
            SELECTED_WDM STRUCTURE SHOULD BE..
            {
                "label": "genetik ( 4.0)",
                "value": "wdm_wisdom_genetic_v4.0",
                "bucket": "option",
                "type": "genetic"
            }
            BUT IT IS DEFINED IN FIELD OF QUERY AS =>
            {
                "value": "genetic",
                "label": "genetik"
            }
            THEN IN THE BELOW CODE WE CONVERT IT FROM p_wdmList
          */
          // console.log(field_data);
          for (let w in this.p_wdmList) {
            if (this.p_wdmList[w].type === this.query_list[q].type.value) {
              if (!this.option_data[this.query_list[q].type.value]) {
                need_wdms.push(this.p_wdmList[w]);
              }
              new_wps.selected_wdm = this.p_wdmList[w];
              break;
            }
          }
          if (field_data.val_list.length > 0 && typeof field_data.val_list[0] === "object" && !Array.isArray(field_data.val_list[0])) {
            new_wps.val_list = field_data.val_list;
            // HERE WE CONTROL WHETHER OUR OBJECTS IN VAL_LIST ARE JSON DICTIONARY OBJECT BUT NOT ARRAY. WE ALREADY KNOW THAT;
            // OUR VAL_LIST DOES NOT CONTAIN ARRAY. IT IS ONE DIMENSIONAL. BUT IN THE FUTURE WE MAY ADD. SO CONTROLLING.
            /*
              Object dictionary containing
              val_list = [
                {
                  'value': '',
                  'label': ''
                }
              ]
            */
          } else {
            /*
              Other containing
              val_list = ['abc'], or ['15'] ....
            */
            new_wps.selected_value = field_data.val_list[0];
          }
          // console.log(JSON.stringify(new_wps));
          this.p_wdmParameterSelectData.push(JSON.parse(JSON.stringify(new_wps)));
        }
      }
      // console.log('need_wdms ', need_wdms);
      if (need_wdms.length > 0) {
        /*
          need_wdms = [
            {
                "label": "genetik ( 4.0)",
                "value": "wdm_wisdom_genetic_v4.0",
                "bucket": "option",
                "type": "genetic"
            }
          ]
        */
        // console.log('need_wdms', need_wdms);
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        let data_wdm_list = {
          'wdm_list': need_wdms,
          'target_lang': ''
        };
        WdmService.get_wdm_list(data_wdm_list)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              for (let n in need_wdms) {
                for (let i in resp.data.result.wdm_list) {
                  if (resp.data.result.wdm_list[i].wdm_key === need_wdms[n].value) {
                    let wdm_type = need_wdms[n].type;
                    this.option_data[wdm_type] = resp.data.result.wdm_list[i];
                    break;
                  }
                }
              }
              this.p_wdmRuleAsettings.mode = 'param';
              this.$forceUpdate();
            } else {
              console.log('error ', resp.data.message);
            }
          });
      } else {
        this.p_wdmRuleAsettings.mode = 'param';
        this.$forceUpdate();
      }
    },
    f_selectMode: function () {
      let active_mode = this.p_wdmRuleAsettings.mode;
      let new_mode = '';
      if (active_mode === 'param') {
        new_mode = 'wdm_query';
      } else if (active_mode === 'wdm_query') {
        new_mode = 'param_query';
      } else if (active_mode === 'param_query') {
        new_mode = 'param';
      }
      if (active_mode === 'wdm_query' && new_mode === 'param') {
        let eligible_for_param_mode = this.f_queryListEligibleForParamMode();
        // console.log('eligible_for_param_mode', eligible_for_param_mode);
        if (eligible_for_param_mode) {
          this.f_goToParamMode();
        } else {
          alert('Parametre moduma geçebilmeniz için sorgu algoritmasını lütfen temizleyiniz. Algoritmanızda veya ifadeleri olabilir, algoritmanız tamamlanmamış olabilir. Parametre moduna geçmek için eklenen sorguları silmenize gerek yok.');
        }
      } else if (active_mode === 'param' && ['wdm_query', 'param_query'].indexOf(new_mode) !== -1) {
        let new_wps = false;
        // console.log('this.p_isPolestarColumn', this.p_isPolestarColumn);
        // console.log('new_mode', new_mode);
        if (this.p_isPolestarColumn && new_mode === 'param_query' && this.p_wdmParameterSelectData.length > 0) {
          // IF WE ARE IN POLESTAR COLUMN ADDING MODE, IT MEANS THAT WE CAN USE ONLY THE SIMILAR SELECTED WDM TYPE.
          new_wps = {
            'operation': '=',
            'selected_value': '',
            'loc': [],
            'val_list': [],
            'selected_wdm': JSON.parse(JSON.stringify(this.p_wdmParameterSelectData[0].selected_wdm)),
            'd_level': 0,
            'd_level_count': 0,
            'significant': 'true',
            'is_column': 1
          };
        }
        // console.log(new_wps);
        ClsRule.f_paramToQuery(this.query_list, this.compile_str_array, this.p_wdmParameterSelectData);
        this.p_wdmParameterSelectData.splice(0, this.p_wdmParameterSelectData.length);
        if (new_wps) {
          this.p_wdmParameterSelectData.push(new_wps);
        }
        this.p_wdmRuleAsettings.mode = new_mode;
      } else if (active_mode === 'param_query' && new_mode === 'param') {
        let eligible_for_param_mode = this.f_queryListEligibleForParamMode();
        // console.log('eligible_for_param_mode', eligible_for_param_mode);
        if (eligible_for_param_mode) {
          this.f_goToParamMode();
        } else {
          alert('Parametre moduma geçebilmeniz için sorgu algoritmasını lütfen temizleyiniz. Algoritmanızda veya ifadeleri olabilir, algoritmanız tamamlanmamış olabilir. Parametre moduna geçmek için eklenen sorguları silmenize gerek yok.');
        }
      } else if (active_mode === 'wdm_query' && new_mode === 'param_query') {
        if (this.p_wdmParameterSelectData.length > 0) {
          let first_item = JSON.parse(JSON.stringify(this.p_wdmParameterSelectData[0]));
          this.p_wdmParameterSelectData.splice(0, this.p_wdmParameterSelectData.length);
          this.p_wdmParameterSelectData.push(first_item);
          ClsRule.f_paramToQuery(this.query_list, this.compile_str_array, this.p_wdmParameterSelectData);
        } else {
          let new_wps = {
            'operation': '=',
            'selected_value': '',
            'loc': [],
            'val_list': [],
            'selected_wdm': '',
            'd_level': 0,
            'd_level_count': 0,
            'significant': 'true'
          };
          this.p_wdmParameterSelectData.push(new_wps);
        }
        this.p_wdmRuleAsettings.mode = new_mode;
      }
      this.$forceUpdate();
    },
    f_analyzeWdmParametersWithText: function () {
      let data = {
        'search_text': this.d_textRule,
        'wdm_data': this.option_data.column_rule
      };
      RuleEngineService.analyze_wdm_parameters_with_text(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log(resp.data);
            this.d_errorInText = resp.data.result.error_in_text;
            this.d_correctTextList = resp.data.result.correct_text_list;
            this.d_textRuleResult.query_list = resp.data.result.query_list;
            this.d_textRuleResult.compile_str_array = resp.data.result.compile_str_array;
            this.query_list.splice(0, this.query_list.length);
            this.compile_str_array.splice(0, this.compile_str_array.length);
            for (let i in this.d_textRuleResult.query_list) {
              this.query_list.push(this.d_textRuleResult.query_list[i]);
            }
            for (let i in this.d_textRuleResult.compile_str_array) {
              this.compile_str_array.push(this.d_textRuleResult.compile_str_array[i]);
            }
          } else {
            alert('Errror: ', resp.data.message);
          }
        });
    },
    f_addToCompileStrArray: function (key) {
      let list_count = 0;
      this.compile_str_array.splice(0, this.compile_str_array.length - 1);
      list_count = this.query_list.length;
      this.compile_str_array.push('(');
      for (let i = 0; i < list_count; i++) {
        this.compile_str_array.push(i);
        if (list_count - 1 !== i) {
          this.compile_str_array.push(key);
        }
      }
      this.compile_str_array.push(')');
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_showValList: function () {
      let x = '';
      for (let i in this.col.parameter_val_list) {
        if (this.col.parameter_val_list[i].label) {
          x += this.col.parameter_val_list[i].label;
        } else {
          x += this.col.parameter_val_list[i];
        }
        if (parseInt(i) !== this.col.parameter_val_list.length - 1) {
          x += ',';
        }
      }
      return x;
    },
    f_prepareData: function () {
      this.prepare_data = { 'data': {} };
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        this.prepare_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.$forceUpdate();
    }
  },
  watch: {
    /*
      query_list: {
        deep: true,
        immediate: true,
        handler () {
          ClsRule.f_addThisKeyQueryAndFields('and', this.query_list, this.compile_str_array);
        }
      },
    */
    'd_selectedDataType': function () {
      if (this.d_selectedDataType.type) {
        this.data_type = this.d_selectedDataType.type;
        if (this.d_selectedDataType && !this.option_data[this.d_selectedDataType.type]) {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12705') } });
          GlobalService.get_one_cb_document({ 'key': this.d_selectedDataType.value, 'bucket': this.d_selectedDataType.bucket })
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                this.option_data[this.d_selectedDataType.type] = resp.data.result;
                if (['wdm_query'].indexOf(this.p_wdmRuleAsettings.mode) !== -1) {
                  this.f_prepareData();
                }
              } else {
                alert('error ', resp.data.message);
              }
            });
        } else {
          if (['wdm_query'].indexOf(this.p_wdmRuleAsettings.mode) !== -1) {
            this.f_prepareData();
          }
        }
      }
    },
    'mother_children.wdm_parameters_data_type': function () {},
    'd_textRule': function () {
      // console.log(this.d_textRule);
      if (this.d_textRule) {
        this.f_analyzeWdmParametersWithText();
      }
      /*
      if (!this.d_errorInText) {
        if (this.d_textRule[this.d_textRule.length - 1] === ' ') {
          this.d_textRule = this.d_textRule.slice(0, this.d_textRule.length - 1);
          this.d_errorInText = 1;
        }
      } else {
        if (this.d_textRule[this.d_textRule.length - 1] === ' ') {
          this.f_analyzeWdmParametersWithText();
        }
      }
      */
    }
  }
};

</script>

<style type="text/css">
.mode_cls {
  padding: 3px;
  background-color: transparent;
  border: none;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

