import { render, staticRenderFns } from "./layer_wdmr_record_view.vue?vue&type=template&id=31086587"
import script from "./layer_wdmr_record_view.vue?vue&type=script&lang=js"
export * from "./layer_wdmr_record_view.vue?vue&type=script&lang=js"
import style0 from "./layer_wdmr_record_view.vue?vue&type=style&index=0&id=31086587&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports